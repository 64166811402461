import React from "react";
import { Link } from "react-router-dom";
import ModalManager from '../common/modal/ModalManager'


export default class Pricing extends React.Component {
  notification = () => {
    ModalManager.confirm("", "","",
    (submitted, close) => {
      if (submitted) close()
    },
      ({ close }) => (
        <SignUpForm />
      ),
      true, true
    )
  };
  render() {
    return (
      <React.Fragment>
        <div
          className="welcome-background"
          style={{
            height: "35vh",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "20px",
            }}
          >
            <Link to="/login">
              <span
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                <i className="material-icons">arrow_back</i>
                Back to login
              </span>
            </Link>
          </div>
        </div>
        <div className="grid-container">
          <div
            className="grid-x grid-padding-x grid-padding-y"
            style={{
              marginTop: "-30vh",
            }}
          >
            <div
              className="cell"
              style={{
                color: "white",
              }}
            >
              <h1
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingBottom: "2rem",
                }}
              >
                Synaccess Cloud Pricing
              </h1>
              <div
                style={{
                  textAlign: "center",
                  // color: "#CCC",
                  color: "white",
                  opacity: "0.8",
                  fontSize: "1.4rem",
                  paddingBottom: "3rem",
                }}
              >
                Get access to the power, control, and customization you need to
                manage your critical projects.
              </div>
            </div>
            {[
              {
                title: "Basic",
                headerColor: "rgb(145 176 203)",
                price: "Free up to 5 devices",
                description: "Monitor and switch PDU outlets remotely and securely in one simple dashboard.",
                features: [
                  "5 devices",
                  // "5 guest users",
                  "Remote device power monitoring, and power control",
                  "Limited HTTP API Usage",
                  // "2FA authentication",
                  "24 hours of power state/metric logging",
                  "1 week of history logging",

                  "Email support",
                ],
                upcomingFeatures: [],
                addOns: [],
                // button: {
                //   label: "Try Free",
                //   link: "/register",
                // },
                button: {
                  label: "Sign up for notifications",
                  onClick: () => this.notification("synaccess-cloud, basic"),
                  // href: "https://synaccess.com/contact",
                },
                enabled: false,
              },
              {
                title: "Professional",
                // headerColor: "#223467",
                // headerColor: "rgb(72 101 126)",
                headerColor: "rgb(130 153 173)",
                footerColor: "rgb(76 104 129)",
                price: "$2 per device /month",
                // conditions: (
                // <div>
                //   <div
                //     style={{
                //       padding: "15px"
                //     }}
                //   >
                //     <div>
                //       <span style={{ paddingLeft: '3px', textDecoration: "underline" }}>
                //         <Link to="/beta-terms">Full beta terms here</Link>
                //       </span>
                //     </div>
                //   </div>
                // </div>
                // ),
                description:
                  "Monitor, log, and switch PDU outlets remotely with multiple teams/groups in one simple dashboard. ",
                features: [
                  "Up to 20 devices",
                  "Remote device power monitoring, and power control",
                  "HTTP API usage",
                  "Email support",
                  "3 months of power state & metrics logging",
                  "CSV export of long term data",
                ],
                addOns: [
                  "Additional history logging",
                  "Additional power state/metric logging",
                ],
                upcomingFeatures: [
                  "Batch firmware updates for devices",
                  "Batch device configuration and configuration backups",
                  "Unlimited guest users",
                  "2FA authentication",
                  "Auto recovery and notify for equipment failures",
                  "Email, Text, and HTTPS Webhook Notifications",
                ],
                button: {
                  label: "Request Access",
                  link: "/register",
                  // href: "https://synaccess.com/contact",
                },
                enabled: true,
              },
              {
                title: "Pro",
                // headerColor: "rgb(72 101 126)",
                headerColor: "rgb(51 78 100)",
                price: "$1 per device /month",
                description:
                  "Monitor, log, and switch PDU outlets remotely with multiple teams/groups in one simple dashboard.",
                features: [
                  // "20 devices",
                  "Remote device power monitoring, and power control",
                  "HTTP API usage",
                  "Priority email support",

                  "3 months of power state & metrics logging",
                  "CSV export of long term data",
                  "Batch firmware updates for devices",
                  "Batch device configuration and configuration backups",
                  "Unlimited guest users",
                  "2FA authentication",
                  "Auto recovery and notify for equipment failures",
                  "Email, Text, and HTTPS Webhook Notifications",
                ],
                upcomingFeatures: [],
                addOns: [
                  "Additional history logging",
                  "Additional power state/metric logging",
                ],
                button: {
                  label: "Sign up for notifications",
                  // href: "https://synaccess.com/contact",
                  onClick: () => this.notification("synaccess-cloud, pro"),
                },
                enabled: false,
                hide: true,
              },
              {
                title: "Enterprise",
                headerColor: "#223647",
                price: "Request Quote",
                description:
                  // "A custom pricing model for your business. Choose which features you want.",
                  "A custom model for your business. We'll work with you on the features you need.",
                features: [
                  "Flexible # of devices",
                  "HTTP API usage",
                  "Priority email & call support",
                ],
                upcomingFeatures: [
                  "Flexible logging history",
                  "Flexible power state/metric logging",
                  "Automatic Firmware Updates",
                  // "Unlimited guest users",
                  // "CSV export of long term data",
                  "Advanced power monitoring notifications and recovery",
                ],
                addOns: [
                  "Custom feature development",
                  "Expert power infrastructure consulting",
                  "Guest users with white labeled dashboard",
                  "Multi-year licensing Options"
                ],
                button: {
                  label: "Contact Us",
                  // link: "",
                  href: "https://synaccess.com/contact",
                },
                enabled: true,
              },
            ].map((section) => {
              const LinkButton = section.button.href
                ? "a"
                : section.button.link
                ? Link
                : "div";
              if (section.hide) return null;
              return (
                <div
                  className="cell auto"
                  key={section.title}
                  style={
                    {
                      // opacity: section.enabled ? 1 : 0.85,
                    }
                  }
                >
                  <div
                    className="app-item"
                    style={{
                      padding: "20px",
                      // border: "3px solid "+ section.headerColor,
                      // border: section.enabled ? "3px solid "+ section.headerColor : "",
                      borderTop: "solid 13px " + section.headerColor,
                      // borderRadius: "3px",
                      boxShadow: "3px 3px 20px rgba(0,0,0,0.1)",
                    }}
                  >
                    <h2>{section.title}</h2>
                    <div
                      style={{
                        minHeight: "80px",
                      }}
                    >
                      {section.description}
                    </div>
                    <div
                      style={{
                        fontSize: "180%",
                        fontWeight: "bold",
                        paddingBottom: "0.2em",
                        paddingTop: "0.4em",
                      }}
                    >
                      <p>{section.price}</p>
                    </div>
                    <div>
                      <LinkButton
                        to={section.button.link}
                        href={section.button.href}
                        onClick={() => section.button.onClick && section.button.onClick()}
                        style={{
                          cursor: 'pointer'
                        }}
                      >
                        <div
                          // className="button"
                          style={{
                            border: "1px solid #ccc",
                            padding: "10px",
                            fontWeight: "bold",
                            margin: "10px",
                            marginBottom: "40px",
                            borderRadius: "4px",
                            textAlign: "center",
                            boxShadow: "3px 3px 3px rgba(0,0,0,0.05)",
                            backgroundColor: section.enabled
                              ? "#223647"
                              // ? section.headerColor
                              : "white",
                            color: section.enabled ? "white" : "#223647",
                            // backgroundColor: "#223647"
                          }}
                        >
                          {section.button.label}
                        </div>
                      </LinkButton>
                    </div>
                    <h4>Features</h4>
                    <ul
                      style={{
                        padding: "10px 0px",
                      }}
                    >
                      {section.features.map((feature) => {
                        return <li>{feature}</li>;
                      })}
                    </ul>
                    {section.upcomingFeatures.length > 0 && (
                      <React.Fragment>
                        <h4>Upcoming Features</h4>
                        <ul>
                          {section.upcomingFeatures.map((feature) => {
                            return <li>{feature}</li>;
                          })}
                        </ul>
                      </React.Fragment>
                    )}
                  </div>
                  {section.addOns.length > 0 && (
                    <div
                      style={{
                        color: "white",
                        // backgroundColor: "#2b405e",
                        backgroundColor: section.footerColor || section.headerColor,
                        padding: "30px",
                      }}
                    >
                      <h4>Optional Add Ons</h4>
                      <ul
                        style={{
                          padding: "10px 0px",
                        }}
                      >
                        {section.addOns.map((addOn) => {
                          return <li>{addOn}</li>;
                        })}
                      </ul>
                    </div>
                  )}
                  {section.conditions &&
                    section.conditions
                  }
                </div>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class SignUpForm extends React.Component {
  state = {
    status: "",
    success: false,
    loading: false,
    email: "",
  };
  submit = () => {
    this.setState({
      loading: false,
      success: false,
      status: "",
    })
    if (!this.state.email) {
      this.setState({
        status: "Please enter an email address.",
      });
      return;
    }
    fetch("https://synaccess.com/api/v1/emailSignup", {
    // fetch("http://localhost:3000/api/v1/emailSignup", {

    method: "POST",
      body: JSON.stringify({
        email: this.state.email,
        tag: "synaccess-cloud",
      }),
    })
      .then((response) => {
        if (response.ok) {
          this.setState({ status: "Successfully Signed Up", success: true });
        } else {
          response
            .json()
            .then((data) => {
              this.setState({ status: data.status });
            })
            .catch((err) => {
              this.setState({ status: "Error Occurred", success: false });
            });
        }
      })
      .catch((error) => {
        this.setState({ status: "Unknown Error Occurred", success: false });
      });
  };
  render() {
    return (
      <div
        style={{
        }}
      >
        {this.state.status && (
          <div
            style={{
              color: this.state.success ? "#5aff5a" : "maroon",
              paddingBottom: "15px",
            }}
          >
            {this.state.status}
          </div>
        )}
        {!this.state.success && (
          <div
            style={{
              display: "flex",
              flexDirection: 'column',
              marginBottom: "20px",
              width: "100%"
              // border: "solid white 1px"
            }}
          >
            <div
              style={{
                minWidth: "350px",
              }}
            >
              <div
                style={{
                  paddingBottom: "30px"
                }}
              >
                <h4>Sign up for Synaccess Cloud notifications</h4>
                <div>
                  Be notified when this product is available.
                </div>
              </div>
              {/* <label>Email Address</label> */}
              <input
                onChange={(e) => this.setState({ email: e.target.value })}
                value={this.state.email}
                placeholder="Business Email"
              />
            </div>
            <div style={{ marginTop: "30px" }}>
              <div
                className="button"
                style={{
                  width: "100%",
                  marginTop: "10px",
                }}
                onClick={() => {
                  this.submit();
                }}
              >
                Sign Up
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
