import React from 'react';

const ButtonInput = ({
  label,
  disabled,
  buttonName,
  buttonCallback = () => {},
  inputContainerStyle={},
  buttonStyle={},
  title,
  description,
}) => (
  <div
    className="input-container"
    style={inputContainerStyle}
  >
    {label && 
      <div
        className="input-label"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          color: disabled ? "#9e9e9e" : "#333",
          flex: "1"
        }}
      >
        <span>{label}</span>
      </div>
    }
    {description &&
      <div
        className="input-label"
        style={{
          color: "#666",
          fontStyle: "italic",
          margin: "5px 0px",
          marginBottom: "10px",
          marginTop: "-5px"
        }}
      >
        {description}
      </div>
    }
    <div
      style={{
        display: 'flex',
        flex: "1"
      }}
    >
      <div
        className={"button " + (disabled ? "background-color-inactive" : "background-color-highlight")}
        style={{
          // backgroundColor: disabled ? "#d0d0d0" : "#223647",
          color: disabled ? "#888" : "white",
          cursor: disabled ? "default" : "pointer",
          ...buttonStyle
        }}
        onClick={() => {
          if (!disabled) {
            buttonCallback()
          }
        }}
        title={title}
      >
        {buttonName}
      </div>
    </div>
  </div>
);

export default ButtonInput;
