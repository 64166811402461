import React from 'react';
// import sensorrefsinfo from '../../assets/refs';
import { Link } from "react-router-dom";
import { formatDistance } from "date-fns";
import Loading from '../../components/common/loading';

export default class DeviceSensors extends React.Component {
  componentDidMount() {
    if (!this.props.resources.sensorsLoaded && !this.props.resources.sensorsLoading) {
      this.props.fetchResource("sensors");
    }
  }
  render() {
    const {
      sensors,
      devices
    } = this.props.resources;

    const columnWidths = [4, 4, 2, 2, 2, 2, 3, 2];

    const highestTemperatureObj = sensors.reduce((prev, current) => {
      return (prev.sensorTempInF || 0) > (current.sensorTempInF || 0) ? prev : current;
    }, {});

    const highestHumidityObj = sensors.reduce((prev, current) => {
      return (prev.sensorHumidity || 0) > (current.sensorHumidity || 0) ? prev : current;
    }, {});

    const averageTemperatureObj = sensors.reduce((prev, current) => {
      return ({
        sensorTempInF: (prev.sensorTempInF || 0) + (current.sensorTempInF || 0),
        sensorTempInC: (prev.sensorTempInC || 0) + (current.sensorTempInC || 0)
      })
    }, {
      sensorTempInF:0,
      sensorTempInC:0
    }) 
    averageTemperatureObj.sensorTempInF = averageTemperatureObj.sensorTempInF / sensors.length;
    averageTemperatureObj.sensorTempInC = averageTemperatureObj.sensorTempInC / sensors.length;
    return (
      <div
        style={{
          overflowX: "auto"
        }}
      >
        
        {!this.props.hideChartz &&
          <Chartz
            loading={this.props.resources.sensorsLoading}
            sections={[
              {
                title: "Highest Temperature",
                value: `${(highestTemperatureObj.sensorTempInF || 0).toFixed(2)} °F  (${(highestTemperatureObj.sensorTempInC || 0).toFixed(2)} °C)`,
                description: `Out of ${sensors.length} sensor(s).`,
              },
              {
                title: "Highest Humidity",
                value: `${(highestHumidityObj.sensorHumidity || 0).toFixed(2)} RH`,
                description:
                  `Out of ${sensors.length} sensor(s).`,
              },
              {
                title: "Number of Sensors",
                value: `${sensors.length}`,
                description: `${sensors.filter(s => s.sensorType === "temp_hum").length} Temperature/Humidity Sensors`,
              },
              {
                title: "Average Temperature",
                value: `${(averageTemperatureObj.sensorTempInF || 0).toFixed(2)} °F (${(averageTemperatureObj.sensorTempInC || 0).toFixed(2)} °C)`,
                description: "Average Temperature of all temperature sensors",
                // value: `${numOnOutlets} ON, ${numOffOutlets} OFF`,
              },
            ]}
          />
        
        }
        <div className="grid-row header">
          <div style={{ flex: columnWidths[0] }}>Sensor</div>
          <div style={{ flex: columnWidths[1] }}>PDU</div>
          <div style={{ flex: columnWidths[2] }}>Port</div>
          <div style={{ flex: columnWidths[3] }}>Temp (C)</div>
          <div style={{ flex: columnWidths[4] }}>Temp (F)</div>
          <div style={{ flex: columnWidths[5] }}>Humidity (RH)</div>
          <div style={{ flex: columnWidths[6] }}>Last Updated</div>
          <div style={{ flex: columnWidths[7] }}></div>
        </div>
        {this.props.resources.sensorsLoading ? (
         <LoadingSkeleton columnWidths={columnWidths}/>
        ) : (
          sensors.length === 0 ? (
            <div className="grid-row-wrapper">
              <div className="grid-row">
                <div  style={{ paddingLeft: "30px" }}>
                  No Sensors Found
                </div>
              </div>
            </div>
          ) : (
            null
          )
        )}
        {sensors.map((sensor, index) => {

          const device = devices.find(d => d.enclosureSerialNumber == sensor.enclosureSerialNumber) || {};
          return (
            <div key={index} className="grid-row-wrapper">
              <div
                className="grid-row"
                style={{
                }}
              >
                <div style={{ flex: columnWidths[0], display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                    {{temp_hum: "Temperature/Humidity Sensor"}[sensor.sensorType]}
                </div>

                <div style={{ flex: columnWidths[1] }} >
                    <Link to={`/devices/connected/${device.enclosureSerialNumber}`}>
                    {(device.deviceName === "SynLink PDU" || !device.deviceName)
                        ? `PDU SN:${device.enclosureSerialNumber}`
                        : device.deviceName}
                    </Link>
                </div>
                <div style={{ flex: columnWidths[2] }}>
                    {sensor.sensorPort}
                </div>
                <div style={{ flex: columnWidths[3] }}>
                    {(sensor.sensorTempInC || 0).toFixed(3)} °C
                </div>
                <div style={{ flex: columnWidths[4] }}>
                    {(sensor.sensorTempInF || 0).toFixed(3)} °F
                </div>
                <div style={{ flex: columnWidths[5] }}>
                    {(sensor.sensorHumidity || 0).toFixed(3)}% RH
                </div>
                
                <div style={{ flex: columnWidths[6] }}>
                    <span
                      style={{
                        fontSize: "90%",
                      }}
                    >
                      {device.lastUpdated &&
                        `${formatDistance(device.lastUpdated, new Date())} ago`}
                    </span>
                </div>
                <div style={{ flex: columnWidths[7] }}>
                    <span
                      title="Refresh PDU Information"
                      className="black"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.props.getDeviceItemAndData(
                          device.enclosureSerialNumber
                        );
                      }}
                    >
                      <i
                        style={{ fontWeight: "600", color: "#6c6f72" }}
                        className="material-icons"
                      >
                        sync
                      </i>
                    </span>

                </div>

              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

const LoadingSkeleton = ({columnWidths}) => {
  return  (
    <div style={{}}>
      {[{}, {}, {}, {}].map((item, index) => {
        return (
          <div className="grid-row-wrapper" key={index+1}>
            <div className="grid-row">
              {/* <div style={{ flex: columnWidths[0] }}></div> */}
              <div style={{ flex: columnWidths[0] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[1] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[2] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[3] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[4] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[5] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[6] }}>
                <div className="skeleton-blank" />
              </div>
              {/* <div style={{ flex: columnWidths[8] }}>
              <div className="skeleton-blank" />
            </div> */}
              {/* <div style={{ flex: columnWidths[9] }}>
                <div className="skeleton-blank" />{" "}
              </div> */}
            </div>
          </div>
        );
      })}
    </div>
  )
}


const Chartz = ({
  sections = [
    {
      value: "",
      title: "",
      description: "",
    },
  ],
  // totalCurrentRms,
  // averageCurrentRms,
  // totalCurrentRms,
  // // allInlets,
  // totalWattage,
  loading,
}) => {
  return (
    <div
      className="grid-x grid-padding-x grid-padding-y"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      {sections.map((section) => {
        return (
          <div key={section.title} className="cell large-3 medium-6">
            <div
              style={{
                flex: 1,
                borderRadius: "3px",
                display: "flex",
                alignItems: "flex-start",
                paddingLeft: "20px",
                justifyContent: "flex-start",
                // textAlign: "flex-start",
                height: "100%",
                boxShadow: "2px 2px 5px rgba(0,0,0,0.14)",
              }}
              className="app-item"
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "120px",
                  }}
                >
                  <Loading black />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div>{section.title}</div>
                  <div
                    style={{
                      fontSize: "150%",
                      fontWeight: "600",
                      padding: "12px 0px 7px 0px",
                    }}
                  >
                    {section.value}
                  </div>
                  <div
                    style={{
                      color: "grey",
                      fontSize: "85%",
                    }}
                  >
                    {section.description}
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};