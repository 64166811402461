import React from 'react';


class TextAreaInput extends React.Component {
  state = {
    validationErr: ""
  }

  render() {
    const {
      label,
      callback,
      value,
      placeholder,
      rows=3,
      description,
      validation,
      disabled,
      errMsg
    } = this.props;
    
    const { validationErr } = this.state;

    return (
      <div className="input-container">
        <div
          className="input-label"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: disabled ? "#9e9e9e" : "#333"
          }}
        >
          <span>{label}</span>
          {(!disabled && (validationErr || errMsg)) && 
            <span
              style={{
                color: 'maroon',
                fontWeight: '400'
              }}
            >
              {validationErr || errMsg}
            </span>
          }
        </div>
        {description &&
          <div className="input-description">{description}</div>
        }
        <textarea
          style={{
            border: disabled ? "solid #e6e6e6 1px" : (validationErr || errMsg) ? 'solid maroon 1px' : '',
            color: disabled ? "#9e9e9e" : "#333"
          }}
          disabled={disabled}
          rows={rows}
          onChange={e => {
            if (callback) {
              let result = e.target.value;
              callback(result)
            }
          }}
          onFocus={() => {
            if (this.state.validationErr) {
              this.setState({ validationErr: "" })
            }
          }}
          onBlur={() => {
            if (validation) {
    
              if (Array.isArray(validation)) {
                validation.some((validationFunction => {
                  const validationResult = validationFunction(value);
    
                  if (validationResult) {
                    this.setState({ validationErr: validationResult });
                    return true
    
                  } else {
                    return false
    
                  }
                }))
    
              } else if (typeof validation === "function") {
                const validationResult = validation(value)
                if (validationResult) {
                  this.setState({ validationErr: validationResult });
                }
    
              } else {
                console.error("Improper validation data type, must be array of functions of single function");
              
              }
            }
          }}
          value={value}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

export default TextAreaInput;
