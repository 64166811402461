import React from "react";
import TextInput from "../../components/common/inputs/textInput";
import Loading from "../../components/common/loading";
import ModalManager from "../../components/common/modal/ModalManager";
import axios from "axios";
import { formatDistance } from "date-fns";

// import NotificationManager from '../../components/common/modal/NotificationManager'
import NotificationManager from "../../utils/notificationManager";

export default class LoggedInUser extends React.Component {
  state = {
    fullName: "",
    company: "",
    hasChanges: false,
    loading: false,
    newTokenFormActive: false,
    description: "",
    tokens: []
  };
  componentDidMount = async () =>  {
    if (this.props.user) {
      this.setState({
        fullName: this.props.user.full_name,
        company: this.props.user.company,
      });
      // const tokens = await axios.get(`/v1/users/${this.props.user.id}/tokens`, {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token")
      //   },
      // })
      // // console.log(tokens.data)
      // this.setState({
      //   tokens: tokens.data.data
      // })
    }
  }
  onCancel = () => {
    this.setState({
      hasChanges: false,
      fullName: this.props.user.full_name,
      company: this.props.user.company,
    });
  };

  // onSave = () => {
  //   this.setState({
  //     // hasChanges: false,
  //     loading: true,
  //   });
  //   this.props.updateUserFetch(
  //     this.props.user,
  //     {
  //       full_name: this.state.fullName,
  //       company: this.state.company,
  //     },
  //     (err, res) => {
  //       if (err) {
  //         console.log(err);
  //         this.setState({
  //           loading: false,
  //         });
  //       } else {
  //         this.setState({
  //           loading: false,
  //           hasChanges: false,
  //         });
  //       }
  //     }
  //   );
  // };
  onSave = () => {
    this.setState({
      // hasChanges: false,
      loading: true,
    });
    this.props.updateUserFetch(
      this.props.user,
      {
        full_name: this.state.fullName,
        company: this.state.company,
      },
      (err, res) => {
        if (err) {
          console.log(err);
          this.setState({
            loading: false,
          });
        } else {
          if (res.data) {
            this.setState({
              fullName: res.data.full_name,
              company: res.data.company,
            });
            NotificationManager.success("User Updated Successfully");
          }
          this.setState({
            loading: false,
            hasChanges: false,
          });
        }
      }
    );
  };
  changePassClick = () => {
    ModalManager.confirm(
      "",
      `Change Password`,
      // "",
      "",
      (submitted, close) => {
        if (submitted) {
          close();
        }
      },
      ({ close }) => (
        <ChangePasswordForm
          close={close}
          resetPassword={this.props.resetPassword}
        />
      ),
      true,
      true
    );
  };
  // createToken = () => {
  //   axios.post(`/v1/users/${this.props.user.id}/tokens`, {
  //     name: this.state.description
  //   }, {
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("token")
  //     },
  //   }).then(res => {
  //     this.setState({
  //       newTokenFormActive: false,
  //       name: "",
  //       description: "",
  //       tokens: [...this.state.tokens, res.data.data]
  //     })
  //     NotificationManager.success("Token created", "Success")
  //     ModalManager.confirm(
  //       `Please copy the access token below. You will not be able to see it again.\n\n${res.data.data.token}`,
  //       "Personal Access Token",
  //       "Confirm",
  //       (submitted, close) => {
  //         if (submitted) {
            
  //           close()
  //         } else {
  //           // close();
  //         }
  //       }
  //     );
  //   }).catch(err => {
  //     NotificationManager.error("Error creating token", "Error")
  //   })
  // }
  // removeToken = (id) => {
    
  //   ModalManager.confirm(
  //     `Are you sure you want to permanently delete this Personal Access Token?`,
  //     "Delete Personal Access Token",
  //     "Confirm",
  //     (submitted, close) => {
  //       if (submitted) {
  //         axios.delete(`/v1/users/${this.props.user.id}/tokens/${id}`, {
  //           headers: {
  //             Authorization: "Bearer " + localStorage.getItem("token")
  //           },
  //         }).then(res => {
  //           this.setState({
  //             tokens: this.state.tokens.filter(token => token.id !== id)
  //           })
  //           NotificationManager.success("Token removed", "Success")
  //         }
  //         ).catch(err => {
  //           NotificationManager.error("Error removing token", "Error")
  //         })
  //         close()
  //       } else {
  //         // close();
  //       }
  //     }
  //   );

  // }
  // toggleToken = (id) => {
  //   axios.put(`/v1/users/${this.props.user.id}/tokens/${id}`, {
  //     is_active: !this.state.tokens.find(token => token.id === id).is_active
  //   } ,{
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("token")
  //     },
  //   }).then(res => {
  //     this.setState({
  //       tokens: this.state.tokens.map(token => {
  //         if (token.id === id) {
  //           token.is_active = !token.is_active
  //         }
  //         return token
  //       })
  //     })
  //     NotificationManager.success("Token toggled", "Success")
  //   }
  //   ).catch(err => {
  //     NotificationManager.error("Error toggling token", "Error")
  //   })

  // }
  render() {
    return (
      <div className="grid-container">
        <div className="grid-x grid-padding-x grid-padding-y">
          <div className="cell" style={{ paddingBottom: "0px", paddingTop: "0px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                opacity: this.state.hasChanges ? 1 : 0,
              }}
            >
              <button
                className="button"
                style={{
                  backgroundColor: "#eee",
                  color: "#111",
                  marginRight: "10px",
                }}
                onClick={() => {
                  this.onCancel();
                }}
              >
                Cancel
              </button>
              <button
                className="button"
                onClick={() => {
                  if (!this.loading) {
                    this.onSave();
                  }
                }}
              >
                {this.state.loading ? <Loading /> : "Save"}
              </button>
            </div>
          </div>
          <div className="cell large-6 " style={{ paddingTop: "0px"}}>
            <div>
              <h4>Profile</h4>
            </div>
            <div style={{ padding: "" }}>
              <TextInput
                label="Email"
                value={this.props.user.email_id}
                readOnly={true}
              />
              {/* <TextInput
                label="Full Name"
                value={this.state.fullName}
                callback={(value) => {
                  this.setState({
                    fullName: value,
                  });
                  if (this.state.company !== value) {
                    this.setState({
                      hasChanges: true,
                    });
                  }
                }}
              /> */}
              {/* <TextInput
                label="Company"
                value={this.state.company}
                callback={(value) => {
                  this.setState({
                    company: value,
                  });
                  if (this.state.company !== value) {
                    this.setState({
                      hasChanges: true,
                    });
                  }
                }}
              /> */}
              <div className="input-container">
                <div className="input-label">
                  <span>Password</span>
                </div>
                <button
                  className="button"
                  style={{}}
                  onClick={() => {
                    this.changePassClick();
                  }}
                >
                  Change Password
                </button>
              </div>


            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

class ChangePasswordForm extends React.Component {
  state = {
    password: "",
    confirmPassword: "",
    currentPassword: "",
    errorMsg: "",
    loading: false,
  };
  resetPasswordButtonPress = () => {
    this.setState({
      errorMsg: "",
      loading: true,
    });
    if (this.state.password === this.state.confirmPassword) {
      this.props.resetPassword(
        {
          currentPass: this.state.currentPassword,
          password: this.state.password,
        },
        (err, res) => {
          this.setState({
            loading: false,
          });
          if (err) {
            this.setState({
              errorMsg: err.message,
            });
          } else {
            if (res.data) {
              this.props.close();
              NotificationManager.success("User Password Changed Successfully");
            }
          }
        }
      );
    } else {
      this.setState({
        loading: false,
        errorMsg: "Passwords do not match",
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <TextInput
          label="Current Password"
          callback={(text) => this.setState({ currentPassword: text })}
          value={this.state.currentPassword}
          inputContainerStyle={{
            width: "100%",
            marginBottom: "0px",
          }}
          type="password"
        />
        <TextInput
          label="Password"
          callback={(text) => this.setState({ password: text })}
          value={this.state.password}
          inputContainerStyle={{
            width: "100%",
            marginBottom: "0px",
          }}
          type="password"
        />
        <TextInput
          label="Confirm Password"
          callback={(text) => this.setState({ confirmPassword: text })}
          value={this.state.confirmPassword}
          inputContainerStyle={{
            width: "100%",
            marginBottom: "0px",
          }}
          type="password"
        />
        <div
          style={{
            color: "maroon",
            padding: "10px 0px 20px 0px",
          }}
        >
          <div>
            {[
              {
                name: "2 lowercase characters required",
                valid: /[a-z].*[a-z]/.test(this.state.password),
              },
              {
                name: "2 uppercase characters required",
                valid: /[A-Z].*[A-Z]/.test(this.state.password),
              },
              {
                name: "2 number characters required",
                valid: /[0-9].*[0-9]/.test(this.state.password),
              },
              {
                name: "2 special characters required",
                valid: /[!@#$%^&*;].*[!@#$%^&*;]/.test(this.state.password),
              },
            ].map((validation) => {
              return (
                <div
                  key={validation.name}
                  style={{
                    color: validation.valid ? "forestgreen" : "#666",
                    display: "flex",
                    paddingBottom: "10px",
                    alignItems: "center",
                  }}
                >
                  <i className="material-icons">
                    {validation.valid ? "check" : "cancel"}
                  </i>
                  <span>{validation.name}</span>
                </div>
              );
            })}
          </div>
          {this.state.errorMsg &&
            this.state.errorMsg.split(",").map((msg) => {
              return (
                <div key={msg}>
                  <div
                    style={{
                      color: "maroon",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="material-icons"
                      style={{ marginRight: "5px" }}
                    >
                      error
                    </i>
                    <span>{msg}</span>
                  </div>
                </div>
              );
            })}
        </div>
        <button
          className="button"
          onClick={() => {
            this.resetPasswordButtonPress();
          }}
        >
          {this.state.loading ? <Loading /> : "Reset Password"}
        </button>
      </React.Fragment>
    );
  }
}
