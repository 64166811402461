import React from "react";
import axios from "axios";
import Loading from "../../components/common/loading";

import NotificationManager from "../../utils/notificationManager";

import PDUAccessDropdown from "../../components/users/pduAccessDropdown.js";
import ToggleInput from "../../components/common/inputs/toggleInput";

import { Link } from "react-router-dom";
const columnWidths = [
  1, // user ID static text
  5, // email static text
  5, // temp user static text toggle?
  2, // enterprise_admin toggle // can only be one
  2, // administrator toggle
  6, // multi select for which PDUs OR PDU groups they can access?
  1, // temporary lock on temp user toggle?
  2, // remove
];

export default class UserTeamMembers extends React.Component {

  render() {
    if (!this.props.resources.userEnterpriseLoaded) {
      return <Loading />;
    }
    return (
      <div className="grid-x grid-padding-x ">
        <ManageTeamMembers {...this.props} />
      </div>
    );
  }
}

class ManageTeamMembers extends React.Component {
  state = {
    // users: [],
    // email_verification_required: false,
    // just_in_time_provisioning_enabled: false,
    // auto_approval_on: false,

    hasChanges: false,
    newUserFormActive: false,
    newUserEmail: "",
    newUserHasAdminPrivileges: false,
    newUserPasswordLoginDisabled: false,
    newUserPDUs: [],
  };

  componentDidMount() {
    // this.props.resources.userEnterpriseLoaded && this.setState({
    //   ...this.props.resources.userEnterprise,
    // });
    if (
      !this.props.resources.groupsLoaded &&
      !this.props.resources.groupsLoading
    ) {
      this.props.fetchResource("groups");
    }

    const queryParams = new URLSearchParams(this.props.location.search);
    if (queryParams.get("new") === "true") {
      this.setState({ newUserFormActive: true });
    }
  }

  // check query parameters for new=true and if so, set newUserFormActive to true

  // componentDidUpdate(prevProps) {
  //   if (prevProps.resources.userEnterprise !== this.props.resources.userEnterprise) {
  //     this.setState({
  //       ...this.props.resources.userEnterprise,
  //     });
  //   }
  // }
  onSave = () => {
    this.setState({
      loading: true,
    });
    const data = {
      email_id: this.state.newUserEmail,
      has_admin_privileges: this.state.newUserHasAdminPrivileges,
      password_login_disabled: this.state.newUserPasswordLoginDisabled,
      pdu_access_list: this.state.newUserPDUs,
    };

    const foundEnterprise = this.props.resources.userEnterprise;

    axios
      .post(`/v1/enterprises/${foundEnterprise?.id}/users`, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        NotificationManager.success("User added successfully");
        this.setState({
          loading: false,
          hasChanges: false,
          newUserFormActive: false,
          newUserEmail: "",
          newUserHasAdminPrivileges: false,
          newUserPasswordLoginDisabled: false,
          newUserPDUs: [],
        });
        this.props.updateFetchWrapperState({
            userEnterprise: {
              ...this.props.resources.userEnterprise,
              users: [
                ...this.props.resources.userEnterprise.users,
                res.data.data,
              ],
            },
        });
        // navigate to this user's page
        this.props.history.push(`/team/members/${res.data.data.user_id}`);
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log(err)
        NotificationManager.alert(`Error occurred adding user.\n` + err?.response?.data?.message || "");
      });
  };

  render() {
    // const users = [
    //   ...this.state.users.filter(u => u.is_enterprise_admin),
    //   // {line: true},
    //   ...this.state.users.filter(u => !u.is_enterprise_admin),
    // ]

    const allUsers = this.props.resources.userEnterprise?.users || [];
    const users = [
      ...allUsers.filter((u) => u.is_enterprise_admin),
      ...allUsers.filter((u) => !u.is_enterprise_admin),
    ];
    const foundEnterprise = this.props.resources.userEnterprise;


    return (
      <div className="cell large-12">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
            marginTop: "10px",
            alignItems: "center",
          }}
        >
          <h4>Manage Team Members</h4>

          <div style={{}}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  textWrap: "nowrap",
                  marginLeft: "10px",
                  display: "flex",
                }}
              >
                {this.state.newUserFormActive ? (
                  <span
                    className="button"
                    style={{
                      backgroundColor: "#eee",
                      color: "#111",
                    }}
                    onClick={() => {
                      this.setState({
                        newUserFormActive: false,
                        newUserEmail: "",
                        newUserHasAdminPrivileges: false,
                        newUserPasswordLoginDisabled: false,
                        newUserPDUs: [],
                        hasChanges: false,
                      });
                    }}
                  >
                    Cancel New User
                  </span>
                ) : (
                  <span
                    className="button"
                    onClick={() => {
                      // this.addTempUser();
                      this.setState({
                        newUserFormActive: true,
                      });
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* <i className="material-icons">person_add</i> */}
                    <span>Create New User</span>
                  </span>
                )}
                <div style={{}}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexWrap: "nowrap",
                      marginLeft: "10px",
                      // opacity: this.state.hasChanges ? 1 : 0,
                      visibility: this.state.hasChanges ? "visible" : "hidden",
                    }}
                  >
                    {this.props.resources.userEnterpriseLoading && <Loading />}
                    {this.props.resources.userEnterprise && (
                      <button
                        className="button"
                        onClick={() => {
                          if (!this.loading) {
                            this.onSave();
                          }
                        }}
                      >
                        {this.state.loading ? <Loading /> : "Save"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div>
            {/* explain admin privileges */}
            {/* Explain the different statuses */}
            {/* Explain password login, and allowed PDUs */}
            <div style={{ marginBottom: "" }}>
              {/* <div>Manage different users and logins to access the PDUs that are tied to this enterprise. Admins will have the ability to modify SAML SSO settings, change team settings, create/update/delete users, and manage PDU access to users. When passwords logins are disabled, users will only be able to login via SAML2.0 Single Sign On</div> */}
              {/* <div>Allowed PDUs determine which PDUs the user can access. This is how you would assign specific PDUs and PDU Groups to particular users</div> */}
              {/* <div style={{ marginTop: "10px" }}>Statuses</div> */}
              {/* Status: 
                  
                    check for any circumstance that prevents the user from logging in
                    
                    1. check this.state.email_verification_required and if its true, and the user.email_verified false then add into status: "Email verification missing and required"
                    2. check user.approved and if its false then add into status: "User requires approval"
                    3. check user.active and if its false then add into status: "User is inactive"
                    4. check user.active_in_enterprise and if its false then add into status: "User is inactive in enterprise"
                    5. check this.state.SAML_ENABLED is false and user.password_login_disabled is false and user.obscuredPassword is false then add into status: "User needs to setup password" 
                    6. if everything is OK, then say OK
              */}
              {/* <ul>
                <li><b>User requires approval</b>: <span> Users that are not approved will not be able to log in until an admin approves their account. Auto approval options are configurable and OFF by default.</span></li>
                <li><b>Email verification missing and required</b>: <span> Users that have not verified their email will not be able to log in until they do so. This is configurable and the requirement can be turned off.</span></li>

              </ul> */}
            </div>
          </div>
        </div>

        <div className="grid-row header">
          <div style={{ flex: columnWidths[0] }}>Active</div>
          <div style={{ flex: columnWidths[1] }}>Email</div>
          <div style={{ flex: columnWidths[2] }}>Status</div>
          <div style={{ flex: columnWidths[3] }}>Admin Privileges</div>
          <div style={{ flex: columnWidths[4] }}>Password Login Allowed</div>
          <div style={{ flex: columnWidths[5] }}>PDUs & PDU Groups Access</div>
          {/* <div style={{ flex: columnWidths[6] }}></div> */}
        </div>
        <div className="grid-row-wrapper">
          {this.state.newUserFormActive && (
            <div className="grid-row">
              <div style={{ flex: columnWidths[0] }}></div>
              <div style={{ flex: columnWidths[1] }}>
                <input
                  type="text"
                  style={{
                    marginBottom: "0px",
                  }}
                  value={this.state.newUserEmail}
                  onChange={(e) => {
                    this.setState({
                      newUserEmail: e.target.value,
                      hasChanges: true,
                    });
                  }}
                  placeholder="example@domain.com"
                />
              </div>
              <div style={{ flex: columnWidths[2] }}>--</div>
              <div style={{ flex: columnWidths[3] }}>
                <ToggleInput
                  inputContainerStyle={{ padding: "0px" }}
                  value={this.state.newUserHasAdminPrivileges}
                  type="checkbox"
                  checked={this.state.newUserHasAdminPrivileges}
                  callback={(value) => {
                    this.setState({
                      newUserHasAdminPrivileges: value,
                      hasChanges: true,
                    });
                  }}
                  id="adminPrivileges"
                />
              </div>
              <div style={{ flex: columnWidths[4] }}>
                <ToggleInput
                  inputContainerStyle={{ padding: "0px" }}
                  value={this.state.newUserPasswordLoginDisabled}
                  type="checkbox"
                  checked={this.state.newUserPasswordLoginDisabled}
                  callback={(value) => {
                    this.setState({
                      newUserPasswordLoginDisabled: value,
                      hasChanges: true,
                    });
                  }}
                  id="passwordLoginDisabled"
                />
              </div>
              <div style={{ flex: columnWidths[5] }}>
                {this.props.devicesLoading ? (
                  <Loading black />
                ) : (
                  <PDUAccessDropdown
                    pdu_access_list={this.state.newUserPDUs}
                    devices={this.props.resources.devices}
                    devicesLoading={this.props.resources.devicesLoading}
                    groups={this.props.resources.groups}
                    onPduAccessListChange={(updatedPduList) => {
                      this.setState({
                        newUserPDUs: updatedPduList,
                        hasChanges: true,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {users.map((user, i) => {
            if (user.line) {
              return (
                <div
                  key={i}
                  style={{
                    borderBottom: "1px solid transparent",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                ></div>
              );
            }
            return (
              <div className="grid-row" key={i}>
                <div style={{ flex: columnWidths[0] }}>
                  {user.active ? (
                    <i className="material-icons" style={{ color: "green" }}>
                      check
                    </i>
                  ) : (
                    <i className="material-icons" style={{ color: "red" }}>
                      close
                    </i>
                  )}
                </div>
                <div style={{ flex: columnWidths[1] }}>
                  <Link to={`/team/members/${user.user_id}`}>
                    <span
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      {user.email_id}
                    </span>
                  </Link>
                </div>
                <div style={{ flex: columnWidths[2] }}>
                  {/* Status: 
                  
                    check for any circumstance that prevents the user from logging in
                    
                    1. check this.state.email_verification_required and if its true, and the user.email_verified false then add into status: "Email verification missing and required"
                    2. check user.approved and if its false then add into status: "User requires approval"
                    3. check user.active and if its false then add into status: "User is inactive"
                    4. check user.active_in_enterprise and if its false then add into status: "User is inactive in enterprise"
                    5. check this.state.SAML_ENABLED is false and user.password_login_disabled is false and user.obscuredPassword is false then add into status: "User needs to setup password" 
                    6. if everything is OK, then say OK
                  */}
                  <ul
                    style={{
                      margin: "0px",
                      marginLeft: "12px",
                      color: "maroon",
                    }}
                  >
                    {foundEnterprise?.email_verification_required &&
                      !user.email_verified && (
                        <li>Email verification missing and required</li>
                      )}
                    {!user.approved && <li>User requires approval</li>}
                    {!user.active && <li>User is inactive</li>}
                    {!user.active_in_enterprise && (
                      <li>User is inactive in enterprise</li>
                    )}
                    {!foundEnterprise?.SAML_ENABLED &&
                      !user.password_login_disabled &&
                      !user.obscuredPassword && (
                        <li>User needs to setup password</li>
                      )}

                    {user.password_login_disabled &&
                      !foundEnterprise?.SAML_ENABLED && (
                        <li>Password and SSO login are both disabled</li>
                      )}
                    {/* {foundEnterprise?.SAML_ENABLED || (!user.password_login_disabled && user.obscuredPassword) && <li>OK</li>} */}
                  </ul>
                </div>
                <div style={{ flex: columnWidths[3] }}>
                  {user.has_admin_privileges ? (
                    <i className="material-icons" style={{ color: "green" }}>
                      check
                    </i>
                  ) : (
                    <i className="material-icons" style={{ color: "#fff" }}>
                      {/* remove */}
                    </i>
                  )}
                </div>
                <div style={{ flex: columnWidths[4] }}>
                  <span>
                    {user.password_login_disabled ? (
                      <i className="material-icons" style={{ color: "#fff" }}>
                        {/* remove */}
                      </i>
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <i
                          className="material-icons"
                          style={{ color: "green" }}
                        >
                          check
                        </i>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#666",
                          }}
                        >
                          {!user.obscuredPassword && "No password set"}
                        </span>
                      </span>
                    )}
                  </span>
                  <span></span>
                </div>
                <div style={{ flex: columnWidths[5] }}>
                  {/* <PDUAccessDropdown
                    user={user}
                    editable={false}
                    onChange={(pdus) => {
                      // this.onPDUChange(user, pdus);
                    }}
                  /> */}
                  {user.is_enterprise_admin ? (
                    <div>All PDUs allowed</div>
                  ) : this.props.devicesLoading ? (
                    <Loading black />
                  ) : (
                    <PDUAccessDropdown
                      pdu_access_list={user.pdu_access_list}
                      editable={false}
                      onChange={(pdus) => {
                        // this.onPDUChange(user, pdus);
                      }}
                      devices={this.props.resources.devices}
                      devicesLoading={this.props.resources.devicesLoading}
                      groups={this.props.resources.groups}
                      onPduAccessListChange={(updatedPduList) => {
                        this.setState({
                          newUserPDUs: updatedPduList,
                          hasChanges: true,
                        });
                      }}
                    />
                  )}
                </div>
                {/* <div style={{ flex: columnWidths[6] }}>
                  <span
                    style={{
                      backgroundColor: "#11181f",
                      color: "#fff",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      // padding: "5px 20px",
                      borderRadius: "2px",
                      cursor: "pointer",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",

                    }}
                  >
                    edit
                  </span>
                </div> */}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
