import React from 'react';

class SelectInput extends React.Component {
  state = {
    validationErr: "",
  }

  render() {
    const {
      label,
      callback,
      value,
      options = [],
      disabled,
      validation,
      errMsg,
      helpIconAction,
      inputContainerStyle = {},
      selectClassName="special-select"
    } = this.props;
    
    const {
      validationErr
    } = this.state;
    return (
      <div
        className="input-container"
        style={{
          ...inputContainerStyle
        }}
      >
        {label &&
          <div
            className="input-label"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: disabled ? "#9e9e9e" : "#333"
            }}
          >
            {/* <span>{label}</span> */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <span>{label}</span>
              {helpIconAction && 
                <span
                  className={disabled ? "grey" : "dark-grey"}
                  onClick={() => helpIconAction()}
                  style={{
                    marginLeft: "5px",
                    cursor: 'pointer'
                  }}
                >
                  <i className="material-icons">help</i>
                </span>
              }
            </div>
            {(!disabled && (validationErr || errMsg)) && 
              <span
                style={{
                  color: 'maroon',
                  fontWeight: '400'
                }}
              >
                {validationErr || errMsg}
              </span>
            }
          </div>

        }
        <select
          className={selectClassName}
          value={value}
          onChange={e => callback && callback(e.target.value)}
          disabled={disabled}
          onFocus={() => {
            if (this.state.validationErr) {
              this.setState({ validationErr: "" })
            }
          }}
          onBlur={() => {
            if (validation) {
              if (Array.isArray(validation)) {
                validation.some((validationFunction => {
                  const validationResult = validationFunction(value);
                  if (validationResult) {
                    this.setState({ validationErr: validationResult });
                    return true
                  } else {
                    return false
                  }
                }))

              } else if(typeof validation === "function") {
                const validationResult = validation(value)
                if (validationResult) {
                  this.setState({ validationErr: validationResult });
                }

              } else {
                console.error("Improper validation data type, must be array of functions of single function");
              
              }
            }
          }}
          style={{
            border: disabled ? "solid #e6e6e6 1px" : (validationErr || errMsg) ? 'solid maroon 1px' : '',
            color: disabled ? "#9e9e9e" : "#333"
          }}
        >
          <option value="">---</option>
          {options.filter(o => !o.disabled).map(opt => (
            <option value={opt.value} key={opt.label}>{opt.label}</option>
          ))}
        </select>
      </div>
    );
  }
}


export default SelectInput;
