import React from "react";
import { Link } from "react-router-dom";
import SelectInput from "../../components/common/inputs/selectInput";
import ModalManager from "../../components/common/modal/ModalManager";
import TextInput from "../../components/common/inputs/textInput";
// import PieChart from "../../components/common/charts/pieChart";
// import BarChartBare from "../../components/common/charts/barChartBare";
import Loading from "../../components/common/loading";
import { formatDistance } from "date-fns";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
ChartJS.register(ArcElement, Tooltip);

export default class DeviceInlets extends React.Component {
  state = {
    selectedDevices: [],
    selectedGroup: "",
    selectedGroupId: "",
    editingActive: false,

    searchBar: "",
    currentFilter: "",
  };
  setActiveInletGroup = (groupId) => {
    const foundGroup =
      this.props.resources.groups.find((g) => g.id === groupId) || {};
    const foundDeviceIds = (foundGroup.items || []).map((i) => ({
      enclosureSerialNumber: parseInt(i, 10),
    }));
    this.setState({
      selectedDevices: foundDeviceIds,
      selectedGroup: foundGroup.name,
      selectedGroupId: foundGroup.id,
    });
  };
  applyFilters = () => {
    this.setState({
      currentFilter: this.state.searchBar
    })
  }
  removeGroup = (groupId) => {
    ModalManager.confirm(
      "This action will permanently delete this group.",
      "Remove Group",
      "Remove",
      (submitted, close) => {
        if (submitted) {
          this.props.removeResource("groups", groupId, (err, res) => {
            this.setState({
              selectedDevices: [],
              selectedGroup: "",
              selectedGroupId: "",
              editingActive: false,
            });
            close();
          });
        } else {
          // close();
        }
      }
    );
  };
  createGroup = () => {
    const items = this.state.selectedDevices.map(
      (d) => d.enclosureSerialNumber
    );
    ModalManager.confirm(
      "",
      "Create New Group",
      "Create",
      (status, close) => {
        if (status) {
          close();
        }
      },
      ({ close }) => (
        <InletGroupForm
          close={close}
          items={items}
          createResource={this.props.createResource}
          changeAppState={(newState) => this.setState(newState)}
        />
      ), // body component
      true,
      true
    );
  };
  updateGroup = (groupId) => {
    const items = this.state.selectedDevices.map(
      (d) => d.enclosureSerialNumber
    );
    this.props.modifyResource(
      "groups",
      groupId,
      {
        items,
      },
      (err, res) => {
        if (!err && res) {
          this.setState({ editingActive: false });
        } else {
          console.log("err occurred");
        }
      }
    );
  };
  componentDidMount() {
    if (!this.props.resources.groupsLoaded && !this.props.resources.groupsLoading) {
      this.props.fetchResource("groups");
    }
    if (!this.props.resources.inletsLoaded && !this.props.resources.inletsLoading) {
      this.props.fetchResource("inlets");
    }

  }
  render() {
    const columnWidths = [
      1, //0 toggle
      1, //1 status icon
      5, //1 plug
      4, //3 PDu name
      2, //4 current
      2, //5 voltage
      2, //6 line freq
      2, //7 power factor
      2, //8 active pwoer
      3, //9 energy
      2, //10 last update
      1, //11 refresh
    ];
    const { inletsLoading, inletsLoaded, devicesLoading } =
      this.props.resources;
    const devices =
      this.state.selectedGroup && !this.state.editingActive
        ? this.props.resources.devices.filter((d) =>
            this.state.selectedDevices.find(
              (reff) => reff.enclosureSerialNumber == d.enclosureSerialNumber
            )
          )
        : this.props.resources.devices;


    const filteredDevices = devices.filter((device) => {
      const inlets = this.props.resources.inlets.filter(
        (i) => i.enclosureSerialNumber === device.enclosureSerialNumber
      );

      const firstInlet = inlets[0] || {};
      const inletType = {
        single: "Standard Single Inlet",
        ats: "Auto Transfer Switch (ATS)",
        dual: "Dual Inlet/Circuit",
      }[firstInlet.inletType] || ""
      return `${device.deviceName}${device.enclosureSerialNumber}${inletType}${firstInlet.inletPlug}`.toLowerCase().includes(this.state.currentFilter.toLowerCase());
    })


    const groups = this.props.resources.groups.filter(
      (g) => g.groupType === "devices"
    );
    const editingGroupHasChanges = (() => {
      if (!this.state.editingActive) {
        return false;
      } else {
        const currentGroup =
          groups.find((g) => g.id === this.state.selectedGroupId) || {};
        return (
          this.state.selectedDevices
            .map((d) => d.enclosureSerialNumber)
            .join("") !== (currentGroup.items || []).join("")
        );
      }
    })();
    const allInlets = this.props.resources.inlets
      // .filter(i => devices.find(d => d.enclosureSerialNumber === i.enclosureSerialNumber))
      .map((i) => {
        const foundDevice = devices.find(
          (d) => d.enclosureSerialNumber === i.enclosureSerialNumber
        );
        if (!foundDevice) return null;
        return {
          ...i,
          deviceName:
            foundDevice.deviceName || `${foundDevice.enclosureSerialNumber}`,
        };
      })
      .filter((i) => i);
    const totalCurrentRms = allInlets
      .reduce((sum, item) => sum + (item.inletCurrentRms || 0), 0)
      .toFixed(3);
    const totalWattage = allInlets
      .reduce((sum, item) => sum + (item.inletActivePower || 0), 0)
      .toFixed(3);
    const inletWithPowerFactor = allInlets.filter(
      (i) => i.inletPowerFactor !== undefined // filter out 3 phase
    );
    const averagePowerFactor =
      inletWithPowerFactor.length > 0
        ? (
            inletWithPowerFactor.reduce(
              (sum, item) => sum + (item.inletPowerFactor || 0),
              0
            ) / inletWithPowerFactor.length
          ).toFixed(3)
        : 0;

    const totalKwh = allInlets
      .reduce((sum, item) => (sum += item.inletEnergyAccumulation || 0), 0)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
      <div>
        <Chartz
          averagePowerFactor={averagePowerFactor}
          totalKwh={totalKwh}
          totalCurrentRms={totalCurrentRms}
          allInlets={allInlets}
          loading={inletsLoading}
          totalWattage={totalWattage}
        />
          <div
            style={{
              borderBottom: "solid 1px #dedede",
              // marginLeft: "15px",
              // marginRight: "15px",
              margin: "20px 10px"
              
            }}
          />
        {/* {!this.props.hideGroupInputs && (
          <GroupInputs
            updateState={(newState) => this.setState(newState)}
            setActiveInletGroup={this.setActiveInletGroup}
            editingGroupHasChanges={editingGroupHasChanges}
            editingActive={this.state.editingActive}
            groups={groups}
            createGroup={this.createGroup}
            removeGroup={this.removeGroup}
            updateGroup={this.updateGroup}
            selectedGroup={this.state.selectedGroup}
            selectedGroupId={this.state.selectedGroupId}
            selectedDevices={this.state.selectedDevices}
          />
        )} */}
        <div className="grid-x">
          <div className="cell large-6 medium-6 small-12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="text"
                  placeholder="Search for device name, inlet plug, or serial number"
                  value={this.state.searchBar}
                  onChange={(e) => {
                    this.setState({ searchBar: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.applyFilters();
                    }
                  }}
                />
                <span
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    padding: "0.45rem",
                    height: "2.43rem",
                    margin: "0 0 1rem",
                    borderRadius: "0.1875rem",
                    // color: "#fff",
                  }}
                  className="background-color-secondary"
                  onClick={() => {
                    this.applyFilters();
                  }}
                >
                  <i className="material-icons" style={{ marginRight: "0px"}}>search</i>
                </span>
              </div>
            </div>
        </div>
        <div
          style={{
            overflowX: "auto",
          }}
        >
          <div className="grid-row header">
            {/* <div style={{ flex: columnWidths[0] }}>
              {!this.state.selectedGroup &&
                (this.props.hideGroupInputs ? null : (
                  <input
                    type="checkbox"
                    checked={
                      this.state.selectedDevices.length === devices.length
                    }
                    onChange={() => {
                      if (
                        this.state.selectedDevices.length === devices.length
                      ) {
                        this.setState({ selectedDevices: [] });
                      } else {
                        this.setState({
                          selectedDevices: [...devices],
                        });
                      }
                    }}
                    style={{
                      boxShadow: "0px",
                      margin: "0px",
                      height: "1.4rem",
                    }}
                  />
                ))}
            </div> */}
            {/* <div style={{ flex: columnWidths[1] }}></div> */}
            <div style={{ flex: columnWidths[2] }}>Plug</div>
            <div style={{ flex: columnWidths[3] }}>PDU</div>
            <div style={{ flex: columnWidths[4] }}>Current</div>
            <div style={{ flex: columnWidths[5] }}>Voltage</div>
            <div style={{ flex: columnWidths[6] }}>Line Freq</div>
            <div style={{ flex: columnWidths[7] }}>Power Factor</div>
            <div style={{ flex: columnWidths[8] }}>Active Power</div>
            <div style={{ flex: columnWidths[9] }}>Energy Consumption</div>
            <div style={{ flex: columnWidths[10] }}>Last Update</div>
            <div style={{ flex: columnWidths[11] }}></div>
          </div>

          {!this.props.resources.devicesLoaded && (
            <LoadingSection columnWidths={columnWidths} />
          )}

          {this.props.resources.devicesLoaded && devices.length === 0 && (
            <React.Fragment>
              <div className="grid-row-wrapper">
                <div className="grid-row">
                  <div
                    style={{
                      paddingLeft: "50px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div>No Inlets Found</div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "40px",
                }}
              >
                <span className="button">
                  <a
                    href="https://synaccess.readme.io/reference/connect-a-synlink-pdu"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "white" }}
                  >
                    Learn how to connect a SynLink PDU
                  </a>
                </span>
              </div>
            </React.Fragment>
          )}
          {filteredDevices.map((device) => {
            const deviceIsSelected = !!this.state.selectedDevices.find(
              (i) => i.enclosureSerialNumber === device.enclosureSerialNumber
            );
            const inlets = this.props.resources.inlets.filter(
              (i) => i.enclosureSerialNumber === device.enclosureSerialNumber
            );

            const firstInlet = inlets[0] || {};
            return (
              <div
                key={device.enclosureSerialNumber}
                className={`grid-row-wrapper ${
                  device.connectionState === "Connected" ? "" : "disconnected"
                }`}
                title={
                  device.connectionState === "Connected" ? "" : "Disconnected"
                }
              >
                <div
                  className={`grid-row ${
                    device.connectionState === "Connected"
                      ? "connected"
                      : "disconnected"
                  }`}
                >
                  {/* <div style={{ flex: columnWidths[0] }}>
                    {this.props.hideGroupInputs ? null : (
                      <input
                        type="checkbox"
                        checked={deviceIsSelected}
                        onChange={() => {
                          if (
                            this.state.selectedGroupId &&
                            !this.state.editingActive
                          ) {
                            return;
                          }
                          if (deviceIsSelected) {
                            this.setState({
                              selectedDevices:
                                this.state.selectedDevices.filter(
                                  (d) =>
                                    d.enclosureSerialNumber !==
                                    device.enclosureSerialNumber
                                ),
                            });
                          } else {
                            this.setState({
                              selectedDevices: [
                                ...this.state.selectedDevices,
                                device,
                              ],
                            });
                          }
                        }}
                        style={{
                          boxShadow: "",
                          margin: "0px",
                          height: "1.4rem",
                        }}
                      />
                    )}
                  </div> */}
                  {/* <div style={{ flex: columnWidths[1] }}>
                  <div
                    style={{
                      fontWeight: "600",
                      flex: columnWidths[0],
                      color:
                        device.connectionState === "Connected"
                          ? "forestgreen"
                          : "maroon",
                    }}
                    // className={device.connectionState === "Connected" ? 'green' : 'red'}
                    title={
                      device.connectionState === "Connected"
                        ? "Connection Established"
                        : "Connection Cannot Be Made"
                    }
                  >
                    <i className="material-icons" style={{ marginLeft: "4px" }}>
                      {device.connectionState === "Connected"
                        ? "cloud_done"
                        : "cloud_off"}
                    </i>
                  </div>
                </div> */}
                  <div style={{ flex: columnWidths[2] }}>
                    {inletsLoading ? (
                      <div className="skeleton-blank" />
                    ) : (
                      <Link
                        to={`/devices/connected/${device.enclosureSerialNumber}/inlets`}
                      >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          textDecoration: "underline",
                          paddingLeft: "10px"
                        }}
                      >
                          <span
                            style={{
                              fontWeight: "600",
                            }}
                          >
                            {{
                              single: "Standard Single Inlet",
                              ats: "Auto Transfer Switch (ATS)",
                              dual: "Dual Inlet/Circuit",
                            }[firstInlet.inletType] || "-"}
                          </span>
                          <span>
                            {firstInlet.inletPlug || "-"}
                            {device.inletConfig === "dualcircuit" && " x 2"}
                          </span>
                        </span>
                      </Link>
                    )}
                  </div>
                  <div style={{ flex: columnWidths[3] }}>
                    {devicesLoading && <div className="skeleton-blank" />}
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <span>
                          {device.deviceName === "SynLink PDU" ||
                          !device.deviceName
                            ? `PDU SN:${device.enclosureSerialNumber}`
                            : device.deviceName}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{ flex: columnWidths[4] }}>
                    <GridColumn
                      loading={inletsLoading || device.loading}
                      loaded={inletsLoaded}
                      connected={firstInlet.connectionState === "Connected"}
                      component={
                        firstInlet.inletPhase === "Three Phase" ? (
                          <DataValues
                            dataValues={(firstInlet.inletLines || []).map((i) => ({
                              value: i.currentRms || 0,
                              unit: `A (${i.line})`,
                            }))}
                          />
                        ) : (
                          <DataValues
                            dataValues={inlets
                              .filter((i) => i.atsInletActive || !i.atsInletId) // only keep if it's an active inlet, or its non ats
                              .map((i) => ({
                                value: i.inletCurrentRms || 0,
                                unit: "A",
                              }))}
                          />
                        )
                      }
                    />
                  </div>
                  <div style={{ flex: columnWidths[5] }}>
                    <GridColumn
                      loading={inletsLoading || device.loading}
                      loaded={inletsLoaded}
                      connected={firstInlet.connectionState === "Connected"}
                      component={
                        firstInlet.inletPhase === "Three Phase" ||
                        !device.inletEnergySupported ? (
                          "-"
                        ) : (
                          <DataValues
                            dataValues={inlets
                              .filter((i) => i.atsInletActive || !i.atsInletId) // only keep if it's an active inlet, or its non ats
                              .map((i) => ({
                                value: i.inletVoltageRms || 0,
                                unit: "V",
                              }))}
                          />
                        )
                      }
                    />
                  </div>
                  <div style={{ flex: columnWidths[6] }}>
                    <GridColumn
                      loading={inletsLoading || device.loading}
                      loaded={inletsLoaded}
                      connected={firstInlet.connectionState === "Connected"}
                      component={
                        firstInlet.inletPhase === "Three Phase" ||
                        !device.inletEnergySupported ? (
                          "-"
                        ) : (
                          <DataValues
                            dataValues={inlets
                              .filter((i) => i.atsInletActive || !i.atsInletId) // only keep if it's an active inlet, or its non ats
                              .map((i) => ({
                                value: i.inletLineFrequency || 0,
                                unit: "Hz",
                              }))}
                          />
                        )
                      }
                    />
                  </div>
                  <div style={{ flex: columnWidths[7] }}>
                    <GridColumn
                      loading={inletsLoading || device.loading}
                      loaded={inletsLoaded}
                      connected={firstInlet.connectionState === "Connected"}
                      component={
                        firstInlet.inletPhase === "Three Phase" ||
                        !device.inletEnergySupported ? (
                          "-"
                        ) : (
                          <DataValues
                            dataValues={inlets
                              .filter((i) => i.atsInletActive || !i.atsInletId) // only keep if it's an active inlet, or its non ats
                              .map((i) => ({
                                value: i.inletPowerFactor || 0,
                                unit: "",
                              }))}
                          />
                        )
                      }
                    />
                  </div>
                  <div style={{ flex: columnWidths[8] }}>
                    <GridColumn
                      loading={inletsLoading || device.loading}
                      loaded={inletsLoaded}
                      connected={firstInlet.connectionState === "Connected"}
                      component={
                        firstInlet.inletPhase === "Three Phase" ||
                        !device.inletEnergySupported ? (
                          "-"
                        ) : (
                          <DataValues
                            dataValues={inlets
                              .filter((i) => i.atsInletActive || !i.atsInletId) // only keep if it's an active inlet, or its non ats
                              .map((i) => ({
                                value: i.inletActivePower || 0,
                                unit: "W",
                              }))}
                          />
                        )
                      }
                    />
                  </div>
                  <div style={{ flex: columnWidths[9] }}>
                    <GridColumn
                      loading={inletsLoading || device.loading}
                      loaded={!device.loading || inletsLoaded}
                      connected={firstInlet.connectionState === "Connected"}
                      component={
                        // firstInlet.inletPhase === "Three Phase" ||
                        !device.inletEnergySupported ? (
                          "-"
                        ) : (
                          <DataValues
                            dataValues={inlets
                              .filter((i) => i.atsInletActive || !i.atsInletId) // only keep if it's an active inlet, or its non ats
                              .map((i) => ({
                                value: (i.inletEnergyAccumulation || 0)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                unit: "kWh",
                              }))}
                          />
                        )
                      }
                    />
                  </div>
                  <div style={{ flex: columnWidths[10] }}>
                    <span
                      style={{
                        fontSize: "90%",
                      }}
                    >
                      {device.lastUpdated &&
                        `${formatDistance(device.lastUpdated, new Date())} ago`}
                    </span>
                  </div>
                  <div style={{ flex: columnWidths[11] }}>
                    <span
                      title="Refresh PDU Information"
                      className="black"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.props.getDeviceItemAndData(
                          device.enclosureSerialNumber
                        );
                      }}
                    >
                      <i
                        style={{ fontWeight: "600", color: "#6c6f72" }}
                        className="material-icons"
                      >
                        sync
                      </i>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

class InletGroupForm extends React.Component {
  state = {
    groupName: "",
  };
  submitGroup = () => {
    this.props.createResource(
      "groups",
      {
        name: this.state.groupName,
        groupType: "devices",
        items: this.props.items,
      },
      (err, newGroup) => {
        if (newGroup) {
          this.props.changeAppState({
            selectedGroup: this.state.groupName,
            selectedGroupId: newGroup.id,
          });
          this.props.close();
        } else {
          console.log(err, newGroup);
        }
      }
    );
  };
  render() {
    return (
      <div>
        <TextInput
          label="Group Name"
          callback={(value) => {
            this.setState({ groupName: value });
          }}
          value={this.state.groupName}
          type="text"
          error=""
          placeholder="Example Name"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "30px",
            paddingBottom: "-30px",
          }}
        >
          <div
            className="button"
            style={{
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
            onClick={() => this.props.close()}
          >
            Cancel
          </div>
          <div
            className="button"
            onClick={() => {
              this.submitGroup();
            }}
          >
            Submit
          </div>
        </div>
      </div>
    );
  }
}

const GridColumn = ({ loading, loaded, connected, component }) => {
  // if (!loaded && loading) {
  if (loading) {
    return <div className="skeleton-blank" />;
  } else if (loaded && !connected) {
    return "-";
  } else {
    return component;
  }
};

const DataValue = ({ value, unit }) => {
  return (
    <span style={{ display: "flex", alignItems: "flex-end" }}>
      <span
        style={{
          fontWeight: "600",
          fontSize: "105%",
        }}
      >
        {value}
      </span>
      <span
        style={{
          fontSize: "80%",
          paddingLeft: "4px",
          paddingBottom: "2px",
        }}
      >
        {unit}
      </span>
    </span>
  );
};

const DataValues = ({ dataValues }) => {
  return (
    <span style={{ display: "flex", flexDirection: "column" }}>
      {dataValues.map((i, y) => (
        <span key={y}>
          <DataValue
            value={typeof i.value === "string" ? i.value : i.value.toFixed(2)}
            unit={i.unit}
          />
        </span>
      ))}
    </span>
  );
};
const GroupInputs = ({
  updateState,
  setActiveInletGroup,

  editingGroupHasChanges,
  editingActive,

  groups,
  createGroup,
  removeGroup,
  updateGroup,

  selectedGroup,
  selectedGroupId,
  selectedDevices,
}) => {
  return (
    <div
      style={
        {
          // display: "flex",
          // justifyContent: "space-between",
          // marginBottom: "10px",
        }
      }
      className="grid-x "
    >
      <div style={{}} className="cell large-6">
        <SelectInput
          label=""
          callback={(result) => {
            setActiveInletGroup(parseInt(result, 10));
          }}
          value={selectedGroupId}
          options={groups.map((g) => ({
            label: g.name,
            value: g.id,
          }))}
          inputContainerStyle={{
            margin: "0px",
          }}
          inputStyle={{
            backgroundColor: "white",
            fontWeight: "600",
          }}
          defaultValue="Select A Group"
        />
      </div>
      {selectedDevices.length > 0 && !selectedGroup && (
        <div className="cell large-6">
          <div
            className="button"
            onClick={() => {
              createGroup();
            }}
          >
            Create New Group ({selectedDevices.length} selected)
          </div>
        </div>
      )}

      {editingActive && (
        <div className="cell">
          <div // edit group buttons
            style={{
              display: "flex",
              // border: "solid green 1px"
            }}
            className="grid-x "
          >
            <div
              className=" cell"
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div
                  className="button"
                  style={{
                    backgroundColor: "rgba(0,0,0,0.1)",
                    color: "rgba(0,0,0,0.9)",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    const currentGroup = groups.find(
                      (g) => g.id === selectedGroupId
                    ) || { items: [] };
                    updateState({
                      editingActive: false,
                      // selectedDevices: currentGroup.items.map(i => ({ enclosureSerialNumber: i }))
                    });
                    setActiveInletGroup(parseInt(currentGroup.id, 10));
                  }}
                >
                  Cancel Edit
                </div>

                <div
                  className="button"
                  style={{
                    backgroundColor: "rgb(140, 56, 56)",
                    // marginLeft: "5px",
                  }}
                  onClick={() => {
                    removeGroup(selectedGroupId);
                  }}
                >
                  Remove Group
                </div>
              </div>
              {editingGroupHasChanges && (
                <div
                  className="button"
                  style={{
                    // backgroundColor: '#49535E',
                    backgroundColor: "royalblue",
                    // color: 'rgba(0,0,0,0.9)',
                    color: "white",
                    marginLeft: "5px",
                  }}
                  onClick={() => {
                    updateGroup(selectedGroupId);
                  }}
                >
                  Save Changes
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {selectedGroup && !editingActive && (
        <div className="cell">
          <div // selected group buttons
            style={
              {
                // display: 'flex',
                // justifyContent: 'space-between',
                // flexWrap: 'wrap'
                // border: "solid blue 1px"
              }
            }
            className="grid-x grid-padding-y"
          >
            <div className="cell large-12 large-order-1 medium-order-2 small-order-2">
              <div
                className="button"
                style={{
                  backgroundColor: "rgba(0,0,0,0.1)",
                  color: "rgba(0,0,0,0.9)",
                }}
                onClick={() => {
                  updateState({
                    selectedGroup: "",
                    selectedGroupId: "",
                    selectedDevices: [],
                    editingActive: false,
                  });
                }}
              >
                Unselect Group
              </div>
              <div
                className="button"
                style={{
                  // backgroundColor: '#49535E',
                  backgroundColor: "#49535E",
                  // color: 'rgba(0,0,0,0.9)',
                  color: "white",
                  marginLeft: "5px",
                }}
                onClick={() => {
                  updateState({
                    editingActive: true,
                  });
                }}
              >
                Edit Group
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const Chartz = ({
  averagePowerFactor,
  totalKwh,
  totalCurrentRms,
  // allInlets,
  totalWattage,
  loading,
}) => {
  return (
    <div
      className="grid-x grid-padding-x grid-padding-y"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <div className="cell large-3 medium-6">
        <div
          style={{
            flex: 1,
            borderRadius: "3px",
            display: "flex",
            alignItems: "flex-start",
            paddingLeft: "20px",
            justifyContent: "flex-start",
            // textAlign: "flex-start",
            height: "100%",
            boxShadow: "2px 2px 5px rgba(0,0,0,0.14)",
          }}
          className="app-item"
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "120px",
              }}
            >
              <Loading black />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div>Total Consumption</div>
              <div
                style={{
                  fontSize: "150%",
                  fontWeight: "600",
                  padding: "12px 0px 7px 0px",
                }}
              >
                {totalKwh} kWh
              </div>
              <div
                style={{
                  color: "grey",
                  fontSize: "85%",
                }}
              >
                Total Energy Consumption for all inlets
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="cell large-3 medium-6">
        <div
          style={{
            flex: 1,
            borderRadius: "3px",
            display: "flex",
            paddingLeft: "20px",
            // textAlign: "center",
            height: "100%",
            boxShadow: "2px 2px 5px rgba(0,0,0,0.14)",
          }}
          className="app-item"
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "120px",
              }}
            >
              <Loading black />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div>Total Current RMS</div>
              <div
                style={{
                  fontSize: "150%",
                  fontWeight: "600",
                  padding: "12px 0px 7px 0px",
                }}
              >
                {totalCurrentRms} A
              </div>
              <div
                style={{
                  color: "grey",
                  fontSize: "85%",
                }}
              >
                Total current output for all outlets
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="cell large-3 medium-6">
        <div
          className="app-item"
          style={{
            height: "100%",
            display: "flex",
            // flexDirection: 'column',
            alignItems: "flex-start",
            paddingLeft: "20px",
            // paddingTop: '20px',
            // paddingBottom: '20px',
            borderRadius: "3px",
            boxShadow: "2px 2px 5px rgba(0,0,0,0.14)",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "120px",
              }}
            >
              <Loading black />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div>Total Wattage Output</div>
              <div
                style={{
                  fontSize: "150%",
                  fontWeight: "600",
                  padding: "12px 0px 7px 0px",
                }}
              >
                {totalWattage} W
              </div>
              <div
                style={{
                  color: "grey",
                  fontSize: "85%",
                }}
              >
                For PDUs with voltage measurements
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="cell large-3 medium-6">
        <div
          className="app-item"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            borderRadius: "3px",
            paddingLeft: "20px",
            boxShadow: "2px 2px 5px rgba(0,0,0,0.14)",
            height: "100%",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "120px",
              }}
            >
              <Loading black />
            </div>
          ) : (
            <React.Fragment>
              <div>
                <div>Avg Power Factor</div>
                <div
                  style={{
                    fontSize: "150%",
                    fontWeight: "600",
                    padding: "12px 0px 7px 0px",
                  }}
                >
                  {averagePowerFactor}
                </div>
                <div
                  style={{
                    color: "grey",
                    fontSize: "85%",
                  }}
                >
                  {/* stuff here */}
                  Average power factor for each inlet
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "5px",
                  overflow: "hidden",
                }}
              >
                <Pie
                  options={{}}
                  data={{
                    datasets: [
                      {
                        data: [
                          Math.abs(averagePowerFactor),
                          1 - Math.abs(averagePowerFactor),
                        ],
                        backgroundColor: ["#42996e", "#eee"],
                        borderWidth: 0,
                      },
                    ],
                  }}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const LoadingSection = ({ columnWidths }) => {
  return (
    <div style={{}}>
      {[null, null, null, null].map((item, index) => {
        return (
          <div className="grid-row-wrapper" key={index}>
            <div className="grid-row">
              <div style={{ flex: columnWidths[0] }}></div>
              <div style={{ flex: columnWidths[1] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[2] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[3] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[4] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[5] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[6] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[7] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[8] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[9] }}>
                <div className="skeleton-blank" />{" "}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
