import React from "react";

import TextInput from "../../components/common/inputs/textInput";
import ToggleInput from "../../components/common/inputs/toggleInput";
import Loading from "../../components/common/loading";
import ModalManager from "../../components/common/modal/ModalManager";
import axios from "axios";
import PDUAccessDropdown from "../../components/users/pduAccessDropdown";
import NotificationManager from "../../utils/notificationManager";
import ButtonInput from "../../components/common/inputs/buttonInput";
import SubHeader from "../../components/common/topHeader/subHeader";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import SelectInput from "../../components/common/inputs/selectInput";


export default class UserTeamMemberItem extends React.Component {
  componentDidMount() {
    if (!this.props.resources.groupsLoaded && !this.props.resources.groupsLoading) {
      this.props.fetchResource("groups")
    }
    if (!this.props.resources.rolesLoaded && !this.props.resources.rolesLoading) {
      this.props.fetchResource("roles")
    }
  }
  render() {

    const foundUser = (this.props.resources.userEnterprise?.users || []).find(
      (user) => user.user_id == this.props.match.params.userId
    );

    const sections = [{
      label: "User Settings",
      icon: "person",
      path: `/team/members/${this.props.match.params.userId}/settings`,
      component: UserSettings,
      props: {
        ...this.props
      }
      
    // }, {
    //   label: "Groups",
    //   icon: "group",
    //   path: `/team/members/${this.props.match.params.userId}/groups`,
    //   component: UserGroups,
    //   props: {
    //     ...this.props
    //   }
    }, {
      label: "Devices",
      icon: "devices",
      path: `/team/members/${this.props.match.params.userId}/devices`,
      component: UserDevices,
      props: {
        ...this.props
      }
    }, {
      label: "Privileges",
      icon: "lock",
      path: `/team/members/${this.props.match.params.userId}/privileges`,
      component: UserPrivileges,
      props: {
        ...this.props
      }

    }]

    return (
      <div>
        <SubHeader
          sections={sections.filter(s => !s.disabled)}
        />
        <div
          style={{
            marginLeft: "15px",
            marginRight: "15px",
            paddingTop: "20px",
            borderBottom: "solid #ddd 1px",
          }}
        >
          <h5>{foundUser?.email_id}</h5>
        </div>
        <div style={{ marginLeft: '15px', marginTop: "15px"}}>
          <Switch>
            {sections &&
              sections.map((section) => {
                return (
                  <Route
                    key={section.label}
                    path={section.path}
                    exact={section.exact}
                    render={(props) => {
                      // const CompToRender = scrollTopOnMount(section.component);
                      const CompToRender = section.component;
                      return (
                        <CompToRender
                          {...(section.props || {})}
                          match={this.props.match}
                        />
                      );
                    }}
                  />
                );
              })}
            {sections && (
              <Redirect
                exact
                to={`/team/members/${this.props.match.params.userId}/settings`}
              />
            )}
          </Switch>

        </div>
      </div>
    )
  }
}

class UserSettings extends React.Component {
  state = {
    user_id: "",
    active_in_enterprise: false,
    email_id: "",
    full_name: "",
    company: "",
    email_verified: false,
    approved: false,
    has_admin_privileges: false,
    password_login_disabled: false,

    enterprise: {},
    pdu_access_list: [],
    password: "",

    hasChanges: false,
    loading: false,
  };
  componentDidMount = async () => {
    this.setState({
      loading: true,
    });
    axios
      .get(`/v1/users/${this.props.match.params.userId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
          

        this.setState({
          ...((res.data || {}).data || {}),
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  deleteUser = () => {
    ModalManager.confirm(
      "This will delete the user permanently",
      "Are you sure you want to delete this user?",
      "Delete",
      (submitted, close) => {
        if (submitted) {
          close();
          axios
            .delete(`/v1/users/${this.state.user_id}`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              NotificationManager.success("User Deleted", "Success");
              this.props.history.push("/team/members");
              this.props.updateFetchWrapperState({
                userEnterprise: {
                  ...this.props.resources.userEnterprise,
                  users: this.props.resources.userEnterprise.users.filter(
                    (user) => user.user_id != this.state.user_id
                  ),
                }
              })
            })
            .catch((err) => {
              NotificationManager.alert("Error deleting user", "Error");
            });
        }
      }
    );
  }

  onSave = () => {
    let pdu_access_list = this.state.pdu_access_list
    if (this.state.pdu_access_list && (this.state.pdu_access_list.length === 1) && this.state.pdu_access_list[0] === "All PDUs") {
      pdu_access_list = []
    }
    axios.put(`/v1/users/${this.state.user_id}`, {
      email_verified: this.state.email_verified,
      approved: this.state.approved,
      active_in_enterprise: this.state.active_in_enterprise,
      has_admin_privileges: this.state.has_admin_privileges,
      pdu_access_list: pdu_access_list,
      password_login_disabled: this.state.password_login_disabled,
    }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then((res) => {
      NotificationManager.success("User updated", "Success");
      this.setState({
        hasChanges: false,
      });
      this.props.updateFetchWrapperState({
          userEnterprise: {
            ...this.props.resources.userEnterprise,
            users: this.props.resources.userEnterprise.users.map((user) => {
              if (user.user_id === this.state.user_id) {
                return {
                  ...user,
                  ...this.state,
                };
              }
              return user;
            }),
          }
      })
    })
    .catch((err) => {
      NotificationManager.alert("Error updating user", "Error");
      console.log(err);
    });

  };
  changePassClick = () => {
    ModalManager.confirm(
      "",
      !!this.state.password ? `Change Password` : "Set Password",
      // "",
      "",
      (submitted, close) => {
        if (submitted) {
          close();
        }
      },
      ({ close }) => (
        <ChangePasswordForm
          close={close}
          user={this.state}
          resetPassword={this.props.resetPassword}
          passwordExists={!!this.state.obscuredPassword}
        />
      ),
      true,
      true
    );
  };

  requestEnterpriseSetupEmail = () => {

    ModalManager
    .confirm(
      "Send a password setup email to the following user. ",
      "Send Setup Email",
      "Send email",
      (status, close) => {
        if (status) { // clicked sign in
          const enterpriseId = this.props.resources.userEnterprise?.id
          if (!enterpriseId) {
            NotificationManager.alert(`Error occurred sending email`);
            return;
          }
          const userId = this.props.match.params.userId;
          axios
          .post(
            `/v1/enterprises/${enterpriseId}/users/${userId}/invite`,
            {},
            {
              headers: {
                authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            // alert("Email sent")
            if (res.status == 200) {
              NotificationManager.success(`Setup Email sent successfully`);
            } else {
              NotificationManager.alert(`Error occurred sending email.`);
            }
          })
          .catch((err) => {
            console.log(err);
            NotificationManager.alert(`Error occurred sending email`);
          });
          close();

        } else { // clicked cancel or outside

        }
      },
      null,
      true
    )
  };
  render() {
    const {
      user_id,
      active_in_enterprise,
      email_id,
      email_verified,
      approved,
      password_login_disabled,
      has_admin_privileges,
      loading
    } = this.state;
    if (!user_id || loading) {
      // return "User not found";
      return <Loading black />;
    }

    const userEnterprise = this.props.resources?.userEnterprise || {};
    return (
      <div className="grid-container">
        <div className="grid-x grid-padding-x grid-padding-y grid-margin-x">
          <div
            className="cell"
            style={{ paddingBottom: "0px", paddingTop: "0px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                opacity: this.state.hasChanges ? 1 : 0,
              }}
            >
              <button
                className="button"
                onClick={() => {
                  if (!this.loading) {
                    this.onSave();
                  }
                }}
              >
                {this.state.loading ? <Loading /> : "Save"}
              </button>
            </div>
          </div>
          <div className="cell large-6">
            <h4 style={{ marginBottom: "15px" }}>User Info</h4>
            <div
              style={{
                // borderBottom: "1px solid #e0e0e0",
                // marginBottom: "20px",
                // paddingBottom: "10px",
              }}
            />
            <TextInput label="Email" value={email_id} readOnly={true} />
            <ToggleInput
              label={<div>Email Verified <span style={{ color: "#555"}}>{userEnterprise.email_verification_required ? "(Currently required)" : "(Not currently required)"}</span></div>}
              description="Manually verify email if they are required for user access."
              value={email_verified}
              callback={(value) => {
                this.setState({
                  email_verified: value,
                  hasChanges: true,
                });
              }}
            />
            <ToggleInput
              label="Approved"
              description="Approve user to allow access to the system."
              value={approved}
              disabled={this.state.is_enterprise_admin}
              callback={(value) => {
                this.setState({
                  approved: value,
                  hasChanges: true,
                });
              }}
            />
            <ToggleInput
              label="Password Login Disabled"
              description="Allow user to login with email and password. If password login is disabled, users will only be able to login with Single Sign On (SAML2.0)."
              value={password_login_disabled}
              callback={(value) => {
                this.setState({
                  password_login_disabled: value,
                  hasChanges: true,
                });
              }}
            />
            <ButtonInput
              // label={"Reset Password " + this.state.obscuredPassword ? "" : "(Not set)"} 
              // label={`Reset Password ${this.state.obscuredPassword ? "" : "(Not currently set)"}`}
              label={<div>Reset Password <span style={{ color: "#555"}}>{this.state.obscuredPassword ? "" : "(Not currently set)"}</span></div>}
              buttonName="Change Password"
              buttonCallback={() => {
                this.changePassClick();
              }}
            />
            <ButtonInput
              label="Send User Setup Email"
              description="Send a password setup email to the user.  If password login for this user is disabled, this email not be sent."
              buttonName="Send Setup Email"
              buttonCallback={() => {
                this.requestEnterpriseSetupEmail();
              }}
            />
            <ButtonInput
              label="Delete User Permanently"
              buttonName="Delete"
              buttonStyle={{
                backgroundColor: "maroon",
              }}
              buttonCallback={() => {
                this.deleteUser();
              }}
            />
          </div>
          <div className="cell large-6">
            <h4 style={{ marginBottom: "15px" }}>Team Settings</h4>
            <div
              style={{
                // borderBottom: "1px solid #e0e0e0",
                // marginBottom: "20px",
                // paddingBottom: "10px",
              }}
            />
            <TextInput label="Team" value={userEnterprise.name} readOnly={true} />
            <ToggleInput
              label="Active in Team"
              description="Users that are inactive will not be able to login or access the allocated resources."
              value={active_in_enterprise}
              callback={(value) => {
                this.setState({
                  active_in_enterprise: value,
                  hasChanges: true,
                });
              }}
            />
            <ToggleInput
              label="Admin Privileges"
              description="Admins will have the ability to modify SAML SSO settings, change team settings, create/update/delete users, and manage PDU access to users. Only admins are allowed to create, edit, or delete groups."
              value={has_admin_privileges}
              callback={(value) => {
                this.setState({
                  has_admin_privileges: value,
                  hasChanges: true,
                });
              }}
            />
            <div
              style={{
                fontSize: "16px",
                paddingBottom: "10px",
              }}
            >
              PDU Access List
            </div>
            <PDUAccessDropdown
              user={this.state}
              devices={this.props.resources.devices}
              devicesLoading={this.props.resources.devicesLoading}
              groups={this.props.resources.groups}
              pdu_access_list={this.state.pdu_access_list}
              onPduAccessListChange={(updatedPDUList) => {
                this.setState({
                  pdu_access_list: updatedPDUList,
                  hasChanges: true,
                });
              }}
            />
          </div>
        </div>
      </div>
    );
    
  }
}

class ChangePasswordForm extends React.Component {
  state = {
    password: "",
    confirmPassword: "",
    errorMsg: "",
    loading: false,
  };
  resetPasswordButtonPress = () => {
    this.setState({
      errorMsg: "",
      loading: true,
    });
    if (this.state.password === this.state.confirmPassword) {
      axios
        .put(
          `/v1/users/${this.props.user.user_id}/password`,
          {
            password: this.state.password,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.setState({
            loading: false,
          });
          this.props.close();
          NotificationManager.success("User Password Changed Successfully");
        })
        .catch((err) => {
          this.setState({
            loading: false,
            errorMsg: err?.response?.data?.message || "Error changing password",
          });
        });
    } else {
      this.setState({
        loading: false,
        errorMsg: "Passwords do not match",
      });
    }
  };

  render() {
    const { passwordExists } = this.props;
    return (
      <React.Fragment>
        <TextInput
          label="Password"
          callback={(text) => this.setState({ password: text })}
          value={this.state.password}
          inputContainerStyle={{
            width: "100%",
            marginBottom: "0px",
          }}
          type="password"
        />
        <TextInput
          label="Confirm Password"
          callback={(text) => this.setState({ confirmPassword: text })}
          value={this.state.confirmPassword}
          inputContainerStyle={{
            width: "100%",
            marginBottom: "0px",
          }}
          type="password"
        />
        <div
          style={{
            color: "maroon",
            padding: "10px 0px 20px 0px",
          }}
        >
          <div>
            {[
              {
                name: "2 lowercase characters required",
                valid: /[a-z].*[a-z]/.test(this.state.password),
              },
              {
                name: "2 uppercase characters required",
                valid: /[A-Z].*[A-Z]/.test(this.state.password),
              },
              {
                name: "2 number characters required",
                valid: /[0-9].*[0-9]/.test(this.state.password),
              },
              {
                name: "2 special characters required",
                valid: /[!@#$%^&*;].*[!@#$%^&*;]/.test(this.state.password),
              },
            ].map((validation) => {
              return (
                <div
                  key={validation.name}
                  style={{
                    color: validation.valid ? "forestgreen" : "#666",
                    display: "flex",
                    paddingBottom: "10px",
                    alignItems: "center",
                  }}
                >
                  <i className="material-icons">
                    {validation.valid ? "check" : "cancel"}
                  </i>
                  <span>{validation.name}</span>
                </div>
              );
            })}
          </div>
          {this.state.errorMsg &&
            this.state.errorMsg.split(",").map((msg) => {
              return (
                <div key={msg}>
                  <div
                    style={{
                      color: "maroon",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="material-icons"
                      style={{ marginRight: "5px" }}
                    >
                      error
                    </i>
                    <span>{msg}</span>
                  </div>
                </div>
              );
            })}
        </div>
        <button
          className="button"
          onClick={() => {
            this.resetPasswordButtonPress();
          }}
        >
          {this.state.loading ? <Loading /> : "Reset Password"}
        </button>
      </React.Fragment>
    );
  }
}



class UserGroups extends React.Component {
render() {
  return (
    <div>
      <h1>Groups</h1>
    </div>
  )
}
}

class UserPrivileges extends React.Component {
  state = {
    individualRoleId: "",
  }
  componentDidMount() {
    const foundUser = (this.props.resources.userEnterprise?.users || []).find(user => user.user_id == this.props.match.params.userId);
    if (foundUser) {
      this.setState({
        individualRoleId: foundUser.role_id,
      })
    }
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.resources.userEnterpriseLoaded && !prevProps.resources.userEnterpriseLoaded) {
      const foundUser = (this.props.resources.userEnterprise?.users || []).find(user => user.user_id == this.props.match.params.userId);
      if (foundUser) {
        this.setState({
          individualRoleId: foundUser.role_id,
        })
      }
    }
  //     if (this.props.resources.rolesLoaded && !prevProps.resources.rolesLoaded) {
  //         const foundRole = this.props.resources.roles.find(role => role.id == this.props.match.params.roleId);
  //         if (foundRole) {
  //             this.setState({individualRoleId: foundRole.id})
  //         }
  //     }
  }
  processIndividualRoleChange = (roleId) => {
    if (roleId === "create-new-role") {
      this.props.history.push("/users/roles/new");
    } else {
      ModalManager.confirm(
        "Are you sure you want to assign this role to this user?",
        "Confirm Role Assignment",
        "Confirm",
        (status, close) => {
          if (status) {
            axios.put(`/v1/users/${this.props.match.params.userId}`, {
              role_id: roleId,
            }, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              }
            }).then((res) => {
              NotificationManager.success("Role Assigned", "Success");
              this.props.updateFetchWrapperState({
                  userEnterprise: {
                    ...this.props.resources.userEnterprise,
                    users: this.props.resources.userEnterprise.users.map((user) => {
                      if (user.user_id == this.props.match.params.userId) {
                        return {
                          ...user,
                          role_id: roleId,
                        }
                      }
                      return user;
                    })
                }
              })
              this.setState({
                individualRoleId: roleId,
              })
              close();
            }).catch((err) => {
              NotificationManager.alert("Error assigning role", "Error");
              console.log(err);
            })

          }
        }
      )
    }
  };

  render() {
    const roles = this.props.resources.roles || [];
    const foundRole = roles.find(role => role.id == this.state.individualRoleId);

    const foundUser = (this.props.resources.userEnterprise?.users || []).find(user => user.user_id == this.props.match.params.userId);

    const userGroups = (foundUser?.pdu_access_list || []).filter(p => p.startsWith("Group:")).map(groupId => {
      return this.props.resources.groups.find(g => g.id == groupId.replace("Group:", ""));
    }).filter(g => !!g);

    const userGroupRoles = userGroups.map(g => {
      return ({
        role: roles.find(r => r.id == g.role_id),
        group: g
      })
    }).filter(r => !!r);

    return (
      <div>
          <h4>Roles</h4>
          <div style={{ marginBottom: "40px" }}>
          Roles are used to manage user permissions and restrict access privileges. They can be assigned either through groups or individually:
          <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
            <li>
            <b>Group-based Roles</b>: When roles are assigned through groups, they apply to all users within that group and affect the devices the group has access to.

            </li>
            <li>
            <b>Individual Roles</b>: These are assigned to a single user and apply to all devices the user can access.
            </li>
          </ul>
            Role assignments are additive, meaning if a user has multiple roles for a specific PDU, they receive the combined permissions from all their roles. Individual roles will extend to all devices within the user's access range.
          </div>
            
          {!foundRole && (this.props.resources.userEnterpriseLoading ? (
            <Loading  black />
          ) : (
            <SelectInput
              label="Individual Role Assignment"
              // description="Assign a role to this user. Utilize roles to manage user permissions and restrict privileges."
              value={this.state.individualRoleId}
              callback={this.processIndividualRoleChange}
              defaultSelectText="Select a role"
              options={[
                ...roles.map((r) => ({
                  value: r.id,
                  label: r.name,
                })),
                {
                  label: "+ Create New Role",
                  value: "create-new-role"
                }
              ]}
            />
          ))}
          {/* <h4>Role Assignment granted from group</h4> */}
          <div className="grid-x">
            <div className="cell large-10 medium-10">
              <div className="grid-row header no-min-width">
                <div style={{ flex: 4 }}>Role</div>
                <div style={{ flex: 4 }}>Assignment</div>
                <div style={{ flex: 1 }}>Action</div>
              </div>
                {foundRole &&
                      <div className="grid-row no-min-width">
                        {/* <div style={{ flex: 4 }}>{foundRole.name}</div> */}
                <div style={{ flex: 4}}>
                  {this.props.resources.userEnterpriseLoading ? (
                    <Loading  black />
                  ) : (
                    <SelectInput
                      // label="Role Assigned"
                      value={this.state.individualRoleId}
                      callback={this.processIndividualRoleChange}
                      defaultSelectText={this.state.individualRoleId ? "No Role" : "No role assigned. Select a role"}
                      inputContainerStyle={{
                        margin: "0px",
                        padding: "0px",
                        marginRight: "10px"
                      }}
                      options={[
                        ...roles.map((r) => ({
                          value: r.id,
                          label: r.name,
                        })),
                        {
                          label: "+ Create New Role",
                          value: "create-new-role"
                        }
                      ]}
                    />
                  )}
                  </div>
                  <div style={{ flex: 4 }}>Individual</div>
                  <div style={{ flex: 1 }}>
                    {this.state.individualRoleId &&
                    // <span className="button" style={{
                    //   marginBottom: "0px"
                    // }}>
                    //   View
                    // </span>
                    <Link
                        to={`/users/roles/${this.state.individualRoleId}`}
                      >
                        <span className="button" style={{
                          marginBottom: "0px"
                        }}>
                          View
                        </span>
                      </Link>

                    }
                  </div>
                </div>
              }
              {userGroupRoles.map((userGroupRole, i) => {
                const role = userGroupRole.role;
                const group = userGroupRole.group;
                if (!role || !group) return null;
                return (
                  <div className="grid-row no-min-width" key={i}>
                    <div style={{ flex: 4 }}>{role.name}</div>
                    <div style={{ flex: 4 }}>Group: 
                      <Link to={`/devices/groups/${group.id}/users`}>
                        <span style={{
                          textDecoration: "underline",
                          paddingLeft: "3px"
                        }}>
                          {group.name}
                        </span>

                      </Link>
                    
                    </div>
                    <div style={{ flex: 1 }}>
                      <Link
                        to={`/users/roles/${role.id}`}
                      >
                        <span className="button" style={{
                          marginBottom: "0px"
                        }}>
                          View
                        </span>
                      </Link>
                    </div>
                  </div>
                )
              })}

            </div>
          </div>


      </div>
    )
  }
}



class UserDevices extends React.Component {
  state = {
    devices: [],

    userDevicesLoading: false,
  }
  
  componentDidMount() {
    this.setState({
      userDevicesLoading: true,
    });
    axios.get(`/v1/users/${this.props.match.params.userId}/devices`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    }).then((res) => {
      this.setState({
        devices: res.data.data,
        userDevicesLoading: false,
      })
    }).catch((err) => {
      console.log(err);
      this.setState({
        userDevicesLoading: false,
      })  
    })
  }
  render() {

    const foundUser = (this.props.resources.userEnterprise?.users || []).find(user => user.user_id == this.props.match.params.userId);
    if (!foundUser) return <Loading black />
    const accessListOrigins = (foundUser || {}).pdu_access_list || [];
    const groupAccessList = accessListOrigins.filter((a) => a.startsWith("Group:"));
    const deviceAccessList = accessListOrigins.filter((a) => a.startsWith("PDU:"));

    const groupAccessListFound = groupAccessList.map((a) => {
      const groupId = a.replace("Group:", "");
      return this.props.resources.groups.find((g) => (g || {}).id == groupId);
    }).filter((g) => !!g);

    return (
      <div>
        <h4>Devices</h4>
        <div style={{ marginBottom: "25px"}}>
        Displays the devices the user has access to, along with their current status (online/offline). Device access can be assigned in the following ways:
        <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
          <li><b>Group-based Assignment</b>: Devices assigned through groups are accessible to all users within the group.</li>
          <li><b>Individual Assignment</b>: Devices assigned directly to the user are available only to that user.</li>
          <li><b>Admin Privileges</b>: All devices will be available to a user that are assigned Admin Privileges</li>
          

        </ul>
        <div>To assign individual devices or groups of devices to a user, navigate to the <Link to={`/team/members/${this.props.match.params.userId}/settings`}><span style={{ textDecoration: "underline" }}>User Settings Page</span></Link> to set a user's PDU Access List</div>
        </div>
        <div className="grid-x">
          <div className="cell large-10 medium-10">
              <div className="grid-row header no-min-width">
                <div style={{ flex: 4 }}>Model</div>
                <div style={{ flex: 4 }}>Device</div>
                <div style={{ flex: 2 }}>Status</div>
                <div style={{ flex: 7 }}>Assignment</div>
                {/* <div style={{ flex: 3 }}>Role Privileges</div> */}
              </div>
              {this.state.userDevicesLoading && (
                <div className="grid-row-wrapper" >
                  <div className="grid-row">
                    <div style={{ flex: 4 }}>
                      <div className="skeleton-blank" />
                    </div>
                    <div style={{ flex: 4 }}>
                      <div className="skeleton-blank" />
                    </div>
                    <div style={{ flex: 2 }}>
                      <div className="skeleton-blank" />
                    </div>
                    <div style={{ flex: 3 }}>
                      <div className="skeleton-blank" />
                    </div>
                    <div style={{ flex: 7 }}>
                      <div className="skeleton-blank" />
                    </div>

                  </div>
                </div>
              )}
            {this.state.devices.map((device, index) => {
              const foundLiveDevice = this.props.resources.devices.find(d => d.enclosureSerialNumber == device.enclosureSerialNumber);
              const series = (() => {
                if (device.modelNumber) {
                  if (
                    device.outletPwrMeasurementsSupported &&
                    device.outletSwitchingSupported
                    // device.inletEnergySupported
                  ) {
                    return "SynLink Switched Pro+";
                  } else if (
                    // device.inletEnergySupported &&
                    !device.outletPwrMeasurementsSupported &&
                    !device.outletSwitchingSupported
                  ) {
                    return "SynLink Monitored";
                  } else if (
                    device.inletCurrentSupported &&
                    device.outletSwitchingSupported
                  ) {
                    if (device.modelNumber.startsWith("NP")) {
                      return "NetBooter";
                    } else {
                      return "SynLink Switched";
                    }
                  } else {
                    return "";
                  }
                } else {
                  return "";
                }
              })();
              const modelLine = device.modelNumber
                ? `${device.formFactor} ${series}`
                : this.state.resources.devicesLoaded
                ? "-"
                : "";

              // return a comma separate list of groups and assigned specifically
              // return an array of

              const groupAssignments = groupAccessListFound.filter((g) => g.items.includes(device.enclosureSerialNumber.toString()));
              const userRole = (foundUser  || {}).role
              const deviceAssignments = deviceAccessList.includes(`PDU:${device.enclosureSerialNumber}`) ? `Assigned Directly ${userRole ? ` - ${userRole.name}` : ""}` : "";
              // const assignment = (() => {
              //   const groupAssignments = groupAccessListFound.filter((g) => g.items.includes(device.enclosureSerialNumber.toString()));
              //   const deviceAssignment = deviceAccessList.includes(`PDU:${device.enclosureSerialNumber}`) ? "Assigned Directly" : "";
              //   return `${groupAssignments.map((g) => g.name).join(", ")} ${deviceAssignment}`.trim();
              // })();
                 
              const groupRoles = groupAssignments.map(g => this.props.resources.roles.find(r => r.id == g.role_id)).filter(r => !!r);
              const individualRole = this.props.resources.roles.find(r => r.id == foundUser.role_id);

              // const allRoles = [
              //   ...groupRoles,
              // ]
              //   if ( individualRole ) {
              //     allRoles.push(individualRole);
              //   }
              
              // const allRolesWithoutDuplicates = allRoles.filter((role, index, self) =>
              //   index === self.findIndex((t) => (
              //     t.id === role.id
              //   ))
              // );
              return (
                <div key={device.id} className="grid-row no-min-width">
                  <div style={{ flex: 4 }}>
                    <Link
                      to={`/devices/connected/${device.enclosureSerialNumber}`}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          <b>
                          {
                              device.modelNumber
                                  ? device.modelNumber.startsWith("NP")
                                      ? device.modelNumber
                                      : `SP-${device.modelNumber}`
                                  : "-"
                          }
                          </b>
                        </span>
                        <span>{modelLine}</span>
                      </span>
                    </Link>
                  </div>
                  <div style={{ flex: 4, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    {foundLiveDevice && 
                      <span>
                        {foundLiveDevice.deviceName}
                      </span>
                    }
                    <span>
                      SN: {device.enclosureSerialNumber}
                    </span>
                  </div>
                  <div style={{ flex: 2, display: 'flex', justifyContent: 'flex-start' }}>
                    {this.props.resources.devicesLoading ? <Loading black /> : (foundLiveDevice && foundLiveDevice.connectionState === "Connected" ? (
                      <span style={{ color: "forestgreen" }}>Online</span>
                    ) : (
                      <span style={{ color: "maroon" }}>Offline</span>
                    ))}
                  </div>
                  <div style={{ flex: 7 }}>
                    <ul style={{ marginBottom: "0px" }}>
                      {(foundUser.has_admin_privileges || foundUser.is_enterprise_admin) ?
                        <li>Admin Privileges</li> :
                        foundUser.pdu_access_list.length === 0 ? 
                          <li>Full Access</li> : ""
                      }
                      {groupAssignments.map(g => (
                        
                        <li key={g.id} style={{ margin: "0px" }}>
                        <Link
                          to={`/devices/groups/${g.id}`}
                          
                        >
                          <span style={{
                            textDecoration: "underline",
                            paddingLeft: "3px"
                          }}>
                            {g.name}
                          </span>
                          <span style={{
                              color: "#555"
                            }}>{g.role_id ? `- ${this.props.resources.roles.find(r => r.id == g.role_id).name}` : ""}</span>
                        </Link>
                        </li>
                      ))}
                      {deviceAssignments && (
                        <li style={{ margin: "0px" }}>
                          {deviceAssignments}
                        </li>
                      )}

                    </ul>
                  </div>
                  {/* <div style={{ flex: 3 }}>
                    <ul style={{ marginBottom: "0px" }}>
                      {allRolesWithoutDuplicates.map((role) => (
                        <li key={role.id} style={{ margin: "0px" }}>
                          <Link
                            to={`/users/roles/${role.id}`}
                          >
                            <span style={{
                              textDecoration: "underline",
                              paddingLeft: "3px"
                            }}>
                              {role.name}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div> */}


                </div>
              )
            })}
          </div>

        </div>
      </div>
    )
  }
}