import React from 'react';
import Loading from '../loading';

export default class Modal extends React.Component {
  state = {
    loading: false
  }
  componentDidMount() {
    this.setState({ loading: false })
  }

  onClose = () => {
    const {
      onClick,
      changeModalContainerState
    } = this.props;

    changeModalContainerState({ active: false });
    if (onClick && typeof(onClick) === "function") {
      onClick(false);
    }
  }

  onSubmit = () => {
    if (this.state.loading) return null;
    const {
      onClick,
      changeModalContainerState
    } = this.props;

    if (onClick && typeof(onClick) === "function") {
      onClick(true, () => {
        changeModalContainerState({ active: false })
        this.setState({ loading: false})
      });
      this.setState({ loading: true })
    }
  }

  render() {
    const {
      title,
      message,
      buttonName,
      active,
      BodyComponent,
      noSubmit,
      noCancel,
      // onClick,
    } = this.props;
    const { loading } = this.state;


    if (!active) return null;

    return (
      <div
        id="outside-modal"
        onClick={(e) => {
          if (e.target.id === "outside-modal") {
            this.onClose();
          }
          // console.log("outside click", e.target.id)
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // border: 'solid red 2px',
            zIndex: '3'
          }}
        >
          <div
            className="confirmation-modal"
            id="inside-modal"
            style={{
              position: "relative",
              zIndex: '4',
              // border: 'solid green 2px'
            }}
          >
            <div className="modal app-item">
              <div className="header-section">
                <div className="header">{title}</div>
                <span
                  className="close" 
                  onClick={() => {
                    this.onClose();
                  }}
                  style={{
                    color: "white"
                  }}
                >
                  &times;
                </span>
              </div>
              <div className="content">
                <div>{message}</div>
                {BodyComponent && <BodyComponent close={() => this.onSubmit()} />}
              </div>
              {!(noSubmit && noCancel) &&
                <div className="actions">
                  {!noCancel &&
                    <div
                      className="button"
                      style={{
                        backgroundColor: "#d1d1d1",
                        color: "#333"
                      }}
                      onClick={() => this.onClose()}
                    >
                      Cancel
                    </div>
                  }
                  {!noSubmit &&
                    <div
                      className="button"
                      style={{
                        marginLeft: '10px',
                        // fontWeight: "600"
                      }}
                      onClick={() => this.onSubmit()}
                    >
                      {loading ? (
                        <Loading />
                      ) : (
                        (buttonName || "Confirm")
                      )}
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}