import { EventEmitter } from 'events';

class ModalManager extends EventEmitter {
  constructor() {
    super();
    this.modalInfo = {
      title: "",
      message: "",
      buttonName: "",
      onClick: () => {},
      BodyComponent: () => null,
      noSubmit: false,
      noCancel: false,
    };
  }

  addChangeListener(callback) {
    this.addListener("change", callback);
  }

  removeChangeListener(callback) {
    this.removeListener("change", callback);
  }

  emitChange() {
    this.emit("change", this.modalInfo);
  }

  confirm(
    message, 
    title, 
    buttonName, 
    onClick,
    BodyComponent = null,
    noCancel,
    noSubmit,
  ) {
    this.modalInfo.title = title;
    this.modalInfo.message = message;
    this.modalInfo.buttonName = buttonName;
    this.modalInfo.BodyComponent = BodyComponent;
    this.modalInfo.onClick = onClick;
    this.modalInfo.noCancel = noCancel;
    this.modalInfo.noSubmit = noSubmit;
    this.emitChange();
  }
}

export default new ModalManager();