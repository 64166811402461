import React from 'react';
import Loading from '../../loading';

const Check = (
  <svg
    xmlns="http://www.w3.org/2000/svg" 
    enableBackground="new 0 0 24 24" 
    height="24px" 
    viewBox="0 0 24 24" 
    width="24px" 
    style={{
      fill: "#228B22"
    }}
  >
    <rect
      fill="none" 
      height="24" 
      width="24"
    />
    <path d="M22,5.18L10.59,16.6l-4.24-4.24l1.41-1.41l2.83,2.83l10-10L22,5.18z M19.79,10.22C19.92,10.79,20,11.39,20,12 c0,4.42-3.58,8-8,8s-8-3.58-8-8c0-4.42,3.58-8,8-8c1.58,0,3.04,0.46,4.28,1.25l1.44-1.44C16.1,2.67,14.13,2,12,2C6.48,2,2,6.48,2,12 c0,5.52,4.48,10,10,10s10-4.48,10-10c0-1.19-0.22-2.33-0.6-3.39L19.79,10.22z"/>
  </svg>
);

const Fail = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px" 
    viewBox="0 0 24 24" 
    width="24px" 
    fill="#800000"
  >
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
  </svg>
)

const UploadInput = ({
  label,
  buttonName,
  buttonCallback = () => {},
  inputContainerStyle = {},
  buttonStyle = {},
  labelStyle = {},
  title,
  disabled,
  buttonLoadResult = null,
  buttonLoadingStatus = false,
}) => (
  <div
    style={{
      display: 'flex',
      ...inputContainerStyle
    }}
  >
    {label &&
      <div
        style={{
          flex: "1"
        }}
      >
        {label}
      </div>
    }
    <div
      style={{
        ...buttonStyle,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <label
        htmlFor={buttonName || "file-upload"}
        style={{
          
          backgroundColor: disabled ? "#d0d0d0" : "#9fd6ae",
          // color: disabled ? "#888" : "#333",
          cursor: disabled ? "default" : "pointer",
          display: "block",
          color: disabled ? "#9e9e9e" : "#333",
          ...labelStyle
        }}
        title={title}
        className="button"
      >
        {buttonName}
      </label>
      <input
        id={buttonName || "file-upload"}
        type="file"
        onChange={event => {
          event.preventDefault();
          buttonCallback(event);
        }}
        onSubmit={() => {
          // console.log("ASDASDASD")
        }}
        style={{
          display: 'none'
        }}
      />
      {buttonLoadingStatus &&
        <Loading  black />
      }
      {buttonLoadResult &&
        Check
      }
      {buttonLoadResult === false &&
        Fail
      }
    </div>
  </div>
);

export default UploadInput;
