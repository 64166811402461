import React from "react";
import { Link } from "react-router-dom";

export default class UserRoles extends React.Component {
  componentDidMount() {
    if (
      !this.props.resources.rolesLoaded &&
      !this.props.resources.rolesLoading
    ) {
      this.props.fetchResource("roles");
    }
  }
  render() {
    const roles = this.props.resources.roles;
    const columnWidths = [
      2, // Name
      4, // Description
      1, // action
    ];

    return (
      <div className="grid-x grid-padding-x">
        <div className="cell">
          {roles.length > 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: 'center',
                  marginTop: "10px",
                  marginBottom: "20px"
                }}
              >
                <div style={{}}>
                  <h4>User Roles</h4>
                </div>
                <Link to="/users/roles/new">
                  <div className="button" style={{ marginBottom: "0px"  }}>Create New Role</div>
                </Link>
              </div>

              <div>
                <div>
                    <div style={{ marginBottom: "40px" }}>
              Roles are used to manage user permissions and restrict access privileges. They can be assigned either through groups or individually:
              <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
                <li>
                <b>Group-based Roles</b>: When roles are assigned through groups, they apply to all users within that group and affect the devices the group has access to.

                </li>
                <li>
                <b>Individual Roles</b>: These are assigned to a single user and apply to all devices the user can access.
                </li>
              </ul>
                Role assignments are additive, meaning if a user has multiple roles for a specific PDU, they receive the combined permissions from all their roles. Individual roles will extend to all devices within the user's access range.
              </div>
                </div>
              </div>

              <div className={`grid-row header`}>
                <div style={{ flex: columnWidths[0] }}>Name</div>
                <div style={{ flex: columnWidths[1] }}>Description</div>
                <div style={{ flex: columnWidths[2] }}></div>
              </div>

              {roles.map((role) => {
                return (
                  <div className={`grid-row`} key={role.id}>
                    <div style={{ flex: columnWidths[0] }}>
                      <Link to={`/users/roles/${role.id}`}>
                        <span style={{ textDecoration: "underline" }}>
                          {role.name}
                        </span>
                      </Link>
                    </div>
                    <div style={{ flex: columnWidths[1] }}>
                      {role.description || ""}
                    </div>
                    <div
                      style={{
                        flex: columnWidths[2],
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Link to={`/users/roles/${role.id}`}>
                        <span
                          className="button"
                          style={{ margin: "0px", marginRight: "5px" }}
                        >
                          Edit
                        </span>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div
              style={{
                textAlign: "center",
                padding: "50px",
                marginTop: "30px",
              }}
            >
              <h4 style={{ color: "#333", marginBottom: "20px" }}>
                No User Roles Available
              </h4>
              <p style={{ color: "#6c757d", marginBottom: "30px" }}>
                It looks like there are no user roles created yet. Get started
                with managing user role privileges here.
              </p>
              <Link to="/users/roles/new">
                <div
                  className="button"
                  style={{
                    padding: "10px 20px",
                  }}
                >
                  + Create New Role
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}
