import React from 'react';

// const Loading = (
//   <div className="loading">Loading&#8230;</div>
// );

const Loading = ({
  size,
  black,
  fixed,
}) => (
  <div id={fixed ? "wrapper-fixed" : "wrapper"}>
    <div className="profile-main-loader">
      <div
        className="loader"
        style={{
          width: size || "20px",
          height: size || "20px",
        }}
      >
        <svg className="circular-loader" viewBox="25 25 50 50" >
          <circle
            className={black ? "loader-path-black" : "loader-path"}
            cx="50" cy="50" r="20" 
            fill="none" 
            strokeWidth="2"
          />
        </svg>
      </div>
    </div>
  </div>
)

export default Loading