import React from "react";
import axios from "axios";
import { format, formatDistanceStrict } from "date-fns";
import DateRangeInput from "../../../components/common/inputs/dateRangeInput";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  registerables,
} from "chart.js";
// import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import ModalManager from "../../../components/common/modal/ModalManager";
import TextInput from "../../../components/common/inputs/textInput";
import SelectInput from "../../../components/common/inputs/selectInput";
import Loading from "../../../components/common/loading";
// import LineChart from '../../../components/common/charts/lineChart';

import RowList from "../../../components/common/rowList";

// import chartColors from "../../../assets/chartColors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ...registerables
);

export default class DevicePageOutletPage extends React.Component {
  state = {
    outletLogs: [],
    outletLogsLoading: false,
    outletLogsLoaded: false,
    chartStartDate: (() => {
      const todaysUnixStartTime = new Date();
      todaysUnixStartTime.setHours(0, 0, 0, 0);
      return todaysUnixStartTime.getTime();
    })(), // default to last day
    chartEndDate: Date.now(),
    chartTimeFrameText: "Today",
    // chartTimeOffset: 0,
    // calendarView: false,
  };
  async componentDidMount() {
    // console.log("initial grab data set")
    // this.grabDataSet();
  }
  componentDidUpdate(prevProps, prevState) {
    // if (
    //   prevProps.match.params.section_id !== this.props.match.params.section_id
    // ) {
    //   // console.log("grabbing data set on update")
    //   this.grabDataSet();
    // }
  }

  editOutletInfo = (outlet, field, fieldName, options) => {
    ModalManager.confirm(
      "",
      `Outlet ${outlet.id}`,
      "",
      (submitted, close) => {
        if (submitted) {
          close();
        }
      },
      ({ close }) => (
        <EditOutletForm
          modifyResource={this.props.modifyResource}
          outlet={outlet}
          close={close}
          field={field}
          fieldName={fieldName}
          options={options}
        />
      ),
      true,
      true
    );
  };
  // grabCsvData = async () => {
  //   const { enclosureSerialNumber } = this.props.match.params;

  //   const timeDiff = this.state.chartEndDate - this.state.chartStartDate;
  //   const granularityInMinutes = Math.round(timeDiff / 1000 / 1000 / 60) || 1;

  //   // const startTimeWithOffset = new Date(
  //   //   this.state.chartStartDate + this.state.chartTimeOffset * timeDiff
  //   // ).getTime();
  //   // const endTimeWithOffset = new Date(
  //   //   this.state.chartEndDate + this.state.chartTimeOffset * timeDiff
  //   // ).getTime();

  
  //   await axios({
  //       url: `/v1/logs/outlets?enclosureSerialNumber=${enclosureSerialNumber}&since=${this.state.chartStartDate}&before=${this.state.chartEndDate}${
  //         granularityInMinutes > 0 ? `&granularity=${granularityInMinutes}&outputFormat=csv` : ""
  //       }`, //your url
  //       method: 'GET',
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //       responseType: 'blob', // important
  //   }).then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', 'outlet_logs.csv'); //or any other extension
  //       document.body.appendChild(link);
  //       link.click();
  //   });
  // }
  // grabDataSet = async () => {
  //   const { enclosureSerialNumber } = this.props.match.params;

  //   const timeDiff = this.state.chartEndDate - this.state.chartStartDate;
  //   const granularityInMinutes = Math.round(timeDiff / 1000 / 1000 / 60) || 1;
  //   this.setState({ outletLogsLoading: true })
  //   const logs = await axios.get(
  //     `/v1/logs/outlets?enclosureSerialNumber=${enclosureSerialNumber}&since=${this.state.chartStartDate}&before=${this.state.chartEndDate}${
  //       granularityInMinutes > 0 ? `&granularity=${granularityInMinutes}` : ""
  //     }&outlet_id=${this.props.match.params.section_id}`,
  //     {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     }
  //   );
  //   this.setState({ outletLogsLoading: false })
  //   if (logs.status === 200) {
  //     const arrayOfInletLogs = logs.data.data
  //       // .map((item) => ({
  //       //   ...item,
  //       //   timestamp: format(new Date(item.createdAt), "hh:mm aaa - M/d"),
  //       // }))
  //       .reduce((sum, item) => {
  //         if (
  //           sum.find((outletLogs) => outletLogs.outlet_id === item.outlet_id)
  //         ) {
  //           return sum.map((s) =>
  //             s.outlet_id === item.outlet_id
  //               ? {
  //                   ...s,
  //                   items: [item, ...s.items],
  //                 }
  //               : {
  //                   ...s,
  //                   items: s.items,
  //                 }
  //           );
  //         } else {
  //           return [
  //             ...sum,
  //             {
  //               outlet_id: item.outlet_id,
  //               items: [item],
  //             },
  //           ];
  //         }
  //       }, [])
  //       .sort((a, b) => a.outlet_id.localeCompare(b.outlet_id));
  //     this.setState({
  //       outletLogs: arrayOfInletLogs.map((outletObj) => {
  //         return {
  //           ...outletObj,
  //           lineFrequencyMax: Math.max(
  //             ...outletObj.items.map((item) => item.lineFrequency)
  //           ),
  //           lineFrequencyMin: Math.min(
  //             ...outletObj.items.map((item) => item.lineFrequency)
  //           ),
  //         };
  //       }),
  //     });
  //   }
  // };

  render() {
    const { foundOutlet, foundDevice } = this.props;
    if (!foundOutlet) return null;

    return (
      <div className="grid-container">
        <div className="grid-x grid-padding-x grid-padding-y">
          <div className="cell ">
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <select
                // value={this.props.match.params.section_id}
                style={{
                  margin: "0px",
                }}
                onChange={(e) => {
                  this.props.history.push(
                    `/devices/connected/${foundDevice.enclosureSerialNumber}/outlets/${e.target.value}`
                  );
                }}
              >
                <option value="">Select An Outlet</option>
                {(this.props.resources.outlets || []).map((outlet) => (
                  <option key={outlet.id} value={outlet.id}>
                    Outlet {outlet.pduLabeledOutletNum}{" "}
                    {outlet.outletName ===
                    `Outlet ${outlet.pduLabeledOutletNum}`
                      ? ""
                      : `- ${outlet.outletName}`}{" "}
                    ({(outlet.currentRms || 0).toFixed(2)} A)
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="cell large-8">
            <h4  style={{ margin: "0px" }}>
              Outlet {foundOutlet.pduLabeledOutletNum}{" "}
              {foundOutlet.outletName ===
              `Outlet ${foundOutlet.pduLabeledOutletNum}`
                ? ""
                : `- ${foundOutlet.outletName}`}
            </h4>
          </div>
          <div className="cell large-6">
            <RowList
              rows={[
                {
                  label: "Outlet Information",
                  labelStyle: {
                    fontWeight: "bold",
                    fontSize: "110%",
                  },
                },
                {
                  label: "Outlet",
                  value: `Outlet ${foundOutlet.pduLabeledOutletNum}`,
                },
                {
                  label: "Enclosure SN",
                  value: foundOutlet.enclosureSerialNumber,
                },
                {
                  label: "Receptacle",
                  value: foundOutlet.receptacle,
                },
                {
                  label: "Outlet ID",
                  value: foundOutlet.id,
                },
                {
                  label: "Relay Health",
                  value:
                    foundOutlet.relayHealth !== undefined
                      ? foundOutlet.relayHealth
                      : "-",
                  valueStyle: {
                    color: foundOutlet.relayHealth === "OK" ? "green" : "red",
                    fontWeight: "600",
                  },
                },
                {
                  label: "Connection State",
                  value: foundOutlet.connectionState,
                  valueStyle: {
                    fontWeight: "500",
                  },
                },
                {
                  label: "Last Updated",
                  value: foundDevice.lastUpdated && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {formatDistanceStrict(
                          foundDevice.lastUpdated,
                          new Date()
                        )}{" "}
                        ago
                      </span>
                      {foundDevice.loading ? (
                        <span
                        style={{
                          // cursor: "pointer",
                          paddingLeft: "7px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        >
                          <Loading black />
                        </span>
                      ) : (
                        <span
                          title="Refresh PDU Information"
                          className="black"
                          style={{
                            cursor: "pointer",
                            paddingLeft: "7px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            this.props.getDeviceItemAndData(
                              foundDevice.enclosureSerialNumber
                            );
                          }}
                        >
                          <i
                            style={{ fontWeight: "600", color: "#6c6f72" }}
                            className="material-icons"
                          >
                            sync
                          </i>
                        </span>
                      )}
                    </div>
                  ),
                  valueStyle: {
                    fontStyle: "italic",
                  },
                },
              ]}
            />
          </div>
          <div className="cell large-6">
            <RowList
              rows={[
                {
                  label: "Outlet Stats",
                  labelStyle: {
                    fontWeight: "bold",
                    fontSize: "110%",
                  },
                },
                {
                  label: "Outlet State",
                  value: foundOutlet.state,
                  valueStyle: {
                    color: foundOutlet.state === "ON" ? "green" : "red",
                    fontWeight: "600",
                  },
                  onEdit: () => {
                    this.editOutletInfo(foundOutlet, "state", "Outlet State", [
                      {
                        value: "ON",
                        label: "On",
                      },
                      {
                        value: "OFF",
                        label: "Off",
                      },
                      {
                        value: "REBOOT",
                        label: "Power Cycle/Reboot",
                      },
                    ]);
                  },
                },
                {
                  label: "Current RMS",
                  value:
                    foundOutlet.currentRms !== undefined
                      ? `${foundOutlet.currentRms.toFixed(2)} A`
                      : "-",
                },
                {
                  label: "Apparent Power",
                  value:
                    foundOutlet.power !== undefined
                      ? `${foundOutlet.power.toFixed(2)} VA`
                      : "-",
                },
                {
                  label: "Circuit Voltage",
                  value:
                    foundOutlet.circuitVoltage !== undefined
                      ? `${foundOutlet.circuitVoltage.toFixed(2)} V`
                      : "-",
                },
              ]}
            />
            <RowList
              rows={[
                {
                  label: "Outlet Configuration",
                  labelStyle: {
                    fontWeight: "bold",
                    fontSize: "110%",
                  },
                  rowStyle: {
                    marginTop: "10px",
                  },
                },
                {
                  label: "Outlet Name",
                  value: foundOutlet.outletName,
                  onEdit: () => {
                    this.editOutletInfo(foundOutlet, "outletName", "Name");
                  },
                },
                {
                  label: "Power Up Initial State",
                  labelTitle:
                    'Power Up Initial State: After the PDU powers up from a cold boot, the outlet relay will switch to the Power Up Initial State. "Previous" is the last known state before the shutdown occurred.',
                  value: foundOutlet.pwrOnState,
                  onEdit: () => {
                    this.editOutletInfo(
                      foundOutlet,
                      "pwrOnState",
                      "Power Up Initial State",
                      [
                        {
                          value: "ON",
                          label: "On",
                        },
                        {
                          value: "OFF",
                          label: "Off",
                        },
                        {
                          value: "PREV",
                          label: "Last Known State",
                        },
                      ]
                    );
                  },
                },
                {
                  label: "Custom Reboot Time Enabled",
                  value: foundOutlet.customRebootTimeEnabled ? "Yes" : "No",
                  onEdit: () => {
                    this.editOutletInfo(
                      foundOutlet,
                      "customRebootTimeEnabled",
                      "Custom Reboot Time Enabled",
                      [
                        {
                          value: true,
                          label: "On",
                        },
                        {
                          value: false,
                          label: "Off",
                        },
                      ]
                    );
                  },
                },
                {
                  label: "Custom Reboot Time",
                  value:
                    foundOutlet.customRebootTime !== undefined
                      ? `${foundOutlet.customRebootTime} seconds`
                      : "-",
                  onEdit: () => {
                    this.editOutletInfo(
                      foundOutlet,
                      "customRebootTime",
                      "Custom Reboot Time"
                    );
                  },
                },
              ]}
            />
          </div>
          {/* <div className="cell">
            <h3
              className="h3"
              style={{ marginBottom: "20px", marginTop: "40px" }}
            >
              Outlet Monitoring Logs
            </h3>
            <DateRangeInput
              onDateChange={(firstDate, secondDate, text) => {
                this.setState(
                  {
                    chartStartDate: firstDate,
                    chartEndDate: secondDate,
                    chartTimeFrameText: text,
                  },
                  () => {
                    this.grabDataSet();
                  }
                );
              }}
              onExport = {() => {
                this.grabCsvData();
              }}
              firstDate={this.state.chartStartDate}
              endDate={this.state.chartEndDate}
              firstDataPointDate={
                outletLogs[0] ? outletLogs[0].items[0].createdAt : null
              }
              lastDataPointDate={
                outletLogs[0]
                  ? outletLogs[0].items[outletLogs[0].items.length - 1].createdAt
                  : null
              }
            />
          </div> */}
          {/* {this.state.outletLogsLoading ? (

            <React.Fragment>
              <div className="cell large-12">
                <div className="app-item" style={{
                  height: "300px",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <Loading black />
                </div>
              </div>
              </React.Fragment>
          ) : (
            <LineChart
                item={{
                  title: "Current RMS over time",
                  wrapperClass: "large-auto",
                  scales: {
                    x: {
                      type: "time",
                      time: {
                        unit:
                          this.state.chartTimeFrameText === "last-week"
                            ? "day"
                            : undefined,
                        displayFormats: {
                          day: "dd MMM",
                        },
                      },
                    },
                    A: {
                      type: "linear",
                      position: "left",
                      title: {
                        display: true,
                        text: "Current RMS",
                      },
                      ticks: {
                        callback: function (value, index, ticks) {
                          return value + "A";
                        },
                      },
                      min: 0,
                    },
                    B: {
                      type: "linear",
                      position: "right",
                      title: {
                        display: true,
                        text: "Voltage RMS",
                      },
                      ticks: {
                        callback: function (value, index, ticks) {
                          return value.toFixed(2) + "V";
                        },
                      },
                    },
                  },
                }}
                chartTimeFrameText={this.state.chartTimeFrameText}
  
                labels={ this.state.outletLogs[0]
                ? this.state.outletLogs[0].items.map((i) => i.createdAt)
                : []}              
                datasets= {[
                  ...this.state.outletLogs.map((logSetObj, index) => {
                    return {
                      label: `${logSetObj.outlet_id} Outlet Current RMS`,
                      data: logSetObj.items.map((i) => i.currentRms),
                      borderColor: chartColors.primaryColors[index % 8],
                      backgroundColor:
                        chartColors.primaryColorHalfOpacity[index % 8],
                      // tension: 0.2,
                      yAxisID: "A",
                    };
                  }),
                  {
                    label: "Circuit Voltage",
                    data: this.state.outletLogs[0]
                      ? this.state.outletLogs[0].items.map(
                          (i) => i.circuitVoltage
                        )
                      : [],
                    borderColor:
                      chartColors.primaryColorHalfOpacity[
                        this.state.outletLogs.length % 8
                      ],
                    backgroundColor:
                      chartColors.primaryColorHalfOpacity[
                        this.state.outletLogs.length % 8
                      ],
                    tension: 0.2,
                    yAxisID: "B",
                  },
                ]}
              />

          )} */}
          {/* <div className="cell">
            <div className="app-item">
              <Line
                options={{
                  animation: {
                    duration: 0,
                  },
                  elements: {
                    point: { radius: 0 },
                  },
                  responsive: true,
                  // maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "top",
                    },
                    title: {
                      display: true,
                      text: "Current RMS over time",
                    },
                  },
                  scales: {
                    x: {
                      type: "time",
                      time: {
                        unit:
                          this.state.chartTimeFrameText === "last-week"
                            ? "day"
                            : undefined,
                        displayFormats: {
                          day: "dd MMM",
                        },
                      },
                    },
                    A: {
                      type: "linear",
                      position: "left",
                      title: {
                        display: true,
                        text: "Current RMS",
                      },
                      ticks: {
                        callback: function (value, index, ticks) {
                          return value + "A";
                        },
                      },
                      min: 0,
                    },
                    B: {
                      type: "linear",
                      position: "right",
                      title: {
                        display: true,
                        text: "Voltage RMS",
                      },
                      ticks: {
                        callback: function (value, index, ticks) {
                          return value.toFixed(2) + "V";
                        },
                      },
                    },
                  },
                }}
                data={{
                  labels: this.state.outletLogs[0]
                    ? this.state.outletLogs[0].items.map((i) => i.createdAt)
                    : [],
                  datasets: [
                    ...this.state.outletLogs.map((logSetObj, index) => {
                      return {
                        label: `${logSetObj.outlet_id} Outlet Current RMS`,
                        data: logSetObj.items.map((i) => i.currentRms),
                        borderColor: chartColors.primaryColors[index % 8],
                        backgroundColor:
                          chartColors.primaryColorHalfOpacity[index % 8],
                        // tension: 0.2,
                        yAxisID: "A",
                      };
                    }),
                    {
                      label: "Circuit Voltage",
                      data: this.state.outletLogs[0]
                        ? this.state.outletLogs[0].items.map(
                            (i) => i.circuitVoltage
                          )
                        : [],
                      borderColor:
                        chartColors.primaryColorHalfOpacity[
                          this.state.outletLogs.length % 8
                        ],
                      backgroundColor:
                        chartColors.primaryColorHalfOpacity[
                          this.state.outletLogs.length % 8
                        ],
                      tension: 0.2,
                      yAxisID: "B",
                    },
                  ],
                }}
              />
            </div>
          </div> */}
          <br />
          <br />
        </div>
      </div>
    );
  }
}

class EditOutletForm extends React.Component {
  state = {
    field: "",
    loading: false,
  };
  componentDidMount() {
    this.setState({
      field: this.props.outlet[this.props.field],
    });
  }
  render() {
    const { outlet, close } = this.props;

    return (
      <div>
        {this.props.options ? (
          <SelectInput
            label={this.props.fieldName || this.props.field}
            value={this.state.field}
            callback={(txt) => {
              this.setState({ field: txt });
            }}
            options={this.props.options}
          />
        ) : (
          <TextInput
            label={"Outlet " + this.props.fieldName || this.props.field}
            value={this.state.field}
            callback={(txt) => {
              this.setState({ field: txt });
            }}
            placeholder={
              "Enter Outlet " + this.props.fieldName || this.props.field
            }
          />
        )}
        <button
          className="button"
          onClick={() => {
            this.setState({ loading: true });
            this.props.modifyResource(
              "outlets",
              outlet.id,
              {
                [this.props.field]: this.state.field,
              },
              (err, res) => {
                this.setState({ loading: false });
                if (err) {
                  console.log(err);
                } else {
                  close();
                }
              },
              "id"
            );
          }}
        >
          {this.state.loading ? <Loading /> : "Change"}
        </button>
      </div>
    );
  }
}
