import React from 'react';
// import { subHeaderSections } from '../../../refs/refs';
// import icons from '../../../assets/icon-all';
import { NavLink } from 'react-router-dom';

export default class SubHeader extends React.Component {
  
  render() {
    const { sections } = this.props;
    // if (!sections) return null;
    // if (activePage === 'status') return null;

    return (
      <div className="active-page-subheader">
        {sections && sections.map((section) => {
          
          return (
            <NavLink
              to={section.path}
              key={section.label}
              activeClassName="active"
              className="active-page-subheader-item"
              // className={`active-page-subheader-item${active?' active':''}`}
              // onClick={() => changeAppState({ activeSection: section.label })}
            >
              {/* {icons[section.icon]} */}
              <i className="material-icons">{section.icon}</i>
              {section.label}
            </NavLink>
          );
        })}
      </div>
    )
  }
}