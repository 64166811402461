import { 
  isAfter, 
  
  startOfQuarter, 
  
  startOfMonth, 
  endOfMonth, 
  addMonths,

  addDays,
  subDays, 
  startOfDay, 
  endOfDay,
 } from "date-fns";
import React from 'react';
import { DateRangePicker } from 'rsuite';



  const predefinedRanges = [
    {
      label: 'Today',
      value: [startOfDay(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left'
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left'
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This quarter',
      value: [startOfQuarter(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    // {
    //   label: 'Last year',
    //   value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
    //   placement: 'left'
    // },
    // {
    //   label: 'All time',
    //   value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
    //   placement: 'left'
    // },
    // {
    //   label: 'Last week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
    //     ];
    //   },
    //   appearance: 'default'
    // },
    // {
    //   label: 'Next week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
    //     ];
    //   },
    //   appearance: 'default'
    // }
  ];
export default class DateRangeInput extends React.Component {
  state = {
    // chartStartDate: (() => {
    //   const todaysUnixStartTime = new Date();
    //   todaysUnixStartTime.setHours(0, 0, 0, 0);
    //   return todaysUnixStartTime.getTime();
    // })(), // default to last day
    // chartEndDate: Date.now(),
    chartTimeFrameText: "Today",
    // chartTimeOffset: 0,
    calendarView: false,
  };

  // onSelectChange = (timeFrameText) => { // returns new start and end times
  //   var start = new Date();
  //   var end = new Date();

  //   if (timeFrameText === "last-hour") {
  //     start.setHours(start.getHours() - 1);
  //   } else if (timeFrameText === "last-6-hours") {
  //     start.setHours(start.getHours() - 6);
  //   } else if (timeFrameText === "last-12-hours") {
  //     start.setHours(start.getHours() - 12);
  //   } else if (timeFrameText === "last-24-hours") {
  //     start.setHours(start.getHours() - 24);
  //   } else if (timeFrameText === "Today") {
  //     start.setHours(0, 0, 0, 0);
  //   } else if (timeFrameText === "Yesterday") {
  //     start = new Date(start.setDate(start.getDate() - 1));
  //     start.setHours(0, 0, 0, 0);
  //     end.setHours(0, 0, 0, 0);
  //   } else if (timeFrameText === "last-week") {
  //     start = new Date(start.setDate(start.getDate() - 7));
  //     start.setHours(0, 0, 0, 0);
  //   } else if (timeFrameText === "last-month") {
  //     start = new Date(start.setMonth(start.getMonth() - 1));
  //     start.setHours(0, 0, 0, 0);
  //   } else if (timeFrameText === "last-quarter") {
  //     start = startOfQuarter(start);
  //   } else if (timeFrameText === "custom") {
  //     start = new Date(this.state.chartStartDate);
  //     end = new Date(this.state.chartEndDate);
  //   }
  //   this.setState({
  //     chartTimeFrameText: timeFrameText,
  //   })
  //   return ({
  //     start,
  //     end,
  //   });
  // };
  render() {
    // const {
    //   firstDataPointDate,
    //   lastDataPointDate,
    // } = this.props;
    // const formatString = this.props.chartEndDate - this.props.chartStartDate > (24 * 60 * 60 * 1000) ? `M/d/yyyy ` : `M/d hh:mm aaa`;

    // const startingDate = firstDataPointDate
    //   ? format(
    //     new Date(firstDataPointDate),
    //     formatString
    //   )
    //   : "";
    // const endingDate = lastDataPointDate
    //   ? format(
    //     new Date(lastDataPointDate),
    //     formatString
    //   )
    //   : "";
    return (
      <div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* <select
            value={this.state.chartTimeFrameText}
            onChange={(e) => {
              const { start, end } = this.onSelectChange(e.target.value);
              this.props.onDateChange(start.getTime(), end.getTime(), e.target.value);
            }}
          >
            <option value="last-hour">Last hour</option>
            <option value="last-6-hours">Last 6 hours</option>
            <option value="last-12-hours">Last 12 hours</option>
            <option value="last-24-hours">Last 24 hours</option>
            <option value="Today">Today</option>
            <option value="Yesterday">Yesterday</option>
            <option value="last-week">This Week</option>
            <option value="last-month">This Month</option>
            <option value="last-quarter">This Quarter</option>
            <option value="custom">Custom</option>
          </select> */}
          
          <div className="field" style={{
              backgroundColor: "white",
              borderRadius: "1px",
              border: "1px solid rgb(187, 187, 187)",
              padding: "0px"
            }} >
              <DateRangePicker
                // format="yyyy-MM-dd HH:mm"
                format="MM/dd/yyyy hh:mm aaa"
                character=" - "
                // format="MM/dd hh:mm aaa"
                appearance="subtle"
                style={{ minWidth: "420px", height: "100%" }}
                placeholder="Today"
                // disabledDate={afterToday()}
                shouldDisableDate={date => {
                  // console.log(date)
                  return isAfter(date, new Date())
                  // return date < afterToday()
                }}
                ranges={predefinedRanges}
                // value={[new Date(this.props.chartStartDate || ""), new Date(this.props.chartEndDate || "")]}
                onChange={(change) => {
                  if (change[1]) {
                    this.props.onDateChange(
                      change[0].getTime(),
                      change[1].getTime(),
                      "custom"
                    );
                    this.setState({
                      chartTimeFrameText: "custom"
                    })
                  } else {
                    this.props.onDateChange(
                      change[0].getTime(),
                      null,
                      "custom"
                    );
                    this.setState({
                      chartTimeFrameText: "custom"
                    })
                  }
                }}

              // defaultCalendarValue={[new Date(firstDataPointDate), new Date(lastDataPointDate)]}
              />
            </div>
          <div
            className=""
            style={{
              backgroundColor: "white",
              color: "#333",
              padding: "8px 30px",
              // height: "2.4em",
              cursor: "pointer",
              border: "solid #bbb 1px",
              boxShadow: "1px 1px 1px rgba(0,0,0,0.1)",
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
            }}
            onClick={() => {
              // this.grabCsvData();
              this.props.onExport();
            }}
          >
            <i className="material-icons">file_download</i>
            <span>Export</span>
          </div>
          {/* <button 
                className="button"
                style={{
                  backgroundColor: "white",
                  color: "#333",
                  padding: "0px 30px",
                  height: "2.8em",
                  border: "solid #bbb 1px",
                  boxShadow: "1px 1px 1px rgba(0,0,0,0.1)"
                }}
                onClick={() => this.setState({ calendarView: !this.state.calendarView })}
              >
                <i className="material-icons">event</i>
              </button> */}

          {/* {this.state.calendarView && (
                <div>
                  <CalenderInput
                    selected={new Date(this.state.chartStartDate)}
                    startDate={new Date(this.state.chartStartDate)}
                    endDate={new Date(this.state.chartEndDate)}
                    onChange={(change) => {
                    console.log(change)
                    if (change[1]) {
                      this.setState({
                        chartStartDate: change[0].getTime(),
                        chartEndDate: null,
                      })
                    } else {
                      this.setState({
                        chartEndDate: change[0].getTime(),
                      })
                    }
                    // this.setState({
                    //   chartStartDate: change[0].getTime(),
                    // })
                    // if (change[1] !== null) {
                    //   this.setState({
                    //     chartEndDate: change[1].getTime(),
                    //   })
                    // }
                      // console.log(endDate)
                      // this.setState({
                      //   chartStartDate: startDate.getTime(),
                      //   chartEndDate: endDate.getTime(),
                      // });
                    }}
                  />
                </div>
              )} */}
        </div>

        {/* <div
          style={{
            whiteSpace: "pre",
            fontWeight: "600",
            fontSize: "120%",
            textAlign: "center",
            padding: "20px 0px",
          }}
          // className="show-for-small-only"
        >
          {startingDate && endingDate && `${startingDate} - ${endingDate}`}

        </div> */}
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontWeight: "600",
            fontSize: "120%",
            // flexWrap: "wrap"
          }}
        >
          <button
            className="button"
            style={{
              marginBottom: "0px",
            }}
            onClick={() => {
              this.props.onDateChange(
                this.props.firstDate - (this.props.endDate - this.props.firstDate),
                this.props.endDate - (this.props.endDate - this.props.firstDate),
                "custom"
              );
              this.setState({
                chartTimeFrameText: "custom"
              })
            }}
          >
            <i className="material-icons">chevron_left</i>
          </button>
          <div
            style={{
              whiteSpace: "pre",
            }}
            className="hide-for-small-only"
          >
            <div>
              {startingDate && endingDate && `${startingDate} - ${endingDate}`}

            </div>

          </div>
          <button
            className="button"
            style={{
              marginBottom: "0px",
            }}
            onClick={() => {
              this.props.onDateChange(
                this.props.firstDate + (this.props.endDate - this.props.firstDate),
                this.props.endDate + (this.props.endDate - this.props.firstDate),
                "custom"
              );
              this.setState({
                chartTimeFrameText: "custom"
              })
            }}
          >
            <i className="material-icons">chevron_right</i>
          </button>
        </div> */}
      </div>
    );
  }
}
