import React from "react";
import TextInput from "../../components/common/inputs/textInput";
import ToggleInput from '../../components/common/inputs/toggleInput';
import ModalManager from "../../components/common/modal/ModalManager";
import Loading from '../../components/common/loading';
export default class UserRolePage extends React.Component {
    state = {
        description: "",
        name: "",
        viewDeviceInfo: true,
        editDeviceInfo: false,
        viewInletInfo: false,
        editInletInfo: false,
        viewAllOutletsAllowed: false,
        editAllOutletsAllowed: false,
        switchAllOutletsAllowed: false,
        viewActivityLog: false,
        viewEventInfo: false,
        editEventInfo: false,

        hasChanges: false,
    }
    submit = () => {
        const isNewRole = this.props.match.params.roleId === "new";

        if (isNewRole) {
            this.createRole();
        } else {
            this.editRole();
        }
    }

    createRole = () => {
        const { hasChanges, ...roleObj } = this.state;
        this.props.createResource("roles", roleObj, (error, data) => {
            if (!error) {
                const roleId = data.id
                this.props.history.push(`/team/roles`);
            }
        })
    }
    editRole = () => {
        const { hasChanges, ...roleObj } = this.state;
        this.props.modifyResource(
            "roles",
            this.props.match.params.roleId,
            roleObj,
            (error, data) => {
                if (!error) {
                }
            }
        )
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.resources.rolesLoaded && !prevProps.resources.rolesLoaded) {
            const foundRole = this.props.resources.roles.find(role => role.id == this.props.match.params.roleId);
            if (foundRole) {
                this.setState({
                    ...foundRole
                });
            }
        }
    }
    componentDidMount = () => {
        if (
            !this.props.resources.rolesLoaded &&
            !this.props.resources.rolesLoading
          ) {
            this.props.fetchResource("roles");
          }
        const foundRole = this.props.resources.roles.find(role => role.id == this.props.match.params.roleId);
        if (foundRole) {
            this.setState({
                ...foundRole
            });
        }
    }
    deleteRole = () => {
        ModalManager.confirm(
            "Are you sure you want to permanently delete this role? All role assignments under this role will be void.",
            "Confirm Role Deletion",
            "Confirm",
            (status, close) => {
              if (status) {
                this.props.removeResource(
                  "roles",
                  this.props.match.params.roleId,
                  (error, data) => {
                    if (!error) {
                    close && close()
                      this.props.history.push("/users/roles");
                    }
                  }
                );
              }
            }
          )
    }
    render() {
        const isNewRole = this.props.match.params.roleId === "new";
        const loading = this.props.resources.rolesLoading;

        if (loading) {
            return (
                <Loading />
            )
        }
        return (
        <div className="grid-x grid-padding-x">
            <div className="cell">
            <div style={{ marginBottom: "15px", marginTop: "10px" }}>
            <div style={{
                borderBottom: "1px solid #bbb",
                marginBottom: "10px",
                paddingBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center"
            }}>
                <h5 style={{ marginBottom: "0px" }}>{isNewRole ? "Create New Role" : "Edit Role"}</h5>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        // marginTop: "15px"
                    }}
                >
                    <div className="button" onClick={() => this.submit()} style={{ margin: "0px" }}>
                        Save
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", marginBottom: "" }}>
                
                <div style={{ width: "100%" }}>
                    <TextInput
                        value={this.state.name}
                        callback={(value) => this.setState({ name: value })}
                        label="Role Name"
                        placeholder="Ex: Technician Level 1"
                    />
                </div>
            </div>

            <div style={{ display: "flex", marginBottom: "10px" }}>

                <div style={{ width: "100%" }}>
                    <TextInput
                        value={this.state.description}
                        callback={(value) => this.setState({ description: value })}
                        label="Description"
                        placeholder="Ex: Can view device info and switch outlets, but cannot change settings or names."
                    />
                </div>

            </div>
            </div>
            <div style={{ borderTop: "1px solid #bbb", paddingTop: "10px" }}>
                  <div style={{ marginBottom: "5px", fontWeight: "bold", marginTop: "10px" }}>
                    Permissions
                  </div>
                  {[{
                    label: "Basic Device Privileges",
                    permissions: [{
                    //     title: "View device info",
                    //     field: "viewDeviceInfo",
                    //     description: "View device information such as device name, model, connection state, and other basic device info"
                    //   }, {
                        title: "Edit device info",
                        field: "editDeviceInfo",
                        description: "Edit device information such as device name and various configurations. Unpair devices."
                      }]
                  }, {
                    label: "Inlet Power Sensors & Sensor Accessory Privileges",
                    permissions: [{
                        title: "View inlet info",
                        field: "viewInletInfo",
                        description: "View inlet information such as inlet name, state, power, and other inlet info"
                      }, {
                        title: "Edit inlet info",
                        field: "editInletInfo",
                        description: "Edit inlet information such as inlet name, inlet boot states, reboot intervals, and other inlet specific info"
                      }, {
                        title: "View sensor accessory info",
                        field: "viewSensorInfo",
                        description: "View sensor information such as temperature and humidity readings, names, etc."
                      }]
                  }, {
                    label: "Outlet Privileges",
                    permissions: [{
                        title: "View all outlets",
                        field: "viewAllOutletsAllowed",
                        description: "View all outlet information such as outlet name, state, power, and other outlet info"
                      }, {
                        title: "Edit all outlets",
                        field: "editAllOutletsAllowed",
                        description: "Edit all outlet information such as outlet name, outlet boot states, reboot intervals, and other outlet specific info"
                      }, {
                        title: "Switch all outlets",
                        field: "switchAllOutletsAllowed",
                        description: "Switch outlets ON, OFF, or power cycle outlets"
                      }]
                //   }, {
                //     label: "Attachable Sensor Privileges",
                //     permissions: [{
                //         title: "View sensor info",
                //         field: "viewSensorInfo",
                //         description: "View sensor information such as temperature and humidity readings, names, etc."
                //     //   }, {
                //     //     title: "Edit sensor info",
                //     //     field: "editSensorInfo",
                //     //     description: ""
                //       }]
                  }, {
                    label: "Event & Activity Privileges",
                    permissions: [{
                        title: "View activity",
                        field: "viewActivityLog",
                        description: "View activity logs and history for the device group"
                      }, {
                        title: "View events & actions",
                        field: "viewEventInfo",
                        description: "View events and alerts for the device group"
                      }, {
                        title: "Create, edit, and delete events & actions",
                        field: "editEventInfo",
                        description: "Manage events and alerts for the device group"
                      }]
                  }].map((section => {
                    return (
                        <div>
                            <div style={{ marginTop: "15px" }}>
                            {/* {section.label} */}

                                <div className={`grid-row header`}>
                                    <div style={{ flex: 10 }}>{section.label}</div>
                                    <div style={{ flex: 3 }}></div>
                                    <div style={{ flex: 5 }}></div>
                                </div>
                            </div>
                            {section.permissions.map((item) => {
                            return (
                                <div className={`grid-row`}>
                                <div style={{ flex: 3 }}>{item.title}</div>
                                <div 
                                    style={{ 
                                        flex: 3, 
                                        minWidth: "200px"
                                    }}
                                >
                                    <ToggleInput
                                        value={this.state[item.field]}
                                        callback={(value) => this.setState({ [item.field]: value })}
                                        id={item.field}
                                        inputContainerStyle={{
                                            margin: "0px",
                                            padding: "0px",
                                            width: "50px"

                                        }}
                                        toggleLabelOff="Disabled"
                                        toggleLabelOn="Enabled"
                                    />
                                </div>
                                <div style={{ flex: 15, color: "#444", fontStyle: "italic" }}>{item.description}</div>
                                </div>
                            );
                            })}
                        </div>
                        );
                  }))}
                  
                </div>
            {!isNewRole && (
                <div style={{ display: "flex", justifyContent: 'center', marginBottom: "10px", marginTop: "80px" }}>
                    <div className="button" style={{ backgroundColor: "maroon" }} onClick={() => this.deleteRole()}>
                        Delete Role Permanently
                    </div>
                </div>
            )}
            </div>
        </div>
        );
    }
}