import React from 'react';
import Modal from './modal';
import ModalManager from './ModalManager';

export default class ModalContainer extends React.Component {
  state = {
    title: "",
    message: "",
    buttonName: "",
    onClick: () => {},
    active: false,
    BodyComponent: () => null,
    noCancel: false,
    noSubmit: false,
  }

  componentWillMount = () => {
    ModalManager.addChangeListener(this.handleStoreChange);
  };

  componentWillUnmount = () => {
    ModalManager.removeChangeListener(this.handleStoreChange);
  };

  handleStoreChange = ({
    title,
    message,
    buttonName,
    BodyComponent,
    onClick,
    noCancel,
    noSubmit,
  }) => {
    this.setState({
      title,
      message,
      buttonName,
      onClick,
      BodyComponent,
      active: true,
      noCancel: noCancel,
      noSubmit: noSubmit,
    });
  };

  changeModalContainerState = newState => this.setState(newState)

  render() {
    const {
      title,
      message,
      buttonName,
      onClick,
      active,
      BodyComponent,
      noSubmit,
      noCancel,
    } = this.state;

    if (!active) return null;
    
    return (
      <Modal
        title={title}
        message={message}
        buttonName={buttonName}
        onClick={onClick}
        active={active}
        BodyComponent={BodyComponent}
        changeModalContainerState={this.changeModalContainerState}
        noSubmit={noSubmit}
        noCancel={noCancel}
      />
    );
  }
}