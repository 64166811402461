import React from 'react';
// import Icons from '../../../assets/icon-all';

class TextInput extends React.Component {
  state = {
    validationErr: ""
  }

  render() {
    const {
      label,
      tabIndex,
      callback,
      value,
      placeholder,
      type="text",
      disabled,
      validation,
      errMsg,
      onBlur,
      step,
      description,
      title,
      inputContainerStyle = {},
      inputLabelStyle = {},
      min = "",
      max = "",
      readOnly,
      labelMate = null,
    } = this.props;
    const { validationErr } = this.state;
    const additionalProps = {};
    if (step) additionalProps.step = step;
    if (readOnly) additionalProps.readOnly = readOnly
    return (
      <div
        className="input-container"
        title={title}
        style={inputContainerStyle}
      >
        <div
          className="input-label"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: disabled ? "#9e9e9e" : "#333",
            ...inputLabelStyle,
          }}
        >
          <div
            style={{
              display: 'flex',
              // alignItems: 'center'
              justifyContent: 'space-between',
              width: "100%"
            }}
          >
            <span>{label}</span>
            {labelMate && labelMate}
          </div>
          {(!disabled && (validationErr || errMsg)) && 
            <span
              style={{
                color: 'maroon',
                fontWeight: '400'
              }}
            >
              {validationErr || errMsg}
            </span>
          }
        </div>
        {description &&
          <div
            className="input-label"
            style={{
              color: "#666",
              fontStyle: "italic",
              margin: "5px 0px"
            }}
          >
            {description}
          </div>
        }
        <input
          disabled={disabled}
          type={type}
          min={min}
          tabindex={tabIndex}
          max={max}
          onBlur={() => {
            if (validation) {
              if (Array.isArray(validation)) {
                validation.some((validationFunction => {
                  const validationResult = validationFunction(value);
                  if (validationResult) {
                    this.setState({ validationErr: validationResult });
                    return true
                  } else {
                    return false
                  }
                }))

              } else if(typeof validation === "function") {
                const validationResult = validation(value)
                if (validationResult) {
                  this.setState({ validationErr: validationResult });
                }

              } else {
                console.error("Improper validation data type, must be array of functions of single function");
              
              }
            }
            if (onBlur) {
              onBlur(value)
            }
          }}
          onFocus={() => {
            if (this.state.validationErr) {
              this.setState({ validationErr: "" })
            }
          }}
          style={{
            border: disabled ? "solid #e6e6e6 1px" : (validationErr || errMsg) ? 'solid maroon 1px' : '',
            color: disabled ? "#9e9e9e" : "#333"
          }}
          
          onChange={e => {
            if (callback) {
              let result = e.target.value;
              if (type === "number") {
                if (step) {
                  result = e.target.value ? parseFloat(e.target.value) : "";
                } else {
                  result = e.target.value ? parseInt(e.target.value, 10) : "";

                }
              }
              callback(result)
            }
          }}
          value={value}
          placeholder={placeholder}
          {...additionalProps}
        />
      </div>
    )
  }
}

export default TextInput;