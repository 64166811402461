import React from 'react';
import DeviceSensors from '../deviceSensors';
import { Switch, Route } from "react-router-dom";


export default class DevicePageSensors extends React.Component {
    render() {
        return (
            <div>
                <DeviceSensors
                    {...this.props}
                />
            </div>
        );
    }
}

