import React from 'react'

import SelectInput from './selectInput';
import TextAreaInput from './textAreaInput';
import TextInput from './textInput';
import ToggleInput from './toggleInput';
import TextStaticInput from './textStaticInput';
import ButtonInput from './buttonInput';
import UploadInput from './uploadInput';
import DownloadInput from './downloadInput';
import SelectGroupInput from './selectGroupInput'

export default {
  select: SelectInput,
  selectGroup: SelectGroupInput,
  textarea: TextAreaInput,
  text: TextInput,
  number: TextInput,
  time: TextInput,
  toggle: ToggleInput,
  staticText: TextStaticInput,
  button: ButtonInput,
  upload: UploadInput,
  download: DownloadInput,
  break:() => <div style={{ padding: '5px', marginBottom: '25px', borderBottom: 'solid rgba(0,0,0,0.1) 1px'}} />
}