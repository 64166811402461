import React from 'react';

const DownloadInput = ({
  label,
  buttonName,
  buttonLink,
  inputContainerStyle = {},
}) => (
  <div
    style={{
      display: 'flex',
      ...inputContainerStyle
    }}
  >
    {label &&
      <div
        style={{
          flex: "1",
          paddingBottom: "10px"
        }}
      >
        {label}
      </div>
    }
    <div
      style={{
        display: 'flex'
      }}
    >
      <a
        className="button"
        style={{
          backgroundColor: "#9fd6ae",
          color: "#333"
        }}
        href={buttonLink}
        download
      >
        {buttonName}
      </a>
    </div>
  </div>
);

export default DownloadInput;
