import React from "react";
import { Link } from "react-router-dom";

import eventrefsinfo from "../../assets/refs";
import eventRefs from "../../assets/eventRef";
import InputText from "../../components/common/inputs/textInput";
import SelectGroupText from "../../components/common/inputs/selectGroupInput";
import SelectInput from "../../components/common/inputs/selectInput";
import Loading from "../../components/common/loading";
import Fields from "../../components/common/inputs/target-web-inputs/inputsIndex";
import renderTimeWithTimeZone from "../../helpers/datetime";
import ModalManager from '../../components/common/modal/ModalManager';
import axios from "axios";


import { 
  format,
  
} from 'date-fns';
const { actionRefs, eventCategories, eventCodeModules } = eventrefsinfo;
// fastify.route({
//   method: "DELETE",
//   url: "/v1/devices/:enclosureSerialNumber/events/:eventId",
//   preHandler: fastify.verifyJwt,
//   handler: eventsController.deleteEvent,
//   schema: {
//     description: 'Delete an event for a given enclosureSerialNumber',
//     headers,
//     params: {
//       type: 'object',
//       required: ['enclosureSerialNumber', 'eventId'],
//       properties: {
//         enclosureSerialNumber: { type: 'string' },
//         eventId: { type: 'number' },
//       }
//     },
//   }
// }) 
export default class DeviceAlertItemPage extends React.Component {
  state = {
    id: null,

    name: null,
    eventCode: null,
    actions: [],
    params: ["", "", ""],

    numTicksBeyond: 0,
    timeExecutedInEpoch: 0,
    triggered: null,

    newActionFormActive: false,
    newActionFormId: null,
    newActionName: "",
    newActionCode: null,
    newActionParams: ["", "", ""],

    eventFormHasChanges: false,
    actionFormHasChanges: false,

    activeActionDetailsSectionIndex: null,

    eventSaving: false,
    eventSaved: false,

    actionSaving: false,
    actionSaved: false,
  };

  removeEvent = () => {

    ModalManager.confirm (
      "Are you sure you want to delete this event trigger? It will be removed permanently from the device.",
      "Confirm Removal of Event Trigger",
      "Remove",
      (status, close) => {
        if (status) { // clicked sign in
          const { enclosureSerialNumber, eventId } = this.props.match.params;
          this.props.removeResource(
            "events", 
            eventId, 
            (err) => {
              if (err) {
                console.error("error deleting event", err);
                alert("Error occurred")
                return;
              } else {

              this.props.history.push(`/devices/${enclosureSerialNumber}/alerts`);

              close();
              }
            }, 
            "id", 
            `/v1/devices/${enclosureSerialNumber}/events/${eventId}`);

        } else { // clicked cancel or outside

        }
      },
      null,
      false
    );
  }
  saveActionButton = (callback = () => {}) => {

    if(this.state.actionSaving) {
      console.warn("cannot save action, action is already saving");
      return;
    }
    this.setState({
      actionSaving: true,
    })

    const { enclosureSerialNumber } = this.props.match.params;
    const { newActionName, newActionCode, newActionParams } = this.state;
    const newAction = {
      name: newActionName,
      code: newActionCode,
      params: newActionParams,
    };

    if (this.state.newActionFormId) {

      this.props.modifyResource(
        "actions",
        this.state.newActionFormId,
        newAction,
        (err, data) => {
          this.setState({
            actionSaving: false,
          })
          if (err) {
            callback(err,null)
            console.error("error updating action", err);
            alert("Error occurred")


            return;
          } else {
            callback(null, data.data)
            

            this.setState({
              newActionFormActive: false,
              newActionFormId: null,
              newActionName: "",
              newActionCode: null,
              newActionParams: ["", "", ""],
              actionFormHasChanges: false,
            }, () => {

              if (this.state.eventFormHasChanges) {
                this.saveEventChanges();
              }
            });
          }
        },
        "id",
        null,
        `/v1/devices/${enclosureSerialNumber}/actions/${this.state.newActionFormId}`
      );

    } else {
      this.props.createResource(
        "actions",
        newAction,
        (err, savedAction) => {
          if (err) {
            console.error("error creating action", err);
            alert("Error occurred")
            return;
          } else {
            this.setState({
              newActionFormActive: false,
              newActionFormId: null,
              newActionName: "",
              newActionCode: null,
              newActionParams: ["", "", ""],
              actionFormHasChanges: false,
              actions: [...(this.state.actions || []), savedAction.id],
            }, () => {
              if (this.state.eventFormHasChanges) {
                this.saveEventChanges();
              }
            });
          }
        },
        `/v1/devices/${enclosureSerialNumber}/actions`
      );

    }
  }

  saveEventChanges = () => {
    if (this.state.eventSaving) {
      console.warn("cannot save event changes, event is already saving");
      return;
    }
    if (this.state.actionFormHasChanges) {
      console.warn("cannot save event changes, action form has changes");
      return;
      // this.saveActionButton();
      
    }
    this.setState({
      eventSaving: true,
    })
    const { enclosureSerialNumber, eventId } = this.props.match.params;
    if (eventId === "new") {
      // alert("new event here")
      const { name, eventCode, actions, params } = this.state;
      const { actionsLoading } = this.props.resources;
      if (actionsLoading) {
        console.warn("actions still loading, cannot save event");
        this.setState({
          eventSaving: false,
        })
        return;
      }
      const actionIds = actions;
      const newEvent = {
        name,
        code: eventCode,
        actions: actionIds,
        params,
      };
      this.props.createResource(
        "events",
        newEvent,
        (err) => {
          this.setState({
            eventSaving: false,
            eventSaved: true,
          })
          if (err) {
            console.error("error creating event", err);
            alert("Error occurred")
            return;
          } else {
            this.setState({
              eventFormHasChanges: false,
            });
          }
        },
        `/v1/devices/${enclosureSerialNumber}/events`
      );

    } else {
      const foundEvent = this.props.resources.events.find(
        (e) => e.id == eventId && e.enclosureSerialNumber == enclosureSerialNumber
      );
      if (foundEvent) {
        const { name, eventCode, actions, params } = this.state;
        const { actionsLoading } = this.props.resources;
        if (actionsLoading) {
          console.warn("actions still loading, cannot save event");
          this.setState({
            eventSaving: false,
            
          })
          return;
        }
        const actionIds = actions;
        const newEvent = {
          name,
          code: eventCode,
          actions: actionIds,
          params,
        };
        this.props.modifyResource(
          "events",
          eventId,
          newEvent,
          (err) => {
            this.setState({
              eventSaving: false,
              eventSaved: true,
            })
            if (err) {
              console.error("error updating event", err);
              alert("Error occurred")
              return;
            } else {
              this.setState({
                eventFormHasChanges: false,
              });
            }
          },
          "id",
          null,
          `/v1/devices/${enclosureSerialNumber}/events/${eventId}`
        );
      } else {
        this.setState({
          eventSaving: false,
        })
        console.warn("event not found in save event changes");
      }
    }
  }

  setEventFormState = (newState) => {
    this.setState(newState);
  };

  resetEventChanges = () => {
    this.loadFoundeventInfo();
    this.setState({
      eventFormHasChanges: false,
    })
  }
  resetActionChanges = () => {
    this.setState({
      actionFormHasChanges: false,
      newActionName: "",
      newActionCode: null,
      newActionParams: ["", "", ""],
    })
  }

  loadFoundeventInfo = () => {
    const { enclosureSerialNumber, eventId } = this.props.match.params;
    const foundEvent = this.props.resources.events.find(
      (e) => e.id == eventId && e.enclosureSerialNumber == enclosureSerialNumber
    );
    if (foundEvent) {
      this.setState({
        id: foundEvent.id,
        name: foundEvent.name,
        eventCode: foundEvent.code,
        actions: foundEvent.actions,
        params: foundEvent.params,
        numTicksBeyond: foundEvent.numTicksBeyond,
        timeExecutedInEpoch: foundEvent.timeExecutedInEpoch,
        triggered: foundEvent.triggered,
      });
    } else {
      console.warn("event not found in mount");
    }
  };

  fetchEventInfoAndUpdateFetchWrapper = (foundEvent) => {
    console.log("fetching event info")
    axios.get(`/v1/devices/${foundEvent.enclosureSerialNumber}/events/${foundEvent.id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    }).then((response) => {
      this.props.updateFetchWrapperState({
        events: this.props.resources.events.map(event => {
          if ((event.id == foundEvent.id) && (event.enclosureSerialNumber == foundEvent.enclosureSerialNumber)) {
            return ({
              ...response.data.data,
              lastUpdated: new Date()
            })
          } else {
            return event;
          }
        })
      })
    }).catch((err) => {
      console.error("error fetching event info", err);
    })

  }

  grabInletData = () => {

    if (this.props.resources.inletsLoading || this.props.resources.inletsLoaded) {
      // do nothing
    } else {
      this.props.fetchResource("inlets");
    }
  }
  componentDidMount() {
    if (this.props.resources.actionsLoading || this.props.resources.actionsLoaded) {
    } else {
      this.props.fetchResource("actions");
    }
    if (this.props.resources.eventsLoading || this.props.resources.eventsLoaded) {
    } else {
      this.props.fetchResource("events");
    }

    if (this.props.resources.inletsLoading || this.props.resources.inletsLoaded) {
    } else {
      this.props.fetchResource("inlets");
    }
    if (this.props.resources.outletsLoading || this.props.resources.outletsLoaded) {
    } else {
      this.props.fetchResource("outlets");
    }
    if (this.props.resources.sensorsLoading || this.props.resources.sensorsLoaded) {
    } else {
      this.props.fetchResource("sensors");
    }




    // this.props.fetchResource("events", true);
    // this.props.fetchResource("groups");
    this.props.fetchResource("banks");
    this.loadFoundeventInfo();
    // get enclosure SN and event ID from url
    // find matching event ID with id and enclosure SN
    // if found then setState for all event fields

    // this.props.fetchResource("banks", (err, banks) => {
    //     // this.setTriggerOptions();
    // }, `?enclosureSerialNumber=${this.props.match.params.enclosureSerialNumber}`);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.resources.events.length === 0 &&
      this.props.resources.events.length > 0
    ) {
      this.loadFoundeventInfo();
    }
  }

  triggerOptions() {
    const device =
      this.props.resources.devices.find(
        (d) =>
          d.enclosureSerialNumber ==
          this.props.match.params.enclosureSerialNumber
      ) || {};
    const banks = this.props.resources.banks.filter(
      (b) =>
        b.enclosureSerialNumber == this.props.match.params.enclosureSerialNumber
    );
    // if (!device) {
    //     console.warn("missing device for device alert item page")
    //     return null;
    // }
    // if (banks.length === 0) {
    //     console.log("no banks found, pulling bank data")
    //     return null;
    // }
    var allowableEventCodes = [...eventCodeModules.codesForEveryone];
    allowableEventCodes.push(...eventCodeModules.multiBankEventCodes);

    if (device.phase == "Single Phase") {
      allowableEventCodes.push(...eventCodeModules.singlePhaseEventCodes);
    } else if (
      device.phase === "Three Phase Delta" ||
      device.phase === "Three Phase Delta+Wye" ||
      device.phase === "Three Phase Wye"
    ) {
      allowableEventCodes.push(...eventCodeModules.threePhaseEventCodes);
    }

    if (
      device.outletPwrMeasurementsSupported &&
      device.outletSwitchingSupported
    ) {
      allowableEventCodes.push(...eventCodeModules.outletEventCodes);
    }

    if (device.inletConfig == "ats") {
      allowableEventCodes.push(...eventCodeModules.atsEventCodes);
    }

    if (
      banks[0] &&
      banks[0].hasCircuitBreakerProtection &&
      device.inletEnergySupported
    ) {
      allowableEventCodes.push(...eventCodeModules.circuitBreakerCodes);
    }
    //remove non-current event codes
    if (device.inletCurrentSupported) {
      allowableEventCodes = allowableEventCodes.filter((e) => {
        return !eventCodeModules.nonInletCurrentEventCodes.includes(e);
      });
    }
    const triggerOptions = eventRefs.map((e) => ({
      label: e.name,
      value: e.code,
    }));
    return triggerOptions;
    // console.log(triggerOptions)
    // this.setState({
    //     triggerOptions
    // })
  }

  deleteAction = (actionId) => {
    ModalManager.confirm (
      "Are you sure you want to delete this action? It will be removed permanently from the device AND from any events which use this action.",
      "Confirm Removal of Action",
      "Remove",
      (status, close) => {
        if (status) { // clicked sign in
          const { enclosureSerialNumber } = this.props.match.params;
          this.props.removeResource(
            "actions", 
            actionId, 
            (err) => {
              if (err) {
                console.error("error deleting action", err);
                alert("Error occurred")
                return;
              } else {
                this.setState({
                  actions: this.state.actions.filter(a => a !== actionId)
                })
                close();
              }
            }, 
            "id", 
            `/v1/devices/${enclosureSerialNumber}/actions/${actionId}`);

        } else { // clicked cancel or outside

        }
      },
      null,
      false
    );
  }

  render() {

    const newEvent = this.props.match.params.eventId === "new";
    const foundEvent = this.props.resources.events
    .filter(e => e.enclosureSerialNumber == this.props.match.params.enclosureSerialNumber)
    .find(
      (e) => e.id == this.props.match.params.eventId
    );

    const allDeviceEvents = this.props.resources.events.filter(event => event.enclosureSerialNumber == this.props.match.params.enclosureSerialNumber);

    const foundDevice = this.props.resources.devices.find(
      (d) =>
        d.enclosureSerialNumber == this.props.match.params.enclosureSerialNumber
    );

    const triggerOptions = this.triggerOptions();

    const relevantDeviceActions = this.props.resources.actions.filter(
      (a) =>
        a.enclosureSerialNumber == this.props.match.params.enclosureSerialNumber
    );

    const actionsLoading = this.props.resources.actionsLoading;
    const actionsLoaded = this.props.resources.actionsLoaded;
    const filteredActionRefs = foundDevice
      ? actionRefs.filter((a) => {
          if (
            (a.code == 22 || a.code == 23) &&
            (!foundDevice.numDryContactRelays ||
              foundDevice.numDryContactRelays == 0)
          ) {
            return false;
          } else if (a.code == 31 && !foundDevice.inletEnergySupported) {
            return false;
          } else if (
            foundDevice.outletPwrMeasurementsSupported ||
            foundDevice.outletSwitchingSupported
          ) {
            return true;
          } else {
            return !a.outletFeature;
          }
        })
      : [];


    return (
      <div className="grid-container">
        <div className="grid-x grid-padding-x grid-padding-y">
          <div
            className="cell"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // marginBottom: "10px",
            
            }}
          >
            <h3>
              Event Trigger Configuration
            </h3>
            {this.state.eventFormHasChanges && (
              <div>
                <div
                  className="button"
                  style={{
                    // backgroundColor: "#223647",
                    opacity: this.state.actionFormHasChanges ? "0.5" : "1",
                    backgroundColor: "rgb(112, 179, 125)",                    
                    color: "#white",
                    marginBottom: "0px",
                    marginRight: "10px",
                  }}
                  onClick={() => this.saveEventChanges()}
                > 
                {this.state.eventSaving ? <Loading white /> : (this.state.id ? "Save" : "Create")}
                </div>
                <div 
                  className="button"
                  style={{
                    opacity: this.state.actionFormHasChanges ? "0.5" : "1",
                    backgroundColor:  "#ddd",
                    color: "#333",
                    marginBottom: "0px",
                    // disabled if action form has changes

                  }}
                  onClick={() => {
                    if (!this.state.actionFormHasChanges) {
                      this.resetEventChanges();
                    }
                  }}
                >
                  Cancel
                </div>
              </div>

            )}
          </div>
          <div className="cell large-6 medium-6">

          {this.state.id && foundEvent && (
              <div 
                style={{
                  marginBottom: "10px",
                }}
              >
              <div className="config-header section" style={{ display: 'flex', alignItems: 'center' }}>
                <h4 className="config-header-title section">Event Status</h4>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',

                  }}
                  onClick={() => {
                    if (!this.props.resources.eventsItemLoading) {
                      // this.props.fetchResourceItem("events", foundEvent.id, () => {}, `/v1/devices/${foundEvent.enclosureSerialNumber}/events/${foundEvent.id}`)
                      this.fetchEventInfoAndUpdateFetchWrapper(foundEvent);  

                    }
                  }}
                >
                  {this.props.resources.eventsItemLoading ? (
                    <Loading />
                  ) : (
                    <i className="material-icons">refresh</i>
                  )}
                </span>
              </div>
                <div
                  className="app-item"
                  style={{
                    display: 'flex',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: "600", paddingBottom: "8px" }}>Status: </div>
                    <div style={{ fontWeight: "600", paddingBottom: "8px" }}>Last Updated: </div>
                    {foundEvent.triggered && foundEvent.timeExecutedInEpoch &&
                      <div style={{ fontWeight: "600", paddingBottom: "8px" }}>Trigger Time: </div>
                    }
                    {((foundEvent.code === 11) || (foundEvent.code === 12)) && (
                      <div style={{ fontWeight: "600", paddingBottom: "8px" }}>Number of consecutive {foundEvent.code === 11 ? "successful" : "failed"} Pings: </div>
                    )}
                    {/* <div style={{ fontWeight: "600", paddingBottom: "8px" }}>Ticks Beyond: </div> */}
                  </div>
                  <div style={{ flex: 2 }}>
                    <div
                      style={{
                        color: foundEvent.triggered ? "maroon" : "forestgreen",
                        fontWeight: "600",
                        paddingBottom: "8px",
                        paddingLeft: '5px'
                      }}
                    >
                      {foundEvent.triggered ? "Triggered" : "Not Triggered"}
                    </div>
                    <div style={{ paddingBottom: "8px", paddingLeft: "5px", display: 'flex', alignItems: 'center'}}>
                      <span>{format(foundEvent.lastUpdated, "MMM dd, yyyy HH:mm")}</span>
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (!this.props.resources.eventsItemLoading) {
                            // this.props.fetchResourceItem("events", foundEvent.id, () => {}, `/v1/devices/${foundEvent.enclosureSerialNumber}/events/${foundEvent.id}`)
                              this.fetchEventInfoAndUpdateFetchWrapper(foundEvent);  
                          }}
                        }
                      >
                        <i className="material-icons" style={{ fontSize: "16px", marginLeft: "5px" }}>refresh</i>
                      </span>
                    </div>
                    {foundEvent.triggered && foundEvent.timeExecutedInEpoch &&
                      <div
                        style={{
                          paddingBottom: "8px",
                          paddingLeft: "5px"
                        }}
                      >
                        {format(foundEvent.timeExecutedInEpoch*1000, "MMM dd, yyyy HH:mm")}
                      </div>
                    }
                    {((foundEvent.code === 11) || (foundEvent.code === 12)) && (
                      <div style={{ paddingBottom: "8px", paddingLeft: "5px" }}>
                        {foundEvent.numTicksBeyond}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {!newEvent && !foundEvent ? (
              <React.Fragment>
                <div className="config-header section">
                  <h4 className="config-header-title section">
                    Event Settings
                  </h4>
                </div>
                <div className="app-item">
                  <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    <div className="skeleton-blank" style={{ width: "45%" }} />
                    <div className="skeleton-blank" style={{ width: "75%" }} />
                    <div className="skeleton-blank" style={{ width: "45%" }} />
                    <div className="skeleton-blank" style={{ width: "70%" }} />
                  </div>
                </div>
                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  <div className="config-header section">
                    <h4 className="config-header-title section" style={{ color: "#49535D"}}>--</h4>
                  </div>
                  <div className="app-item">
                    <div className="skeleton-blank" style={{ width: "45%" }} />
                    <div className="skeleton-blank" style={{ width: "75%" }} />
                    <div className="skeleton-blank" style={{ width: "45%" }} />
                    <div className="skeleton-blank" style={{ width: "70%" }} />
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div>
                <div className="config-header section">
                  <h4 className="config-header-title section">
                    Event Settings
                  </h4>
                </div>
                <div className="app-item" style={{ marginBottom: "10px" }}>
                  <InputText
                    label="Event Name"
                    placeholder="eg. 16A Max Current Threshold Inlet"
                    value={
                      this.state.name === null
                        ? foundEvent?.name
                        : this.state.name
                    }
                    validation={value => {
                      if (value.length === 0) {
                        return "name is required";
                      }
                      if (value.length > 100) {
                        return "Name is too long";
                      }
                      if (allDeviceEvents.find(a => a.name === value)) {
                        return "Name already exists";
                      }
                      return null;
                    }}
                    callback={(e) => this.setState({ name: e, eventFormHasChanges: true })}
                  />
                  {foundDevice ? (
                    <SelectGroupText
                      label="Event Type"
                      value={
                        this.state.eventCode === null
                          ? foundEvent?.code
                          : this.state.eventCode
                      }
                      callback={(result) => {
                        this.setState({
                          eventCode: result,
                          params: ["", "", ""],
                          eventFormHasChanges: true,
                        });
                      }}
                      validation={value => {
                        if (!value) {
                          return "Event type is required";
                        }
                        return null;
                      
                      }}
                      options={eventCategories
                        .map((category) => {
                          return {
                            groupName: category.label,
                            options: triggerOptions.filter((option) => {
                              return category.codes.includes(option.value);
                            }),
                          };
                        })
                        .filter((category) => category.options.length > 0)}
                    />
                  ) : (
                    "Loading"
                  )}
                </div>
                <TriggerParamSection
                  {...this.props}
                  setEventFormState={this.setEventFormState}
                  eventForm={this.state}
                />
              </div>
            )}
          </div>
          <div className="cell large-6 medium-6">

            



            {!this.state.newActionFormActive &&
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <div className="config-header section">
                <h4 className="config-header-title section">Add Action</h4>
              </div>
              <div
                className="app-item"
                style={{
                  padding: "18px",
                  borderRadius: "0px 0px 3px 3px",
                }}
              >
                <SelectInput
                  label="Add Existing Action for Event"
                  callback={(result) =>
                    this.setState({
                      actions: [...(this.state.actions || []), parseInt(result, 10)],
                      eventFormHasChanges: true,
                    })
                  }
                  value=""
                  options={
                    relevantDeviceActions
                    .filter(a => !this.state.actions.includes(a.id))
                    .map(a => ({
                      label: a.name,
                      value: a.id
                    })
                  )}
                  // options={actions
                  //   .map((a) => ({
                  //     label: a.name,
                  //     value: a.id,
                  //   }))
                  //   .filter(
                  //     (a) =>
                  //       !(actions || []).find(
                  //         (actionid) => a.value === actionid
                  //       )
                  //   )}
                />
                <div
                  style={{
                    paddingBottom: "10px",
                  }}
                >
                  or
                </div>
                <div>
                  <div
                    onClick={() => {
                      this.setState({
                        newActionFormActive: true,
                      });
                    }}
                    style={{
                      color: "#333",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Create New Action
                  </div>
                </div>
              </div>
            </div>
            }
            {this.state.newActionFormActive && (
              <div
                style={{
                  marginBottom: "10px",
                }}
              >
                <div className="config-header section">
                  <h4 className="config-header-title section">
                    {this.state.newActionFormId ? "Edit Action" : "Create New Action"}
                  </h4>
                  <div>
                    {this.state.actionFormHasChanges && (
                      <div
                        className="button"
                        style={{
                          marginRight: "10px",
                          // backgroundColor: "#223647",
                          backgroundColor: "rgb(112, 179, 125)",
                          color: "#fff",
                          marginBottom: "0px"
                        }}
                        onClick={() => {
                          this.saveActionButton();
                        }}
                      >
                       {this.state.actionSaving ? <Loading white /> : (this.state.newActionFormId ? "Save" :  "Create")}
                      </div>

                    )}
                    <div
                      className="button" 
                      style={{  
                        marginBottom: "0px",
                        backgroundColor: "#ddd",
                        color: "#333"
                      }}
                      onClick={() => {
                        this.setState({
                          newActionFormActive: false,
                          newActionFormId: null,
                          newActionName: "",
                          newActionCode: null,
                          newActionParams: ["", "", ""],
                          actionFormHasChanges: false,
                          activeActionDetailsSectionIndex: null
                        });
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
                <div className="app-item">
                  <InputText
                    label="Action Name"
                    placeholder="eg. Switch Outlet"
                    value={this.state.newActionName}
                    callback={(result) =>
                      this.setState({ newActionName: result, actionFormHasChanges: true})
                    }
                  />
                  <SelectInput
                    label="Action Type"
                    value={this.state.newActionCode}
                    callback={(result) => {
                      this.setState({
                        newActionCode: parseInt(result, 10),
                        newActionParams: ["", "", ""],
                        actionFormHasChanges: true
                      });
                    }}
                    options={filteredActionRefs.map((a) => ({
                      label: a.name,
                      value: a.code,
                    }))}
                  />
                  <div
                    style={{
                      borderBottom: "solid rgba(0,0,0,0.1) 1px",
                      marginBottom: "10px",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                  />
                  <ActionParamSection
                    {...this.props}
                    actionForm={{
                      name: this.state.newActionName,
                      code: this.state.newActionCode,
                      params: this.state.newActionParams,
                    }}
                    setEventFormState={this.setEventFormState}
                  />
                </div>
              </div>
            )}

          <div className="config-header section">
            <h4 className="config-header-title section">
              List of Actions for Event
            </h4>
          </div>
            {relevantDeviceActions.length === 0 && actionsLoading && (
              <div className="app-item">
                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  {/* <Loading black />  */}
                  <div className="skeleton-blank" style={{ width: "45%" }} />
                  <div className="skeleton-blank" style={{ width: "70%" }} />
                  <div className="skeleton-blank" style={{ width: "55%" }} />
                </div>
              </div>
            )}
            {((this.state.actions.length === 0) && actionsLoaded) && (
              <div className="app-item">
                <div
                  style={{
                    padding: "10px",
                    borderRadius: "0px 0px 3px 3px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                      // marginBottom: "10px",
                    }}
                  >
                      No Actions Included
                  </div>
                </div>
              </div>
            
            )}
            {this.state.actions.map((actionId, actionIndex) => {
              const foundAction = relevantDeviceActions.find( a => a.id == actionId);
              if (!foundAction) {
                return null;
              }
              const actionRef = actionRefs.find((a) => a.code == foundAction.code);
              return (
                <div
                  key={actionIndex}
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  {/* <div className="config-header section">
                    <h4 className="config-header-title section">
                      {actionRef.name}
                    </h4>
                  </div> */}
                  <div
                    className="app-item"
                    style={{
                      padding: "18px",
                      borderRadius: "0px 0px 3px 3px",
                      cursor: 'pointer',
                    }}

                    onClick={() => {
                      this.setState({
                        activeActionDetailsSectionIndex: this.state.activeActionDetailsSectionIndex === actionIndex ? null : actionIndex
                      })
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div>
                        <div>
                          {actionRef.name} Action
                        </div>
                        <div
                          style={{
                            fontWeight: '600'
                          }}
                        >
                          {foundAction.name}
                        </div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center'}}>
                        {/* dropdown */}
                        <span
                          style={{
                          }}
                        >
                          <i className="material-icons">
                            {this.state.activeActionDetailsSectionIndex === actionIndex ? 'expand_less' : 'expand_more'}
                          </i>
                        </span>
                      </div>
                    </div>
                    {(this.state.activeActionDetailsSectionIndex === actionIndex) && (
                        <div
                          style={{
                            paddingLeft: "10px"
                          }}
                        >
                          <div
                            style={{
                              borderBottom: "solid rgba(0,0,0,0.08) 1px",
                              // marginBottom: "10px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              marginTop: "10px"
                            }}
                          />
                          <div
                            style={{
                              paddingBottom: "5px",
                            }}
                          >
                            {actionRef.params.map((param, i) => {
                              return (
                                <div
                                  key={param}
                                  style={{
                                    padding: "10px 0px 0px 0px",
                                  }}
                                >
                                  <div
                                    style={{
                                      paddingBottom: "5px",
                                      fontSize: "90%",
                                    }}
                                  >
                                    {param}:
                                  </div>
                                  <div
                                    style={{
                                      fontWeight: "600",
                                      // paddingLeft: '6px'
                                    }}
                                  >
                                    {foundAction.params[i]}
                                  </div>
                                </div>
                              );
                            })}
                          </div> 
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "10px"
                            }}
                          >
                            <div style={{ display: 'flex' }}>
                              <div
                                className="button"
                                style={{
                                  backgroundColor: "rgb(112, 179, 125)",
                                  color: "#fff",
                                  marginRight: "5px",
                                  marginBottom: "0px"
                                }}
                                onClick={() => {
                                  this.setState({
                                    newActionFormActive: true,
                                    newActionFormId: foundAction.id,
                                    newActionName: foundAction.name,
                                    newActionCode: foundAction.code,
                                    newActionParams: foundAction.params,
                                  });
                                }}
                              >
                                Save
                              </div>
                              <div
                                className="button" 
                                style={{
                                  backgroundColor: "rgb(155 155 155)",
                                  color: '#fff', 
                                  marginBottom: "0px"
                                }}
                                onClick={() => {
                                  const newActions = [...this.state.actions];
                                  newActions.splice(actionIndex, 1);
                                  this.setState({
                                    actions: newActions,
                                    eventFormHasChanges: true,
                                    activeActionDetailsSectionIndex: null,
                                    newActionFormActive: false,
                                  });
                                }}
                              >
                                Remove
                              </div>
                            </div>
                            <div>
                              <div
                                className="button"
                                style={{
                                  backgroundColor: "maroon",
                                  color: "#fff",
                                  marginBottom: "0px",
                                  marginLeft: "5px"
                                }}
                                onClick={() => {
                                  this.deleteAction(foundAction.id)
                                }}
                              >
                                Delete Action
                              </div>
                            </div>
                          </div>
                        </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {!newEvent &&
            <div className="cell">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                  marginTop: "20px"
                }}
              >
                <div
                  className="button"
                  style={{
                    backgroundColor: "maroon",
                    color: "#fff",
                    marginBottom: "0px",
                  }}
                  onClick={() => {
                    this.removeEvent();
                  }}
                >
                  Delete Event Trigger Permanently
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

class TriggerParamSection extends React.Component {
  state = {
    helpActive: false,
  };
  renderParamInputs() {
    const { eventForm, setEventFormState } = this.props;

    const { enclosureSerialNumber } = this.props.match.params;

    const foundDevice = this.props.resources.devices.find(
      (d) => d.enclosureSerialNumber == enclosureSerialNumber
    );
    if (!foundDevice) {
      console.log("no device found for trigger param section");
      return [];
    } else {
      const outlets = this.props.resources.outlets.filter(
        (o) => o.enclosureSerialNumber == foundDevice.enclosureSerialNumber
      );
      const banks = this.props.resources.banks.filter(
        (b) => b.enclosureSerialNumber == foundDevice.enclosureSerialNumber
      );
      const sensors = this.props.resources.sensors.filter(
        (s) => s.enclosureSerialNumber == foundDevice.enclosureSerialNumber
      );
      const inlets = this.props.resources.inlets.filter(
        (i) => i.enclosureSerialNumber == foundDevice.enclosureSerialNumber
      );
      const eventRef = eventRefs.find((e) => e.code == eventForm.eventCode);
      if (!eventRef) {
        console.warn("No event ref found");
        return [];
      }
      return eventRef.params.map((paramObj, index) => {
        var InputField = Fields[paramObj.input];
        if (!InputField) return null;
        const additionalParams = {};
        const queryParamParams = getParameterByName("params")
          .split(" ")
          .filter((p) => p);

        if (
          (
            (eventCategories.find((c) => c.relResource == "banks") || {})
              .codes || []
          ).includes(eventRef.code) ||
          (
            (
              eventCategories.find((c) => c.relResource == "circuitbreaker") ||
              {}
            ).codes || []
          ).includes(eventRef.code)
        ) {
          additionalParams.options = [
            ...banks
              .map((bank) => ({
                label: bank.bankName,
                value: (bank.id || "").toString(),
              }))
              .filter((opt) => {
                if (queryParamParams.length > 0) {
                  return (
                    opt.value === "any" || queryParamParams.includes(opt.value)
                  );
                } else {
                  return true;
                }
              }),
          ];
        } else if (
          (
            (eventCategories.find((c) => c.relResource == "inlet") || {})
              .codes || []
          ).includes(eventRef.code)
        ) {
          if (eventForm.code == 13 || eventForm.code == 14) {
            // skip for line current
          } else {
            additionalParams.options = [
              ...inlets
                .map((inlet) => ({
                  label: inlet.inletName,
                  value: inlet.id,
                }))
                .filter((opt) => {
                  if (queryParamParams.length > 0) {
                    return queryParamParams.includes(opt.value);
                  } else {
                    return true;
                  }
                }),
            ];
          }
        } else if (
          (
            (eventCategories.find((c) => c.relResource == "outlets") || {})
              .codes || []
          ).includes(eventRef.code)
        ) {
          additionalParams.options = [
            ...outlets
              .map((outlet) => ({
                label: outlet.outletName,
                value: outlet.outlet_id,
              }))
              .filter((opt) => {
                if (queryParamParams.length > 0) {
                  return (
                    opt.value === "any" || queryParamParams.includes(opt.value)
                  );
                } else {
                  return true;
                }
              }),
          ];

          // } else if (((eventCategories.find(c => c.relResource == "groups") || {}).codes || []).includes(eventRef.code)) {
          //   additionalParams.options = [
          //     ...groups.map(group => ({
          //       label: group.groupName,
          //       value: group.id
          //     })).filter(opt => {
          //       if (queryParamParams.length > 0) {
          //         return queryParamParams.includes(opt.value)
          //       } else {
          //         return true;
          //       }
          //     })
          //   ];
        } else if (
          (
            (eventCategories.find((c) => c.relResource == "ioSensors") || {})
              .codes || []
          ).includes(eventRef.code)
        ) {
          if (eventRef.code !== 64) {
            const { foundEvent } = this.props;

            const portToCheck = eventForm.params
              ? eventForm.params[0]
              : (foundEvent.params || [])[0];

            if (portToCheck === "A") {
              const foundSensor = sensors.find((s) => s.sensorPort === "A");
              const isIoHub =
                foundSensor && foundSensor.sensorType === "dio_hub";
              if (foundSensor && isIoHub && index == 1) {
                // only change param #2
                additionalParams.options = [
                  ...foundSensor.digitalInputs.map((di) => ({
                    label: `${di.index}. ${di.digitalInputName}`,
                    value: di.index,
                  })),
                ];
              }
            } else if (portToCheck === "B") {
              const foundSensor = sensors.find((s) => s.sensorPort === "B");
              const isIoHub =
                foundSensor && foundSensor.sensorType === "dio_hub";
              if (foundSensor && isIoHub && index == 1) {
                additionalParams.options = [
                  ...foundSensor.digitalInputs.map((di) => ({
                    label: `${di.index}. ${di.digitalInputName}`,
                    value: di.index,
                  })),
                ];
              }
            }
          }
        } else if (
          (
            (eventCategories.find((c) => c.relResource == "tempSensors") || {})
              .codes || []
          ).includes(eventRef.code)
        ) {
          additionalParams.options = [
            ...sensors.map((sensor) => ({
              label:
                `${sensor.sensorName} (${sensor.sensorId})` ||
                sensor.sensorPort,
              value: sensor.sensorId || sensor.sensorPort,
            })),
          ];
        }
        const helpSectionitem = (eventRef.helpSections || []).find(
          (h) => h.title === paramObj.label
        );

        return (
          <InputField
            key={index}
            {...paramObj}
            type={
              paramObj.input === "number"
                ? "number"
                : paramObj.input === "text"
                ? "text"
                : paramObj.input === "time"
                ? "time"
                : undefined
            }
            {...additionalParams}
            label={`${paramObj.label}${
              paramObj.unit ? ` (${paramObj.unit})` : ""
            }`}
            value={(eventForm.params || [])[index]}
            helpIconAction={
              helpSectionitem
                ? () => this.setState({ helpActive: !this.state.helpActive })
                : null
            }
            callback={(result) => {
              const newParams = [...(eventForm.params || [])];
              newParams[index] = result;
              setEventFormState({
                params: newParams,
                eventFormHasChanges: true,
              });
            }}
          />
        );
      });
    }
  }

  render() {
    const eventRef = eventRefs.find(
      (e) => e.code == this.props.eventForm.eventCode
    );
    if (!eventRef) {
      console.warn("No event ref found");
      return null;
    }
    const { helpActive } = this.state;
    const paramInputs = this.renderParamInputs();

    return (
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <div className="config-header section">
          <h4 className="config-header-title section">{eventRef.name}</h4>
          <div>
            {(eventRef.helpSections || []).length > 0 && (
              <span
                className="white"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({ helpActive: !helpActive });
                }}
              >
                <span
                  style={{
                    marginRight: "-13px",
                  }}
                >
                  <i className="material-icons">
                    {helpActive ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                  </i>
                </span>
                <i className="material-icons">help</i>
              </span>
            )}
          </div>
        </div>
        {helpActive && (eventRef.helpSections || []).length > 0 && (
          <div
            className="app-item"
            style={{
              fontSize: "85%",
              whiteSpace: "pre-line",
              lineHeight: "120%",
            }}
          >
            {(eventRef.helpSections || []).map((section) => {
              return (
                <div
                  key={section.title}
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                  >
                    {section.title}
                  </div>
                  <div>{section.desc}</div>
                </div>
              );
            })}
          </div>
        )}
        <div
          className="app-item"
          style={{
            padding: "18px",
            borderRadius: "0px 0px 3px 3px",
          }}
        >
          {paramInputs.length === 0 && "No Parameters Required"}
          {paramInputs}
        </div>
      </div>
    );
  }
}

class ActionParamSection extends React.Component {
  state = {
    helpActive: false,
  };

  renderParamInputs() {
    const foundDevice = this.props.resources.devices.find(
      (d) =>
        d.enclosureSerialNumber == this.props.match.params.enclosureSerialNumber
    );
    if (!foundDevice) {
      console.log("no device found for action param section");
      return [];
    } else {
      const outlets = this.props.resources.outlets.filter(
        (o) => o.enclosureSerialNumber == foundDevice.enclosureSerialNumber
      );
      const sensors = this.props.resources.sensors.filter(
        (s) => s.enclosureSerialNumber == foundDevice.enclosureSerialNumber
      );

      const { actionForm } = this.props;

      const postRequestParams = [
        {
          label: "None",
          value: "none",
        },
        {
          label: "Inlet Data",
          value: "inlets", // TODO make this conditional for device
        },
        {
          label: "Sensor Port Data",
          value: "sensors",
        },
      ];
      // if (device.numBanks > 1) {
      postRequestParams.push({
        label: "Circuit Data",
        value: "circuits", // banks
      });
      // }
      if (
        foundDevice.outletPwrMeasurementsSupported ||
        foundDevice.outletSwitchingSupported
      ) {
        postRequestParams.push({
          label: "Outlet Data",
          value: "outlets",
        });
      }

      const actionRef = actionRefs.find((a) => a.code == actionForm.code);
      if (!actionRef || actionRef.params.length === 0) return null;

      // console.log(actionForm)

      var ioHubRelayOutputOptions = [
        {
          label: "Relay Output 1",
          value: "1",
        },
        {
          label: "Relay Output 2",
          value: "2",
        },
        {
          label: "Relay Output 3",
          value: "3",
        },
        {
          label: "Relay Output 4",
          value: "4",
        },
        {
          label: "Relay Output 5",
          value: "5",
        },
        {
          label: "Relay Output 6",
          value: "6",
        },
      ];
      if (actionForm.code == 32 && (actionForm.params || [])[0]) {
        if (actionForm.params[0] === "A" || actionForm.params[0] === "B") {
          let foundIoHubSensor = sensors.find(
            (s) =>
              s.sensorPort === actionForm.params[0] &&
              s.sensorType === "dio_hub"
          );
          if (foundIoHubSensor) {
            ioHubRelayOutputOptions = (foundIoHubSensor.outputRelays || []).map(
              (r) => ({
                // label: `Relay Output ${r.index} - ${r.outputRelayName}`,
                label: `${r.index}. ${r.outputRelayName}`,
                value: r.index,
              })
            );
          }
        }
      }

      const actionParameterInputs = [
        {
          actionCode: 11,
          inputs: [
            {
              inputType: "select",
              options: outlets.map((outlet) => ({
                label: outlet.outletName,
                value: outlet.outlet_id,
              })),
            },
            {
              inputType: "select",
              options: [
                {
                  label: "ON",
                  value: "ON",
                },
                {
                  label: "OFF",
                  value: "OFF",
                },
              ],
            },
          ],
        },
        {
          actionCode: 12,
          inputs: [
            {
              inputType: "select",
              options: outlets.map((outlet) => ({
                label: outlet.outletName,
                value: outlet.outlet_id,
              })),
            },
          ],
        },
        {
          actionCode: 15,
          inputs: [
            {
              inputType: "text",
              placeholder: "eg: support@synaccess-net.com",
            },
          ],
          // },{
          //   actionCode: 16,
          //   inputs: [{
          //     inputType: "select",
          //     options: groups.map(group => ({
          //       label: group.groupName,
          //       value: group.id
          //     }))
          //   }, {
          //     inputType: "select",
          //     options: [{
          //       label: "ON",
          //       value: "ON"
          //     }, {
          //       label: "OFF",
          //       value: "OFF"
          //     }]
          //   }]
          // },{
          //   actionCode: 17,
          //   inputs: [{
          //     inputType: "select",
          //     options: groups.map(group => ({
          //       label: group.groupName,
          //       value: group.id
          //     }))
          //   }]
        },
        {
          actionCode: 18,
          inputs: [
            {
              inputType: "text",
              description: "Format: HOST:PORT - Default SNMP Trap Port: 162",
              placeholder: "ex: 192.168.1.101:162",
            },
            {
              inputType: "text",
              placeholder: "ex: public",
            },
          ],
        },
        {
          actionCode: 19,
          inputs: [
            {
              inputType: "text",
              description: "Format: HOST:PORT - Default SNMP Trap Port: 162",
              placeholder: "ex: 192.168.1.101:162",
            },
          ],
        },
        {
          actionCode: 20,
          inputs: [
            {
              inputType: "text",
              placeholder: "",
            },
            {
              inputType: "select",
              options: postRequestParams,
            },
          ],
        },
        {
          actionCode: 21,
          inputs: [
            {
              inputType: "text",
              placeholder: "16192098077",
            },
          ],
          // },{
          //   actionCode: 22,
          //   inputs: [{
          //     inputType: "select",
          //     options: relayOutputs.map(relayOutput => ({
          //       label: relayOutput.relayName,
          //       value: relayOutput.id
          //     }))
          //   }, {
          //     inputType: "select",
          //     options: [{
          //       label: "ON",
          //       value: "ON"
          //     }, {
          //       label: "OFF",
          //       value: "OFF"
          //     }]
          //   }]
          // },{
          //   actionCode: 23,
          //   inputs: [{
          //     inputType: "select",
          //     options: relayOutputs.map(relayOutput => ({
          //       label: relayOutput.relayName,
          //       value: relayOutput.id
          //     }))
          //   }]
        },
        {
          actionCode: 24,
          inputs: [
            {
              inputType: "text",
              description: "Format: HTTP_TYPE://HOST:PORT ",
              placeholder: "ex: http://192.168.1.100:80",
            },
            {
              inputType: "text",
              description: "Format: outlet index or outlet id ",
              placeholder: "ex: 2",
            },
            {
              inputType: "text",
              description: "Format: PAT from Other PDU ",
              placeholder: "cqf0u5mtzDFfG2yCKJA",
              type: "password",
            },
          ],
        },
        {
          actionCode: 25,
          inputs: [
            {
              inputType: "text",
              description: "Format: HTTP_TYPE://HOST:PORT ",
              placeholder: "ex: http://192.168.1.100:80",
            },
            {
              inputType: "text",
              description: "Format: outlet index or outlet id ",
              placeholder: "ex: 2",
            },
            {
              inputType: "text",
              description: "Format: PAT from Other PDU ",
              placeholder: "cqf0u5mtzDFfG2yCKJA",
              type: "password",
            },
          ],
        },
        {
          actionCode: 26,
          inputs: [
            {
              inputType: "text",
              description: "Format: HTTP_TYPE://HOST:PORT ",
              placeholder: "ex: http://192.168.1.100:80",
            },
            {
              inputType: "text",
              description: "Format: outlet group index or outlet group id ",
              placeholder: "ex: 2",
            },
            {
              inputType: "text",
              description: "Format: PAT from Other PDU ",
              placeholder: "cqf0u5mtzDFfG2yCKJA",
              type: "password",
            },
          ],
        },
        {
          actionCode: 27,
          inputs: [
            {
              inputType: "text",
              description: "Format: HTTP_TYPE://HOST:PORT ",
              placeholder: "ex: http://192.168.1.100:80",
            },
            {
              inputType: "text",
              description: "Format: outlet group index or outlet group id ",
              placeholder: "ex: 2",
            },
            {
              inputType: "text",
              description: "Format: PAT from Other PDU ",
              placeholder: "cqf0u5mtzDFfG2yCKJA",
              type: "password",
            },
          ],
        },
        {
          actionCode: 28,
          inputs: [
            {
              inputType: "text",
              description: "Format: HTTP_TYPE://HOST:PORT ",
              placeholder: "ex: http://192.168.1.100:80",
            },
            {
              inputType: "text",
              description: "Format: outlet index or outlet id ",
              placeholder: "ex: 2",
            },
            {
              inputType: "text",
              description: "Format: PAT from Other PDU ",
              placeholder: "cqf0u5mtzDFfG2yCKJA",
              type: "password",
            },
          ],
        },
        {
          actionCode: 29,
          inputs: [
            {
              inputType: "text",
              description: "Format: HTTP_TYPE://HOST:PORT ",
              placeholder: "ex: http://192.168.1.100:80",
            },
            {
              inputType: "text",
              description: "Format: outlet group index or outlet group id ",
              placeholder: "ex: 2",
            },
            {
              inputType: "text",
              description: "Format: PAT from Other PDU ",
              placeholder: "cqf0u5mtzDFfG2yCKJA",
              type: "password",
            },
          ],
        },
        {
          actionCode: 30,
          inputs: [
            {
              inputType: "select",
              options: [
                {
                  label: "Disabled",
                  value: "disabled",
                },
                {
                  label: "Bridge Mode",
                  value: "bridge_mode",
                },
              ],
            },
          ],
        },
        {
          actionCode: 32,
          inputs: [
            {
              inputType: "select",
              options: [
                {
                  label: "Sensor Port A",
                  value: "A",
                },
                {
                  label: "Sensor Port B",
                  value: "B",
                },
              ],
            },
            {
              inputType: "select",
              options: ioHubRelayOutputOptions,
            },
            {
              inputType: "select",
              options: [
                {
                  label: "OPEN (NO-COM)",
                  value: "OPEN",
                },
                {
                  label: "CLOSE (NC-COM)",
                  value: "CLOSE",
                },
              ],
            },
          ],
          // }, {
          //   actionCode: 31,
          //   inputs: [{
          //     inputType: "number",
          //     // type: "number"
          //     // description:

          //   }]
        },
      ];

      const foundActionParameterInputs = actionParameterInputs.find(
        (paramObj) => paramObj.actionCode === actionRef.code
      );
      if (!foundActionParameterInputs) return null;

      return foundActionParameterInputs.inputs.map(
        (actionParamInput, index) => {
          var shouldIncludeHelpIcon =
            (actionRef.helpSections || []).filter((h) => h.paramIndex === index)
              .length > 0;
          var InputField = Fields[actionParamInput.inputType];
          if (!InputField) return null;
          return (
            <InputField
              key={index}
              {...actionParamInput}
              helpIconAction={
                shouldIncludeHelpIcon
                  ? () => this.setState({ helpActive: !this.state.helpActive })
                  : null
              }
              label={actionRef.params[index]}
              value={(actionForm.params || [])[index]}
              callback={(result) => {
                const newParams = [...(actionForm.params || [])];
                newParams[index] = result;
                this.props.setEventFormState({
                  newActionParams: newParams,
                  actionFormHasChanges: true
                });
              }}
            />
          );
        }
      );
    }
  }

  render() {
    const { actionForm } = this.props;
    const { helpActive } = this.state;

    const actionRef = actionRefs.find((a) => a.code == actionForm.code);
    if (!actionRef || actionRef.params.length === 0) {
      console.log("no action ref found");
      return null;
    }

    return (
      <div>
        {/* <div>
          {(actionRef.helpSections || []).length > 0 &&
            <span
              // className="white"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                this.setState({ helpActive: !helpActive })
              }}
            >
              <span
                style={{
                  marginRight: "-13px"
                }}
              >
                <i className="material-icons">
                  {helpActive ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                </i>
              </span>
              <i className="material-icons">
                help
              </i>
            </span>
          }
        </div> */}
        <div>
          {/* {(helpActive && (actionRef.helpSections || []).length > 0) && */}
          {(actionRef.helpSections || []).length > 0 && (
            <div
              // className="app-item"
              style={{
                fontSize: "85%",
                whiteSpace: "pre-line",
                lineHeight: "120%",
              }}
            >
              {(actionRef.helpSections || []).map((section) => {
                return (
                  <div
                    key={section.title}
                    style={{
                      marginBottom: "15px",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        marginBottom: "10px",
                      }}
                    >
                      {section.title}
                    </div>
                    <div>{section.desc}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div>{this.renderParamInputs()}</div>
      </div>
    );
  }
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(
    /[[\]]/g, //     /[\[\]]/g,
    "\\$&"
  );
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return ""; // null
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
