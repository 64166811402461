import React from "react";
import SubHeader from "../../components/common/topHeader/subHeader";
import DeviceConnected from "../devices/deviceConnected";
import DeviceAlerts from "../devices/deviceAlerts";
import DevicePageInlet from '../devices/deviceInlets';
import DeviceOutlets from './deviceOutlets';
import DeviceSensors from './deviceSensors';

import axios from "axios";
import NotificationManager from "../../utils/notificationManager";
// import ModalManager from '../../componentscommon/modal/ModalManager
import ModelManager from "../../components/common/modal/ModalManager";
import TextInput from "../../components/common/inputs/textInput";
import SelectInput from "../../components/common/inputs/selectInput";
import Loading from "../../components/common/loading";
import { Link } from "react-router-dom";

import { Switch, Route, Redirect } from "react-router-dom";
import ModalManager from "../../components/common/modal/ModalManager";

export default class DeviceGroupPage extends React.Component {
  componentDidMount() {
    if (
      !this.props.resources.groupsLoaded &&
      !this.props.resources.groupsLoading
    ) {
      this.props.fetchResource("groups");
    }
  }

  render() {
    const foundGroup = this.props.resources.groups.find(
      (g) => g.id == this.props.match.params.groupId
    );
    const newGroup = this.props.match.params.groupId === "new";
    const isAdmin = this.props.user.is_enterprise_admin || this.props.user.has_admin_privileges;
    const sections = [
      {
        label: "Devices",
        icon: "power",
        path: `/devices/groups/${this.props.match.params.groupId}/devices`,
        props: {
          ...this.props,
          resources: {
            ...this.props.resources,
            devices: this.props.resources.devices.filter((d) =>
              foundGroup?.items.includes(d.enclosureSerialNumber.toString())
            ),
          },
          addGroupDeviceActive: true,
        },
        component: DeviceConnected,
      },
      {
        label: "Power Consumption",
        icon: "power",
        path: `/devices/groups/${this.props.match.params.groupId}/inlets`,
        props: {
          ...this.props,
          resources: {
            ...this.props.resources,
            devices: this.props.resources.devices.filter((d) =>
              foundGroup?.items.includes(d.enclosureSerialNumber.toString())
            ),
          },
        },
        disabled: this.props.user.role && !this.props.user.role.viewInletInfo,
        component: DevicePageInlet
      },
      {
        label: "Manage Outlets",
        icon: "outlet",
        path: `/devices/groups/${this.props.match.params.groupId}/outlets`,
        props: {
          ...this.props,
          resources: {
            ...this.props.resources,
            // devices: this.props.resources.devices.filter((d) =>
            //   foundGroup?.items.includes(d.enclosureSerialNumber.toString())
            // ),
            outlets: this.props.resources.outlets.filter((o) =>
              foundGroup?.items.includes(o.enclosureSerialNumber.toString())
            ),
          },
          hideGroupInputs: true,
        },
        disabled: this.props.user.role && !this.props.user.role.viewOutletInfo,
        component: DeviceOutlets,
      },
      {
        label: "Alerts",
        icon: "notifications",
        path: `/devices/groups/${this.props.match.params.groupId}/alerts`,
        props: {
          ...this.props,
          resources: {
            ...this.props.resources,
            events: this.props.resources.events.filter((d) =>
              foundGroup?.items.includes(d.enclosureSerialNumber.toString())
            ),
            devices: this.props.resources.devices.filter((d) =>
              foundGroup?.items.includes(d.enclosureSerialNumber.toString())
            ),
          },
        },
        component: DeviceAlerts,
        disabled: this.props.user.role && !this.props.user.role.viewEventInfo,
      },
      {
        label: "Sensors",
        icon: "device_thermostat",
        path: `/devices/groups/${this.props.match.params.groupId}/sensors`,
        props: {
          ...this.props,
          resources: {
            ...this.props.resources,
            sensors: this.props.resources.sensors.filter((s) =>
              foundGroup?.items.includes(s.enclosureSerialNumber.toString())
            ),
          },
        },
        component: DeviceSensors,
        disabled: this.props.user.role && !this.props.user.role.viewSensorInfo,
      },
      {
        label: "Users",
        icon: "group",
        path: `/devices/groups/${this.props.match.params.groupId}/users`,
        props: {
          ...this.props,
        },
        disabled: !isAdmin ,
        component: GroupUsers,
      },
      {
        label: "Settings",
        icon: "settings",
        path: `/devices/groups/${this.props.match.params.groupId}/settings`,
        props: {
          ...this.props,
        },
        disabled: !isAdmin && this.props.user.enterprise_Id,
        component:
          this.props.resources.groupsLoading ? Loading : GroupSettings,
      },
      {
        label: "Privileges",
        icon: "lock",
        path: `/devices/groups/${this.props.match.params.groupId}/privileges`,
        disabled: !isAdmin,
        props: {
          ...this.props,
        },
        component: GroupPrivileges,
      },
    ];
    return (
      <div>
        {!newGroup && (
          <SubHeader
            sections={sections.filter((section) => !section.disabled)}
          />
        )}
        <div
          style={{
            marginLeft: "15px",
            marginRight: "15px",
            paddingTop: "20px",
            borderBottom: "solid #ddd 1px",
          }}
        >
          <h5>{foundGroup?.name}</h5>
        </div>
        <Switch>
          {sections &&
            sections.map((section) => {
              return (
                <Route
                  key={section.label}
                  path={section.path}
                  exact={section.exact}
                  render={(props) => {
                    // const CompToRender = scrollTopOnMount(section.component);
                    const CompToRender = section.component;
                    return (
                      <CompToRender
                        {...(section.props || {})}
                        match={this.props.match}
                      />
                    );
                  }}
                />
              );
            })}
          {sections && (
            <Redirect
              exact
              to={
                newGroup
                  ? `/devices/groups/new/settings`
                  : `/devices/groups/${this.props.match.params.groupId}/devices`
              }
            />
          )}
        </Switch>
      </div>
    );
  }
}

class GroupUsers extends React.Component {
  state = {
    addUserActive: false,
    userToAdd: "",
  };
  requestAddUser = () => {
    const relevantDevices = this.props.resources.devices.filter((d) =>
      this.props.resources.groups
        .find((g) => g.id == this.props.match.params.groupId)
        .items.includes(d.enclosureSerialNumber.toString())
    );
    ModelManager.confirm(
      <div>
        <div>Are you sure you want to add this user to the group?</div>
        {/* the following devices will be accessible to this user */}
        <div>This user will have access to the following devices</div>
        <ul style={{ marginTop: "10px" }}>
          {relevantDevices.map((d) => {
            return (
              <li key={d.enclosureSerialNumber}>
                {d.deviceName}
                {d.enclosureSerialNumber}
              </li>
            );
          })}
        </ul>
      </div>,
      "Add User to Group",
      "Confirm",
      (status, close) => {
        if (status) {
          this.requestAddUserCall(() => {
            close();
          });
        }
      }
    );
  };
  requestAddUserCall = (cb = () => {}) => {
    axios
      .post(
        `/v1/groups/${this.props.match.params.groupId}/addUser`,
        {
          user_id: this.state.userToAdd,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((data) => {
        this.setState({ addUserActive: false, userToAdd: "" });
        NotificationManager.success("User added to group");

        this.props.updateFetchWrapperState({
          groups: this.props.resources.groups.map((g) => {
            if (g.id == this.props.match.params.groupId) {
              return {
                ...g,
                users: [...g.users, data.data.data],
              };
            }
            return g;
          }),
        });
        cb();
      })
      .catch((e) => {
        console.log(e);
        NotificationManager.alert("Error adding user to group");
      });
  };
  requestRemoveUser = (userId) => {
    ModelManager.confirm(
      <div>
        <div>Are you sure you want to remove this user from the group?</div>
      </div>,
      "Remove User from Group",
      "Confirm",
      (status, close) => {
        if (status) {
          this.requestRemoveUserCall(userId, () => {
            close();
          });
        }
      }
    );
  };
  requestRemoveUserCall = (userId, cb = () => {}) => {
    axios
      .post(
        `/v1/groups/${this.props.match.params.groupId}/removeUser`,
        {
          // user_id: this.state.userToAdd,
          user_id: userId,
        },
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then((data) => {
        this.setState({ addUserActive: false, userToAdd: "" });
        NotificationManager.success("User removed from group");
        this.props.updateFetchWrapperState({
          groups: this.props.resources.groups.map((g) => {
            if (g.id == this.props.match.params.groupId) {
              return {
                ...g,
                users: g.users.filter((u) => u.id !== userId),
              };
            }
            return g;
          }),
        });
        cb();
      })
      .catch((e) => {
        console.log(e);
        NotificationManager.error("Error removing user from group");
      });
  };

  render() {
    const columnWidths = [
      1,
      14, // Email
      8, // Admin
      8, // Status
      1, // delete
    ];

    const foundGroup = this.props.resources.groups.find(
      (g) => g.id == this.props.match.params.groupId
    );
    if (!foundGroup) {
      return null;
    }
    const users = (this.props.resources.userEnterprise.users || []).filter(
      (u) => !foundGroup.users.find((fu) => u.email_id === fu.email_id)
    );
    return (
      <div>
        <div className="grid-x">
          <div className="cell">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "20px",
              }}
            >
              {!this.state.addUserActive ? (
                <div
                  className="button"
                  onClick={() => {
                    this.setState({ addUserActive: true });
                  }}
                >
                  Add User
                </div>
              ) : (
                <div
                  className="button"
                  style={{ backgroundColor: "#bbb", color: "#333" }}
                  onClick={() => {
                    this.setState({ addUserActive: false, userToAdd: "" });
                  }}
                >
                  Cancel
                </div>
              )}
            </div>
          </div>
          <div className="cell large-10 ">
            <div className={`grid-row header`}>
              <div style={{ flex: columnWidths[0] }}></div>
              <div style={{ flex: columnWidths[1] }}>Email</div>
              <div style={{ flex: columnWidths[2] }}>Privileges</div>
              <div style={{ flex: columnWidths[3] }}>Status</div>
              <div style={{ flex: columnWidths[4] }}></div>
            </div>

            {this.state.addUserActive && (
              <div className="grid-row">
                <div style={{ flex: columnWidths[0] }}></div>
                <div style={{ flex: columnWidths[1] }}>
                  <select
                    style={{ marginBottom: "0px" }}
                    value={this.state.userToAdd}
                    onChange={(e) => {
                      if (e.target.value === "create-new-user") {
                        this.props.history.push("/team/members?new=true");
                      } else {
                        this.setState({ userToAdd: e.target.value });
                      }
                    }}
                  >
                    <option value="">Select User</option>
                    {users.map((user) => {
                      return (
                        <option key={user.email_id} value={user.id}>
                          {user.email_id}
                        </option>
                      );
                    })}
                    <option value="create-new-user">
                      + Create New User
                    </option>
                  </select>
                </div>
                <div
                  style={{
                    flex: columnWidths[2] + columnWidths[3],
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <span
                    className="button"
                    style={{
                      marginBottom: "0px",
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      this.requestAddUser();
                    }}
                  >
                    Add
                  </span>
                  <span
                    className="button"
                    style={{
                      backgroundColor: "#bbb",
                      color: "#333",
                      marginBottom: "0px",
                    }}
                    onClick={() => {
                      this.setState({ addUserActive: false, userToAdd: "" });
                    }}
                  >
                    Cancel
                  </span>
                </div>
                <div style={{ flex: columnWidths[4] }}></div>
              </div>
            )}
            {foundGroup?.users.map((user) => {
              const userIsAdmin =
                user.is_enterprise_admin || user.has_admin_privileges;
              return (
                <div key={user.email_id} className={`grid-row`}>
                  <div style={{ flex: columnWidths[0] }}></div>
                  <div style={{ flex: columnWidths[1] }}>
                    <Link
                      to={`/team/members/${user.user_id}`}
                      >
                        <span
                          style={{ textDecoration: "underline"}}
                        >
                    {user.email_id}

                        </span>

                      </Link>
                  
                  </div>
                  <div style={{ flex: columnWidths[2] }}>
                    {userIsAdmin ? "Admin" : "User"}
                  </div>
                  <div style={{ flex: columnWidths[3] }}>
                    {user.active_in_enterprise ? "Active" : "Inactive"}
                  </div>
                  <div style={{ flex: columnWidths[4] }}>
                    {!userIsAdmin && (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.requestRemoveUser(user.id);
                        }}
                      >
                        <i
                          style={{ fontSize: "17px" }}
                          className="material-icons"
                        >
                          close
                        </i>
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

class GroupSettings extends React.Component {
  state = {
    name: "",
    description: "",
    loading: false,
    hasChanges: false,
  };

  componentDidMount() {
    const foundGroup = this.props.resources.groups.find(
      (g) => g.id == this.props.match.params.groupId
    );
    if (foundGroup) {
      this.setState({
        name: foundGroup.name,
        description: foundGroup.description,
      });
    }
  }

  removeGroup = (groupId) => {
    ModelManager.confirm(
      "This action will permanently delete this group.",
      "Remove Group",
      "Remove",
      (submitted, close) => {
        if (submitted) {
          this.props.removeResource(
            "groups",
            this.props.match.params.groupId,
            (err, res) => {
              // console.log(res)
              // if (res) {
              //   NotificationManager.success("Group deleted");
              //   console.log(this.props)
              //   // this.props.history.push("/devices/groups");
              // } else {
              //   NotificationManager.alert("Error deleting group");
              // }
              this.props.history.push("/devices/groups");

              close();
            }
          );
        } else {
          // close();
        }
      }
    );
  };

  onSave = () => {
    const isNew = this.props.match.params.groupId === "new";

    if (isNew) {
      this.props.createResource(
        "groups",
        {
          name: this.state.name,
          groupType: "devices",
          description: this.state.description,
        },
        (err, newGroup) => {
          if (newGroup) {
            // NotificationManager.success("Group created");
            this.props.history.push(`/devices/groups/${newGroup.id}`);
            // console.log(err, newGroup);
          } else {
            console.log(err, newGroup);
            // NotificationManager.alert("Error creating group");
          }
        }
      );
    } else {
      this.props.modifyResource(
        "groups",
        this.props.match.params.groupId,
        {
          name: this.state.name,
          groupType: "devices",
          description: this.state.description,
        },
        (err, newGroup) => {
          if (newGroup) {
            // NotificationManager.success("Group Edited");
            // this.props.history.push(`/devices/groups/${newGroup.id}`);
            // console.log(err, newGroup);
          } else {
            // console.log(err, newGroup);
            // NotificationManager.alert("Error creating group");
          }
        }
      );
    }
  };
  render() {
    const isNew = this.props.match.params.groupId === "new";

    return (
      <div className="grid-container">
        <div className="grid-x grid-padding-x grid-padding-y grid-margin-x">
          <div
            className="cell"
            style={{ paddingBottom: "0px", paddingTop: "10px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                opacity: this.state.hasChanges ? 1 : 0,
              }}
            >
              <button
                className="button"
                style={{
                  backgroundColor: "#eee",
                  color: "#111",
                  marginRight: "10px",
                }}
                onClick={() => {
                  this.onCancel();
                }}
              >
                Cancel
              </button>
              <button
                className="button"
                onClick={() => {
                  if (!this.loading) {
                    this.onSave();
                  }
                }}
              >
                {this.state.loading ? <Loading /> : "Save"}
              </button>
            </div>
            <h4>Group Settings</h4>
          </div>
          <div className="cell large-8">
            <div>
              <TextInput
                label="Group Name"
                value={this.state.name}
                callback={(value) => {
                  this.setState({
                    name: value,
                    hasChanges: true,
                  });
                }}
              />
              <TextInput
                label="Group Description"
                value={this.state.description}
                callback={(value) => {
                  this.setState({
                    description: value,
                    hasChanges: true,
                  });
                }}
              />
              {!isNew && (
                <div
                  className="button"
                  style={{
                    backgroundColor: "maroon",
                    color: "white",
                    marginTop: "20px",
                  }}
                  onClick={() => {
                    this.removeGroup();
                  }}
                >
                  Delete group permanently
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class GroupPrivileges extends React.Component {
  state = {
    roleId: "",

    roles: [],
  };
  componentDidMount() {
    if (
      !this.props.resources.rolesLoaded &&
      !this.props.resources.rolesLoading
    ) {
      this.props.fetchResource("roles");
    } else {
      const foundGroup = this.props.resources.groups.find(
        (g) => g.id == this.props.match.params.groupId
      );
      if (foundGroup) {
        this.setState({ roleId: foundGroup.role_id });
      }
    }
  }
  componentDidUpdate = (prevProps) => {
      if (this.props.resources.rolesLoaded && !prevProps.resources.rolesLoaded) {
          const foundRole = this.props.resources.roles.find(role => role.id == this.props.match.params.roleId);
          if (foundRole) {
              this.setState({roleId: foundRole.id})
          }
      }
  }
  processRoleChange = (roleId) => {
    if (roleId === "create-new-role") {
      this.props.history.push("/users/roles/new");
    } else {
      ModalManager.confirm(
        "Are you sure you want to assign this role to the group? All users in this group will have the permissions described in this role.",
        "Confirm Role Assignment",
        "Confirm",
        (status, close) => {
          if (status) {
            this.props.modifyResource(
              "groups",
              this.props.match.params.groupId,
              {
                role_id: roleId,
              },
              (err, res) => {
                if (res) {
                  this.setState({
                    roleId,
                  })
                  close && close();
                }
              }
            )
          }
        }
      )
    }
  };

  render() {
    const foundGroup = this.props.resources.groups.find(
      (g) => g.id == this.props.match.params.groupId
    );
    if (!foundGroup) {
      return null;
    }
    const foundRole = this.props.resources.roles.find(
      (r) => r.id == foundGroup.role_id
    );
    const roles = this.props.resources.roles;
    return (
      <div>
        <div className="grid-x">
          <div className="cell">
            <div style={{ padding: "20px", paddingBottom: "0px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "20px",
              }}
            ></div>
            <h4>Role Assignment for Group</h4>
            <div>
              <div style={{ marginBottom: "10px" }}>
                Assign a role to this group. All users in this group will have the privileges described in this role. If no role is assigned, users in this group will have full privileges.
              </div>
            </div>
            <div>
              <SelectInput
                label="Role Assigned"
                value={this.state.roleId}
                callback={this.processRoleChange}
                defaultSelectText="Select a role"
                options={[
                  {
                    label: "No Role",
                    value: "",
                  },
                  ...roles.map((r) => ({
                    value: r.id,
                    label: r.name,
                  })),
                  {
                    label: "+ Create New Role",
                    value: "create-new-role",
                  },
                ]}
              />
            </div>

            </div>

            {foundRole &&
              <div
                style={{
                  padding: "20px",
                  fontFamily: "Arial, sans-serif",
                  color: "#333",
                }}
              >
                <div
                  style={{
                    borderBottom: "1px solid #bbb",
                    marginBottom: "20px",
                    paddingBottom: "10px",
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <h4 style={{ margin: 0 }}>Role Details</h4>
                  <div>
                      <div className="button" style={{ marginBottom: "0px" }}> 
                        <Link
                          to={`/users/roles/${foundRole.id}`}
                          style={{ color: "white" }}
                        >
                          Edit Role
                        </Link>
                      </div>
                  </div>
                </div>

                <div style={{ marginBottom: "15px" }}>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div style={{ fontWeight: "bold", width: "150px" }}>
                      Name:
                    </div>
                    <div>{foundRole.name}</div>
                  </div>

                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div style={{ fontWeight: "bold", width: "150px" }}>
                      Description:
                    </div>
                    <div>{foundRole.description}</div>
                  </div>
                </div>

                <div style={{ borderTop: "1px solid #bbb", paddingTop: "10px" }}>
                  <div style={{ marginBottom: "5px", fontWeight: "bold", marginTop: "10px" }}>
                    Permissions
                  </div>
                  <div style={{ paddingBottom: "20px" }}>
                    Permissions apply to all users in this group. Users will have access to the devices in this group, and the accompany permissions/privileges described in this role. Users may also be assigned roles at the user level along with the group level. Permissions are additive.
                  </div>
                  <div className={`grid-row header`}>
                    <div style={{ flex: 3 }}>Privilege</div>
                    <div style={{ flex: 2 }}>Status</div>
                    <div style={{ flex: 15 }}>Description</div>
                  </div>
                  {[{
                    label: "Basic Device Privileges",
                    permissions: [{
                      //   title: "View device info",
                      //   field: "viewDeviceInfo",
                      //   description: "View device information such as device name, model, connection state, and other basic device info"
                      // }, {
                        title: "Edit device info",
                        field: "editDeviceInfo",
                        description: "Edit device information such as device name and various configurations. Unpair devices."
                      }]
                  }, {
                    label: "Inlet & Power Privileges",
                    permissions: [{
                      title: "View inlet info",
                      field: "viewInletInfo",
                      description: "View inlet information such as inlet name, state, power, and other inlet info"
                    }, {
                      title: "Edit inlet info",
                      field: "editInletInfo",
                      description: "Edit inlet information such as inlet name, inlet boot states, reboot intervals, and other inlet specific info"
                    }, {
                      title: "View sensor accessory info",
                      field: "viewSensorInfo",
                      description: "View sensor information such as temperature and humidity readings, names, etc."
                    }]
                  }, {
                    label: "Outlet Privileges",
                    permissions: [{
                        title: "View all outlets",
                        field: "viewAllOutletsAllowed",
                        description: "View all outlet information such as outlet name, state, power, and other outlet info"
                      }, {
                        title: "Edit all outlets",
                        field: "editAllOutletsAllowed",
                        description: "Edit all outlet information such as outlet name, outlet boot states, reboot intervals, and other outlet specific info"
                      }, {
                        title: "Switch all outlets",
                        field: "switchAllOutletsAllowed",
                        description: "Switch outlets ON, OFF, or power cycle outlets"
                      }]
                  }, {
                    label: "Event & Activity Privileges",
                    permissions: [{
                        title: "View activity",
                        field: "viewActivityLog",
                        description: "View activity logs and history for the device group"
                      }, {
                        title: "View events & actions",
                        field: "viewEventInfo",
                        description: "View events and alerts for the device group"
                      }, {
                        title: "Create, edit, and delete events & actions",
                        field: "editEventInfo",
                        description: "Manage events and alerts for the device group"
                      }]
                  }].map((section => {
                    return (
                        <div>
                            <div style={{ marginTop: "15px" }}>
                            {/* {section.label} */}

                                <div className={`grid-row header`}>
                                    <div style={{ flex: 3 }}>{section.label}</div>
                                    <div style={{ flex: 3 }}></div>
                                    <div style={{ flex: 15 }}></div>
                                </div>
                            </div>
                            {section.permissions.map((item) => {
                            return (
                              <div className={`grid-row`}>
                              <div style={{ flex: 3 }}>{item.title}</div>
                              <div style={{ flex: 2, color: foundRole[item.field] ? "#222" : "#777" }}>
                                {foundRole[item.field] ? "Allowed" : "Not Allowed"}
                              </div>
                              <div style={{ flex: 15, color: "#444", fontStyle: "italic" }}>{item.description}</div>
                            </div>
                            );
                            })}
                        </div>
                        );
                  }))}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
