import React from 'react';
import { Link } from 'react-router-dom';

const RowList = ({
  rows=[],
  helpSections = [],
  toggleHelpSection = () => {},
}) => {
  return (
    rows.map((row, index) => {
      const {
        rowStyle = {},
        labelStyle = {},
        labelTitle = "",
        labelSpanStyle = {},
        valueStyle = {},
        disabled = false,
        link,
        unit = "",
        valueComponent
      } = row;
      
      if (disabled)
        return null;

      const ConditionalElement = link ? Link : "div";
      const value = typeof row.value === "number" ? (
        row.value % 1 === 0 ? (
          row.value
        ) : (
          row.value.toFixed(2)
        )
      ) : (
        row.value
      );
      
      const helpSectionItem = helpSections.find(h => h.title === row.label);
      return (
        <div
          key={row.label + index}
          style={{
            display: "flex",
            flex: 1,
            ...rowStyle
          }}
          // title={row.labelTitle}
          className="row-list-parent"
        >
          <div
            style={{
              flex: "1",
              fontWeight: '500',
              margin: "0px",
              borderRadius: "1px",
              // border: 'solid rgba(0,0,0,0.2) 1px',
              display: 'flex',
              alignItems: 'center',
              ...labelStyle
            }}
            className="app-item"
          >
            <span
              style={labelSpanStyle || {}}
              title={labelTitle}
            >
              {row.label}
            </span>
            {helpSectionItem &&
              <span
                className="dark-grey"
                onClick={() => toggleHelpSection()}
                style={{
                  cursor: 'pointer'
                }}
              >
                <i className="material-icons">help</i>
              </span>
            }
          </div>
            <ConditionalElement
              style={{
                flex: "1",
                margin: "0px",
                borderRadius: "1px",
                // border: 'solid rgba(0,0,0,0.2) 1px',
                display: 'flex',
                alignItems: 'center',
                color: "#333",
                textDecoration: link ? "underline" : "",
                whiteSpace: "pre-line",
                ...valueStyle
              }}
              className="app-item"
              to={link}
            >
              {valueComponent ? (valueComponent) : 
              row.onEdit ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                  className="hover-show"
                  onClick={row.onEdit}
                >
                  <span>{value} {unit}</span>
                  <span className="hover-item">
                    <i className="material-icons" style={{ color: "#666", marginLeft: "5px" }}>edit</i>
                  </span>
                </div>
              ) : (
                <React.Fragment>{value} {unit}</React.Fragment>
              )
              }
            </ConditionalElement>

        </div>
      )
    })
  )
};

export default RowList;
