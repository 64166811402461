
const actionRefs = [{
  code: 11,
  name: "Switch Outlet",
  params: [
    "Outlet",
    "State to Switch to"
  ],
  outletFeature: true
},{
  code: 12,
  name: "Outlet Reboot",
  params: [ "Outlet" ],
  outletFeature: true
}, {
  code: 15,
  name: "Email Recipient",
  params: [ "Recipient Email Address" ],
  helpSections: [{
    title: "Pre-requisites:",
    desc: "Make sure SMTP Server Settings are setup in \"Network\" -> \"SMTP\"",
    paramIndex: 0 // help icon will appear next to the first input field (zero index)
  }],
// }, {
//   code: 16,
//   name: "Switch Outlet Group",
//   params: [ "Outlet Group", "State to Switch to" ],
//   outletFeature: true
// }, {
//   code: 17,
//   name: "Power Cycle Outlet Group",
//   params: [ "Outlet Group" ],
//   outletFeature: true
}, {
  code: 18,
  name: "SNMPv2 Trap Notification",
  params: ["Trap Manager Host", "Trap Community String" ],
  helpSections: [{
    title: "SNMPv2 Trap Notifications",
    desc: "SNMP Traps OIDs are dependent on which events are triggering actions. To configure trap notifications, add this action to any relevant event triggers",
    // paramIndex: 0 // help icon will appear next to the first input field (zero index)
  }],
}, {
  code: 19,
  name: "SNMPv3 Trap Notification",
  params: ["Trap Manager Host"],
  helpSections: [{
    title: "SNMPv3 Trap Notifications",
    desc: "SNMP Traps OIDs are dependent on which events are triggering actions. SNMPv3 credentials are configured in Network -> SNMP settings section /network/snmp. To configure trap notifications, add this action to any relevant event triggers",
    // paramIndex: 0 // help icon will appear next to the first input field (zero index)
  }],
}, {
  code: 20,
  name: "HTTP Post Request",
  params: ["URL/IP Address", "Optional Stats"],
  helpSections: [{
    title: "Pre-requisites:",
    desc: "Make sure to include the correct port number of your target HTTP Server (i.e. IP:Port)",
    paramIndex: 0 // help icon will appear next to the first input field (zero index)
  }]
// }, {
//   code: 21,
//   name: "SMS Notification",
//   params: ["Target Cell Phone Number"],
//   helpSections: [{
//     title: "Important:",
//     desc: "Make sure to include the country code. An example US phone number would be 16192098077.",
//     paramIndex: 0 // help icon will appear next to the first input field (zero index)
//   }]
},{
  code: 22,
  name: "Switch Output Relay",
  params: [
    "Output Relay Switch",
    "State to Switch to"
  ]
},{
  code: 23,
  name: "Reboot Output Relay",
  params: [ "Output Relay Switch" ]
},{
  code: 24,
  name: "Switch Off Other PDU Outlet",
  params: [ "IP of Other PDU","Outlet ID or Index","Personal Access Token" ]
},{
  code: 25,
  name: "Switch On Other PDU Outlet",
  params: [ "IP of Other PDU","Outlet ID or Index","Personal Access Token" ]
},{
  code: 26,
  name: "Switch Off Other PDU Outlet Group",
  params: [ "IP of Other PDU","Group ID or Index","Personal Access Token" ]
},{
  code: 27,
  name: "Switch On Other PDU Outlet Group",
  params: [ "IP of Other PDU","Group ID or Index","Personal Access Token" ]
},{
  code: 28,
  name: "Reboot Other PDU Outlet",
  params: [ "IP of Other PDU","Outlet ID or Index","Personal Access Token" ]
},{
  code: 29,
  name: "Reboot Other PDU Outlet Group",
  params: [ "IP of Other PDU","Group ID or Index","Personal Access Token" ]
},{
  code: 30,
  name: "Switch Secondary Network Interface",
  params: [ "Mode to Set" ],
  helpSections: [{
    title: "Pre-requisites:",
    desc: "Requires a SynLink-compatible USB-to-Ethernet plugged into the USB port. Contact Synaccess for more information.\n\nBridged mode passes network packets from one ethernet port to the other.",
    paramIndex: 0 // help icon will appear next to the first input field (zero index)
  }]
},{
  code: 31,
  name: "Reset kWh Meter",
  params: []
}, {
  code: 32,
  name: "IO Hub Output Relay Switch",
  params: [
    "Port",
    "Relay",
    "State",
  ],
  helpSections: [{
    title: "Output Relay State",
    desc:`CLOSE: The relay is in the Normally Closed (NC) position, with the Common (COM) terminal making contact with the Normally Closed terminal 
    
    OPEN: The relay is in the Normally Open (NO) position, with the Common (COM) terminal making contact with the Normally Open terminal
    
    Symbols on the port of the IO Hub: 'O' = Normally Open Relay Contact. 'M' = Common. 'C' = Normally Closed Relay Contact.`,
    paramIndex: 2
  }]
// },{
//   code: 32,
//   name: "Delay",
//   params: [ "Number of seconds" ],
//   helpSections: [{
//     title: "Delay Next Action:",
//     desc: "Pause for a user specified number of seconds before executing the next action.",
//     paramIndex: 0 // help icon will appear next to the first input field (zero index)
//   }]
}];

const eventCategories = [{
  codes: [13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29],
  label: "Inlet Monitoring Events",
  relResource: "inlet", // this references the events to a particular component for viewng in triggerList.jsx
}, {
  codes: [31,32,33,34,35,36,37,38,39,40,41,42,43,44,59],
  label: "Bank Monitoring Events",
  relResource: "banks"
}, {
  codes: [45,46],
  label: "Outlet Monitoring Events",
  relResource: "outlets"
// }, {
//   codes: [47,48],
//   label: "Group Monitoring Events",
//   relResource: "groups"
}, {
  codes: [11,12],
  label: "Network Events",
  relResource: "network"
}, {
  codes: [49,50,51,52],
  label: "Temperature & Humidity Sensor Events",
  relResource: "tempSensors"
}, {
  codes: [62,63],
  label: "IO Hub Sensor Events",
  relResource: "ioSensors"
}, {
  codes: [64,65],
  label: "Sensor Events",
  relResource: "sensors"
}, {
  codes: [53, 54, 60, 61],
  label: "Scheduled/Interval Events",
  relResource: "schedule"
}, {
  codes: [30],
  label: "Circuit Breaker Trip Events",
  relResource: "circuitbreaker"
}, {
  codes: [55,56,57,58],
  label: "ATS Event Events",
  relResource: "ats"
}];

const codesForEveryone = [11, 12, 22, 49, 50, 51, 52, 53, 54, 59, 60, 61, 62, 63, 64, 65];
const singlePhaseEventCodes = [15, 16, 17, 18, 19, 20, 21, 23, 24, 25, 26, 27, 28];
const threePhaseEventCodes = [13, 14, 29];
const multiBankEventCodes = [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44]
const outletEventCodes = [
  45, 
  46, 
  // 47, // take out outlet groups for cloud for now
  // 48
]
const atsEventCodes = [55,56,57,58];
const circuitBreakerCodes = [30];
const nonInletCurrentEventCodes =[17, 18, 19, 20, 21, 22, 23, 24, 25,
   26, 27, 28, 33,34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44]; //events codes for non inlet current devices


export default {
  eventCodeModules: {
    codesForEveryone,
    singlePhaseEventCodes,
    threePhaseEventCodes,
    multiBankEventCodes,
    outletEventCodes,
    atsEventCodes,
    circuitBreakerCodes,
    nonInletCurrentEventCodes
  },
  eventCategories,
  actionRefs
}