import React from 'react';
// import icons from '../../../assets/icon-all';

const hamburger = (
  <svg style={{ weight: "38px", height: "38px" }} height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>
);
const ActivePageHeader = ({
  title,
  icon,
  mobileSideNavActive,
  miniModeActive,
  changeAppState
}) => {

  // const foundIcon = icons[icon];
  return (
    
    <header className="top-header-container">
      <div
        onClick={() => {
          changeAppState({
            mobileSideNavActive: !mobileSideNavActive, 
            miniModeActive: !miniModeActive, 
          })
        }}
        style={{
          cursor: 'pointer'
        }}
        className="top-header-mobile-hamburger"
      >
        {hamburger}
      </div>
      <div>
        <div style={{ width: "60px"}}>
          <i className="material-icons white" style={{ fontSize: "45px" }}>{icon}</i>
        </div>
        <h2 className="top-header-title" style={{ marginBottom: "8px" }}>{title}</h2>
      </div>
      <div />
    </header>
  )
};

export default ActivePageHeader;
