import React from 'react';
import Logo from '../../synaccess_white.svg';
import Loading from '../common/loading';
import NotificationManager from '../../utils/notificationManager';
export default class EmailVerification extends React.Component {
  state = {
    loading: false
  }
  render() {
    return (
      <div className="welcome-background">
        <div className="grid-container">
          <div className="grid-x grid-padding-x grid-padding-y">
            <div className="cell" style={{
              display: 'flex', justifyContent: 'flex-end'
            }}>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: "solid white 1px",
                  padding: '10px 30px',
                  borderRadius: '3px',
                  color: "white",
                  cursor: 'pointer',
                  // borderBottom: "solid white 1px"

                }}
                onClick={() => {
                  this.props.signout()
                }}
              >
                <i className="material-icons">logout</i>
                <span>
                  <b>
                    Sign Out
                  </b>
                </span>
              </div>
            </div>
          </div>
          <div className="grid-x grid-padding-x grid-padding-y align-center"  style={{ marginTop: "0vh" }}>
            <div
                className="cell large-4 medium-7 small-10"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // border: "solid red 1px"
                }}
              >
               <div
                // className="app-item"
                style={{
                  padding: "0px",
                  marginTop: "10vh",
                  borderRadius: "2px",
                  width: "100%"
                  // boxShadow: '2px 3px 3px rgba(0,0,0,0.1)',
                  // maxWidth: "520px",
                  // minWidth: "350px",
                  // border: "solid red 1px"
                }}
              >
                <div
                  style={{
                    // maxHeight: "60px",
                    // border: "solid red 1px"
                    paddingBottom: "20px",
                  }}
                >
                  <img alt="logo" src={Logo} width="165px" />
                </div>
                <div
                  style={{
                    // boxShadow: "2px 3px 3px rgba(0,0,0,0.15)",

                    boxShadow: "3px 3px 20px rgba(0,0,0,0.05)",
                    width: "100%",
                    backgroundColor: "white",
                    display: 'flex',
                    // alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: "column",
                    // textAlign: 'center',
                    borderRadius: "3px",
                    padding: "50px 40px",

                  }}
                >
                  <div
                    style={{ display: 'flex', justifyContent : 'center', paddingBottom: "12px" }}
                  >
                    <i className="material-icons" style={{ fontSize: "100px", width: "100px"}}>mail_lock</i>
                  </div>
                  <div
                    style={{
                      // backgroundColor: "#1d262f",
                      // padding: "30px 0px 0px 50px",
                      paddingBottom: "20px",
                      paddingTop: "20px",
                      // color: "white",
                      borderRadius: "2px",
                      // display: 'flex',
                      // justifyContent: 'center',
                      fontSize: "125%",
                      fontWeight: "600",
                    }}
                  >
                    A verification link has been sent to your email account
                  </div>
                  <div style={{
                  }}>
                    Check your email to verify your email and continue the registration process. You will still also need approval to join Synacess Cloud after email verification. 
                  </div>
                  <div>
                    <div
                      className="button"
                      style={{
                        marginTop: "30px",
                        // marginBottom: "40px",
                      }}
                      onClick={() => {
                        if (this.state.loading) {
                          return;
                        }
                        this.setState({
                          loading: true
                        })
                        this.props.resendVerificationEmail(() => {
                          // NotificationManager
                          this.setState({
                            loading: false
                          })
                        });
                        NotificationManager.success("Verification email has been sent", "Success");
                      }}
                    >
                      {this.state.loading ? (
                        <Loading white />
                        ) : (
                          "Resend Email"
                      ) }
                    </div>

                  </div>
                </div>
                <div
                  style={{
                    paddingTop: "20px",
                    // padding: "20px 10px 10px 10px",
                    color: "white",
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <div>
                    Need Help? {" "}
                    <a
                      href="http://synaccess.com/contact"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b style={{ color: "white" }}>Contact Us</b> 
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}