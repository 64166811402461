import React from 'react';
import TextInput from '../common/inputs/textInput';
import Logo from "../../synaccess_white.svg";
import { Link } from 'react-router-dom'
import parseQueryParam from '../../utils/parseQueryParams';


export default class ResetPasswords extends React.Component {
  state = {
    email: "",
    submitted: false,
    passwordMode: false,
    password: "",
    confirmPassword: "",
    errorMsg: "",
    passwordChangeSuccess: false,
  }
  componentDidMount() {
    const { t } = parseQueryParam(this.props.history.location.search);
    if (t) {
      this.setState({
        passwordMode: true
      })
    }
  }
  resetPasswordButtonPress = () => {
    this.setState({
      errorMsg: "",
    })
    if (this.state.password === this.state.confirmPassword) {
      this.props.resetPassword({password: this.state.password}, (err, res) => {
        if (err) {
          this.setState({
            errorMsg: err.message
          })
        } else {
          if (res.data) {
            this.setState({
              passwordChangeSuccess: true
            })

          }
        }
      });
    } else {
      this.setState({
        errorMsg: "Passwords do not match"
      })
    }
  }
  render() {
    return (
      <div
        style={{
          height: "117.7vh",
          overflow: "auto",
        }}
        className="background-gradient"
      >
        <div className="grid-container">
          <div className="grid-x grid-padding-x grid-padding-y align-center">
            <div
              className="cell large-5 medium-7 small-10"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  padding: "0px",
                  marginTop: "10vh",
                  borderRadius: "2px",
                  width: "100%"
                }}
              >
                <div
                  style={{
                    paddingBottom: "20px",
                  }}
                >
                  <img alt="logo" src={Logo} width="165px" />
                </div>
                {this.state.submitted ? (
                  <div
                    style={{
                      boxShadow: "3px 3px 20px rgba(0,0,0,0.05)",
                      width: "100%",
                      backgroundColor: "white",
                      padding: "50px 40px",
                      display: 'flex',
                      // alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: "column",
                      // textAlign: 'center',
                      borderRadius: "3px"
                    }}
                  >
                    <div
                      style={{
                        paddingBottom: "20px",
                        // paddingTop: "20px",
                        borderRadius: "2px",
                        fontSize: "125%",
                        fontWeight: "600",
                      }}
                    >
                      Check your email for instructions to reset your password
                    </div>
                    <div style={{
                      // textAlign: 'start'
                    }}>
                      If you haven't received your email in 5 minutes, check your spam or
                      <span
                        style={{
                          color: "#223647",
                          paddingLeft: '5px',
                          fontWeight: "600",
                          cursor: 'pointer'
                        }}
                        onClick={() => this.props.submitForgottenPassEmail()}
                      >
                        resend
                      </span>
                    </div>

                  </div>
                ) : (
                  this.state.passwordChangeSuccess ? (
                    <div
                      style={{
                        boxShadow: "3px 3px 20px rgba(0,0,0,0.05)",
                        width: "100%",
                        backgroundColor: "white",
                        padding: "50px 40px",
                        display: 'flex',
                        // alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: "column",
                        // textAlign: 'center',
                        borderRadius: "3px"
                      }}
                    >
                      <div
                        style={{
                          paddingBottom: "20px",
                          // paddingTop: "20px",
                          borderRadius: "2px",
                          fontSize: "125%",
                          fontWeight: "600",
                        }}
                      >
                        You've successfully changed your password
                      </div>

                      <button
                        className="button"
                      >
                        <Link to="/">
                          <span
                            style={{
                              color: "white"
                            }}
                          >
                            Continue
                          </span>
                        </Link>
                      </button>
                    </div>

                  ) : (
                    <React.Fragment>
                      <div
                        style={{
                          boxShadow: "3px 3px 20px rgba(0,0,0,0.05)",
                          width: "100%",
                          backgroundColor: "white",
                          padding: "50px 40px",
                          display: 'flex',
                          // alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: "column",
                          // textAlign: 'center',
                          borderRadius: "3px"
                        }}
                      >
                        <div
                          style={{
                            paddingBottom: "20px",
                            // paddingTop: "20px",
                            borderRadius: "2px",
                            fontSize: "125%",
                            fontWeight: "600",
                          }}
                        >
                          Reset your password
                        </div>
                        {this.state.passwordMode ? (
                          <React.Fragment>
  
                            <TextInput
                              label="Password"
                              callback={text => this.setState({ password: text })}
                              value={this.state.password}
                              inputContainerStyle={{
                                width: "100%",
                                marginBottom: "0px",
                              }}
                              type="password"
                            />
                            <TextInput
                              label="Confirm Password"
                              callback={text => this.setState({ confirmPassword: text })}
                              value={this.state.confirmPassword}
                              inputContainerStyle={{
                                width: "100%",
                                marginBottom: "0px",
                              }}
                              type="password"
                            />
                            <div
                            >
                              {[{
                                name: "2 lowercase characters required",
                                valid: /[a-z].*[a-z]/.test(this.state.password)
                              }, {
                                name: "2 uppercase characters required",
                                valid: /[A-Z].*[A-Z]/.test(this.state.password)
                              }, {
                                name: "2 number characters required",
                                valid: /[0-9].*[0-9]/.test(this.state.password)
                              }, {
                                name: "2 special characters required",
                                valid: /[!@#$%^&*;].*[!@#$%^&*;]/.test(this.state.password)
                              }].map(validation => {
                                return (
                                  <div
                                    key={validation.name}
                                    style={{
                                      color: validation.valid ? "forestgreen" : "#666",
                                      display: 'flex',
                                      paddingBottom: "10px",
                                      alignItems: 'center'
                                    }}
                                  >
                                    <i className="material-icons">{validation.valid ? "check" : "cancel"}</i>
                                    <span>{validation.name}</span>
                                  </div>
                                )
                              })}
                            </div>
                            <div
                              style={{
                                color: "maroon",
                                padding: "10px 0px 20px 0px",
                              }}
                            >
                              {this.state.errorMsg &&
                                this.state.errorMsg
                              }
                            </div>
                            <button
                              className="button"
                              onClick={() => {
                                this.resetPasswordButtonPress();
  
                              }}
                            >
                              Reset Password
                            </button>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div style={{
                              // textAlign: 'start'
                            }}>
                              Enter the email address associated with your account and we'll send you a link to reset your password.
                            </div>
                            <TextInput
                              label="Email"
                              callback={text => this.setState({ email: text })}
                              value={this.state.email}
                              inputContainerStyle={{
                                width: "100%"
                              }}
                            />
                            <button
                              className="button"
                              onClick={() => {
                                this.props.submitForgottenPassEmail(this.state.email, (status) => {
                                  this.setState({
                                    submitted: true
                                  })
                                });
                              }}
                            >
                              Submit
                            </button>
                            
                          </React.Fragment>
  
                        )}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: "10px"
                          }}
                        >
                          <Link
                            to="/login"
                          >
                            Return to sign in
                          </Link>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "20px 10px 10px 10px",
                          color: "white",
                        }}
                      >
                        Don't have an account?{" "}
                        <Link to="/register">
                          <b style={{ color: "white" }}>Sign up</b>
                        </Link>
                      </div>
                    </React.Fragment>

                  )

                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}