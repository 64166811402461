
import AWN from "awesome-notifications";

const options = {
  position: "top-right",
  
};

const notifier = new AWN(options);


export default notifier;