  import React from "react";
// import icons from '../../../assets/icon-all';
import { NavLink, Link } from "react-router-dom";
import Logo from '../../../synaccess_white.svg'

import pages from "../../../pages/routes";

const logo = (
  <svg className="App-logo" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M14.28,14.08L12.26,16.1C11.66,16.7 10.87,17 10.08,17C9.29,17 8.5,16.7 7.9,16.1C6.7,14.9 6.7,12.95 7.9,11.74L9.15,10.5L9.14,11.06C9.14,11.5 9.21,11.95 9.36,12.37L9.41,12.5L9.04,12.87C8.76,13.15 8.6,13.53 8.6,13.92C8.6,14.32 8.76,14.69 9.04,14.97C9.6,15.53 10.57,15.53 11.13,14.97L13.14,12.96C13.43,12.67 13.58,12.3 13.58,11.91C13.58,11.5 13.43,11.14 13.15,10.86C13,10.71 12.9,10.5 12.9,10.29C12.9,10.08 13,9.88 13.15,9.73C13.45,9.42 14,9.43 14.28,9.73C14.86,10.31 15.18,11.08 15.18,11.9C15.18,12.73 14.86,13.5 14.28,14.08M17.1,11.26L15.85,12.5L15.86,11.94C15.86,11.5 15.79,11.06 15.64,10.64L15.6,10.5L15.96,10.13C16.25,9.85 16.4,9.5 16.4,9.08C16.4,8.69 16.25,8.32 15.97,8.04C15.4,7.47 14.43,7.47 13.87,8.04L11.86,10.05C11.58,10.33 11.42,10.7 11.42,11.1C11.42,11.5 11.57,11.86 11.86,12.14C12,12.29 12.1,12.5 12.1,12.71C12.1,12.93 12,13.13 11.85,13.28C11.7,13.44 11.5,13.5 11.29,13.5C11.09,13.5 10.88,13.43 10.72,13.28C9.5,12.08 9.5,10.12 10.72,8.92L12.74,6.9C13.95,5.7 15.9,5.7 17.1,6.9C17.68,7.5 18,8.26 18,9.08C18,9.9 17.68,10.68 17.1,11.26Z"
    />
  </svg>
);

const hamburger = (
  <svg
    style={{ weight: "38px", height: "38px" }}
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg>
);

export default class SideHeader extends React.Component {
  componentDidMount() {
    const styling = localStorage.getItem("styling");

    if (styling) {
      const stylingJson = JSON.parse(styling);
      if (stylingJson.logoUrl) {
        this.setState({
          logo: stylingJson.logoUrl,
        });
      }
    }
  }
  state = {
    // active: false,
    logo: Logo
  };
  render() {
    // const { active } = this.state;
    const {
      // device,
      // lastUpdated,
      // wsActive,
      changeAppState,
      miniModeActive,
      mobileSideNavActive,
      // banks,
      // user = {
      //   role: {
      //     permissions: {}
      //   }
      // },
      // conf
      user,
    } = this.props;
    const items = pages(user, this.props.resources)
      .filter((p) => !p.unlisted)
      .filter((p) => !p.disabled)
      .filter((p) => !p.hideFromHeader)
      .map((page) => ({
        name: page.headerTitle,
        link: page.basePath,
        icon: page.headerIcon,
      }));
    return (
      <div
        className={`side-nav-wrapper${mobileSideNavActive ? " active" : " "}${
          miniModeActive ? " mini" : ""
        }`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "8px",
              paddingRight: "8px",
              alignItems: "flex-start",
              // marginBottom: '-20px',
              cursor: "pointer",
              zIndex: "999",
              position: "relative",
              // width: "30px"
            }}
            onClick={() => {
              changeAppState({
                miniModeActive: !miniModeActive,
                mobileSideNavActive: !mobileSideNavActive,
              });
            }}
          >
            {hamburger}
          </div>
          <Link to="/">
            <header className={`App-header ${miniModeActive ? "mini" : ""}`}>
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fill: "white",
                }}
              >
                {logo}
              </div>
              <div className="App-title">Synaccess Cloud</div> */}
              <img
                alt="logo"
                src={this.state.logo}
              />
            </header>
          </Link>

          <div
            className={`side-nav-container ${miniModeActive ? "mini" : ""}`}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "50%",
            }}
          >
            {items.map((item) => {
              return (
                <NavLink
                  to={`${item.link}`}
                  activeClassName={`side-nav-item active ${
                    miniModeActive ? "mini" : ""
                  }`}
                  className={`side-nav-item ${miniModeActive ? "mini" : ""}`}
                  key={item.name}
                  onClick={() => {}}
                >
                  <i className="material-icons">{item.icon}</i>
                  <div>{item.name}</div>
                </NavLink>
              );
            })}
          </div>
        </div>
        <div style={{ paddingBottom: "40px"}}>

        <div
            className={`side-nav-container ${miniModeActive ? "mini" : ""}`}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "50%",
            }}
          >

          <div style={{ color: "white", padding: "10px", paddingBottom: "10px" }}>
            <div style={{ 
              fontSize: "85%",
              paddingBottom: "3px",

            }}>Logged in as:</div>
            <div style={{
              fontWeight: "600",
              color: "white",
            }}>{user.email_id}</div>
          </div>
          <NavLink
            to="/users"
            className={`side-nav-item ${miniModeActive ? "mini" : ""}`}
            style={{marginBottom: "40px" }}
          >
            <i className="material-icons">account_circle</i>
            <div>Account</div>
          </NavLink>
          <a
            href="/logout"
            className={`side-nav-item ${miniModeActive ? "mini" : ""}`}
            onClick={() => {
              localStorage.removeItem("token");
              localStorage.removeItem("tokenExpiration");
              localStorage.removeItem("userId");

              this.props.history.replace("/login");
            }}
          >
            <i className="material-icons">logout</i>
            <div>Logout</div>
          </a>
          </div>
        </div>
      </div>
    );
  }
}
// const SideHeaderItems = ({items}) => {
//   return (
//     items.map(item => {
//       if (item.disabled) return null;
//       const ConditionalElement = item.link ? Link : "div";

//       return (
//         <div
//           key={item.label}
//           style={{
//             paddingBottom: '20px',
//             paddingLeft: '15px',
//             color: 'white'
//           }}
//         >
//           <div
//             style={{
//               paddingBottom: '3px',
//               fontSize: '75%',
//             }}
//           >
//             {item.label}:
//           </div>
//           <ConditionalElement
//             style={{
//               fontWeight: '500',
//               color: item.color || "white",
//               textDecoration: item.link ? "underline" : ""
//             }}
//             to={item.link}
//           >
//             {item.value}
//           </ConditionalElement>
//         </div>
//       )
//     })
//   )
// }
