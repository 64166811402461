
import React from "react";
import TextInput from "../../components/common/inputs/textInput";
import NotificationManager from "../../utils/notificationManager";
import Loading from "../../components/common/loading";
import axios from "axios";

export default class EditDeviceGroupPage extends React.Component {
  state = {
    selectedDevices: [],
    // selectedGroup: "",
    // selectedGroupId: "",
    // editingActive: true,

    searchBar: "",
    searchBar2: "",

    addGroupDeviceSerialNumbersLoading: [],
    removeGroupDeviceSerialNumbersLoading: [],
  };
  setActiveInletGroup = (groupId) => {
    const foundGroup =
      this.props.resources.groups.find((g) => g.id == groupId) || {};

    const foundDeviceIds = (foundGroup.items || []).map((i) => ({
      enclosureSerialNumber: parseInt(i, 10),
    }));

    this.setState({
      selectedDevices: foundDeviceIds,
      selectedGroup: foundGroup.name,
      selectedGroupId: foundGroup.id,
    });
  };
  updateGroup = (groupId) => {
    const items = this.state.selectedDevices.map(
      (d) => d.enclosureSerialNumber
    );
    this.props.modifyResource(
      "groups",
      groupId,
      {
        items,
      },
      (err, res) => {
        if (!err && res) {
          //   this.setState({ editingActive: false });
        } else {
          console.log("err occurred");
        }
      }
    );
  };

  addGroupDevice = (enclosureSerialNumber) => {
    this.setState((prevState) => ({
      addGroupDeviceSerialNumbersLoading: [
        ...prevState.addGroupDeviceSerialNumbersLoading,
        enclosureSerialNumber,
      ],
    }));
    // fetch call
    axios
      .post(
        `/v1/groups/${this.props.match.params.groupId}/addDevice`,
        {
          enclosureSerialNumber,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        this.setState((prevState) => ({
          addGroupDeviceSerialNumbersLoading: prevState.addGroupDeviceSerialNumbersLoading.filter(
            (sn) => sn !== enclosureSerialNumber
          ),
        }));        
        if (res.status === 200) {
          NotificationManager.success("Device added to group");
          this.props.updateFetchWrapperState({
            groups: this.props.resources.groups.map((group) => ({
              ...group,
              items:
                group.id == this.props.match.params.groupId
                  ? [...group.items, enclosureSerialNumber.toString()]
                  : group.items,
            })),
          });
        } else {
          NotificationManager.alert("Failed to add device to group");
        }
      })
      .catch((err) => {
        // this.setState({ addGroupDeviceLoading: false });
        this.setState((prevState) => ({
          addGroupDeviceSerialNumbersLoading: prevState.addGroupDeviceSerialNumbersLoading.filter(
            (sn) => sn !== enclosureSerialNumber
          ),
        }));
        console.log("err", err);
        NotificationManager.alert("Failed to add device to group");
      });
  };

  removeGroupDevice = (enclosureSerialNumber) => {
    // this.setState({ removeGroupDeviceLoading: true });
    this.setState((prevState) => ({
      removeGroupDeviceSerialNumbersLoading: [
        ...prevState.removeGroupDeviceSerialNumbersLoading,
        enclosureSerialNumber,
      ],
    }));
    // fetch call
    axios
      .post(
        `/v1/groups/${this.props.match.params.groupId}/removeDevice`,
        {
          enclosureSerialNumber,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        // this.setState({ removeGroupDeviceLoading: false });
        this.setState((prevState) => ({
          removeGroupDeviceSerialNumbersLoading: prevState.removeGroupDeviceSerialNumbersLoading.filter(
            (sn) => sn !== enclosureSerialNumber
          ),
        }));
        if (res.status === 200) {
          NotificationManager.success("Device removed from group");
          this.props.updateFetchWrapperState({
            groups: this.props.resources.groups.map((group) => ({
              ...group,
              items:
                group.id == this.props.match.params.groupId
                  ? group.items.filter(
                      (item) => item !== enclosureSerialNumber.toString()
                    )
                  : group.items,
            })),
          });
        } else {
          NotificationManager.alert("Failed to remove device from group");
        }
      })
      .catch((err) => {
        // this.setState({ removeGroupDeviceLoading: false });
        this.setState((prevState) => ({
          removeGroupDeviceSerialNumbersLoading: prevState.removeGroupDeviceSerialNumbersLoading.filter(
            (sn) => sn !== enclosureSerialNumber
          ),
        }));
        console.log("err", err);
        NotificationManager.alert("Failed to remove device from group");
      });
  };
  componentDidMount() {
    if (
      !this.props.resources.groupsLoaded &&
      !this.props.resources.groupsLoading
    ) {
      this.props.fetchResource("groups");
    } else {
      //   this.setActiveInletGroup(this.props.match.params.groupId);
    }
  }
  render() {
    const columnWidths = [
      1, //0 toggle
      8, //1 Name
      4, //1 Model
      4, //3 Serial
      4, //4 Status
      4, // edit
    ];
    const { devicesLoading } = this.props.resources;
    const {
      // addGroupDeviceLoading,
      // removeGroupDeviceLoading,
      addGroupDeviceSerialNumbersLoading,
      removeGroupDeviceSerialNumbersLoading,
    } = this.state;
    const foundGroup =
      this.props.resources.groups.find(
        (g) => g.id == this.props.match.params.groupId
      ) || {};

    const foundGroupDevices = (foundGroup.items || [])
      .map((enclosureSn) => {
        return this.props.resources.devices.find(
          (d) => d.enclosureSerialNumber == enclosureSn
        );
      })
      .filter((d) => d);

    const filteredFoundGroupDevices = foundGroupDevices.filter((d) => {
      const search = this.state.searchBar.toLowerCase();
      return (
        (d.deviceName || "").toLowerCase().includes(search) ||
        (d.modelNumber || "").toLowerCase().includes(search) ||
        (d.enclosureSerialNumber || "")
          .toString()
          .toLowerCase()
          .includes(search)
      );
    });

    const remainingDevices = this.props.resources.devices.filter(
      (d) =>
        !foundGroupDevices.find(
          (gd) => gd.enclosureSerialNumber == d.enclosureSerialNumber
        )
    );

    const filteredRemainingDevices = remainingDevices.filter((d) => {
      const search = this.state.searchBar2.toLowerCase();
      return (
        (d.deviceName || "").toLowerCase().includes(search) ||
        (d.modelNumber || "").toLowerCase().includes(search) ||
        (d.enclosureSerialNumber || "")
          .toString()
          .toLowerCase()
          .includes(search)
      );
    });
    return (
      <div>
        {filteredFoundGroupDevices.length > 0 ? (
          <div
            style={{
              overflowX: "auto",
              maxHeight: "45vh",
            }}
          >
            <div
              className="grid-x "
              style={{ width: "100%", marginBottom: "10px" }}
            >
              <div
                className="cell "
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ marginTop: "20px", marginLeft: "5px" }}>
                  <h5 style={{ marginBottom: "0px" }}>
                    {foundGroup.name} Devices
                  </h5>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    //   marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search for model number, device name, or serial number"
                    style={{
                      marginBottom: "0px",
                      minWidth: "420px",
                    }}
                    value={this.state.searchBar}
                    onChange={(e) => {
                      this.setState({ searchBar: e.target.value });
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        this.applyFilters();
                      }
                    }}
                  />
                  <span
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      padding: "0.45rem",
                      height: "2.43rem",
                      margin: "0 0 1rem",
                      marginBottom: "0px",
                      borderRadius: "0.1875rem",
                      backgroundColor: "#49535E",
                      color: "#fff",
                    }}
                    onClick={() => {
                      this.applyFilters();
                    }}
                  >
                    <i
                      className="material-icons"
                      style={{ marginRight: "0px" }}
                    >
                      search
                    </i>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="grid-row header"
              style={{ marginLeft: "5px", minWidth: "200px" }}
            >
              <div style={{ flex: columnWidths[1] }}>Device Name</div>
              <div style={{ flex: columnWidths[2] }}>Model Number</div>
              <div style={{ flex: columnWidths[3] }}>Serial Number</div>
              <div style={{ flex: columnWidths[4] }}>Status</div>
              <div style={{ flex: columnWidths[5] }}></div>
            </div>

            {!this.props.resources.devicesLoaded && (
              <LoadingSection columnWidths={columnWidths} />
            )}

            {filteredFoundGroupDevices.map((device) => {
              return (
                <div
                  key={device.enclosureSerialNumber}
                  className={`grid-row-wrapper ${
                    device.connectionState === "Connected" ? "" : "disconnected"
                  }`}
                  title={
                    device.connectionState === "Connected" ? "" : "Disconnected"
                  }
                  style={{
                    opacity: "1",
                  }}
                >
                  <div
                    className={`grid-row ${
                      device.connectionState === "Connected"
                        ? "connected"
                        : "disconnected"
                    }`}
                    style={{
                      cursor: "default",
                      minWidth: "200px",
                    }}
                  >
                    <div style={{ flex: columnWidths[1] }}>
                      {devicesLoading && <div className="skeleton-blank" />}
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <span>
                            {device.deviceName === "SynLink PDU" ||
                            !device.deviceName
                              ? `PDU SN:${device.enclosureSerialNumber}`
                              : device.deviceName}
                          </span>
                        </span>
                      </span>
                    </div>
                    <div style={{ flex: columnWidths[2] }}>
                      {device.modelNumber}
                    </div>
                    <div style={{ flex: columnWidths[3] }}>
                      {device.enclosureSerialNumber}
                    </div>
                    <div style={{ flex: columnWidths[4] }}>
                      {device.connectionState}
                    </div>
                    <div
                      style={{
                        flex: columnWidths[5],
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {removeGroupDeviceSerialNumbersLoading.includes(device.enclosureSerialNumber) ? (
                        <Loading black />
                      ) : (
                        <span
                          onClick={() => {
                            this.removeGroupDevice(device.enclosureSerialNumber);
                          }}
                          style={{
                            cursor: "pointer",
                            backgroundColor: "grey",
                            color: "white",
                            padding: "3px 15px 3px 10px",
                            borderRadius: "3px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <i className="material-icons">remove</i>
                          Remove
                        </span>

                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}

        {/* -----=======------- */}
        <div
          style={{
            overflowX: "auto",
            maxHeight: "45vh",
            marginTop: "30px",
          }}
        >
          <div
            className="grid-x "
            style={{ width: "100%", marginBottom: "10px" }}
          >
            <div
              className="cell "
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ marginTop: "20px", marginLeft: "5px" }}>
                <h5 style={{ marginBottom: "0px" }}>Add a Device</h5>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  //   marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <input
                  type="text"
                  placeholder="Search for model number, device name, or serial number"
                  style={{
                    marginBottom: "0px",
                    minWidth: "420px",
                  }}
                  value={this.state.searchBar2}
                  onChange={(e) => {
                    this.setState({ searchBar2: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.applyFilters2();
                    }
                  }}
                />
                <span
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    padding: "0.45rem",
                    height: "2.43rem",
                    margin: "0 0 1rem",
                    marginBottom: "0px",
                    borderRadius: "0.1875rem",
                    backgroundColor: "#49535E",
                    color: "#fff",
                  }}
                  onClick={() => {
                    this.applyFilters2();
                  }}
                >
                  <i className="material-icons" style={{ marginRight: "0px" }}>
                    search
                  </i>
                </span>
              </div>
            </div>
          </div>
          <div
            className="grid-row header"
            style={{ marginLeft: "5px", minWidth: "200px" }}
          >
            <div style={{ flex: columnWidths[1] }}>Device Name</div>
            <div style={{ flex: columnWidths[2] }}>Model Number</div>
            <div style={{ flex: columnWidths[3] }}>Serial Number</div>
            <div style={{ flex: columnWidths[4] }}>Status</div>
            <div style={{ flex: columnWidths[5] }}></div>
          </div>

          {!this.props.resources.devicesLoaded && (
            <LoadingSection columnWidths={columnWidths} />
          )}

          {filteredRemainingDevices.map((device) => {
            return (
              <div
                key={device.enclosureSerialNumber}
                className={`grid-row-wrapper ${
                  device.connectionState === "Connected" ? "" : "disconnected"
                }`}
                title={
                  device.connectionState === "Connected" ? "" : "Disconnected"
                }
                style={{
                  opacity: "1",
                }}
              >
                <div
                  className={`grid-row ${
                    device.connectionState === "Connected"
                      ? "connected"
                      : "disconnected"
                  }`}
                  style={{
                    cursor: "default",
                    minWidth: "200px",
                  }}
                >
                  <div style={{ flex: columnWidths[1] }}>
                    {devicesLoading && <div className="skeleton-blank" />}
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <span>
                          {device.deviceName === "SynLink PDU" ||
                          !device.deviceName
                            ? `PDU SN:${device.enclosureSerialNumber}`
                            : device.deviceName}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{ flex: columnWidths[2] }}>
                    {device.modelNumber}
                  </div>
                  <div style={{ flex: columnWidths[3] }}>
                    {device.enclosureSerialNumber}
                  </div>
                  <div style={{ flex: columnWidths[4] }}>
                    {device.connectionState}
                  </div>
                  <div
                    style={{
                      flex: columnWidths[5],
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {addGroupDeviceSerialNumbersLoading.includes(device.enclosureSerialNumber)
                    ? (
                      <Loading black />
                    ) : (
                      <span
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#223647",
                          color: "white",
                          padding: "3px 15px 3px 10px",
                          borderRadius: "3px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          this.addGroupDevice(device.enclosureSerialNumber);
                        }}
                      >
                        <i className="material-icons">add</i>
                        Add
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

class InletGroupForm extends React.Component {
  state = {
    groupName: "",
  };
  submitGroup = () => {
    this.props.createResource(
      "groups",
      {
        name: this.state.groupName,
        groupType: "devices",
        items: this.props.items,
      },
      (err, newGroup) => {
        if (newGroup) {
          this.props.changeAppState({
            selectedGroup: this.state.groupName,
            selectedGroupId: newGroup.id,
          });
          this.props.close();
        } else {
        }
      }
    );
  };
  render() {
    return (
      <div>
        <TextInput
          label="Group Name"
          callback={(value) => {
            this.setState({ groupName: value });
          }}
          value={this.state.groupName}
          type="text"
          error=""
          placeholder="Example Name"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "30px",
            paddingBottom: "-30px",
          }}
        >
          <div
            className="button"
            style={{
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
            onClick={() => this.props.close()}
          >
            Cancel
          </div>
          <div
            className="button"
            onClick={() => {
              this.submitGroup();
            }}
          >
            Submit
          </div>
        </div>
      </div>
    );
  }
}

const LoadingSection = ({ columnWidths }) => {
  return (
    <div style={{}}>
      {[null, null, null, null].map((item, index) => {
        return (
          <div className="grid-row-wrapper" key={index}>
            <div className="grid-row">
              <div style={{ flex: columnWidths[0] }}></div>
              <div style={{ flex: columnWidths[1] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[2] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[3] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[4] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[5] }}>
                <div className="skeleton-blank" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
