import React from 'react';


class AppLogout extends React.Component {
  state = {
    timer: null
  }

  componentDidMount() {
    window.addEventListener("click", this.clickListener)
    this.createTimer();
  }
  componentWillUnmount() {
    clearTimeout(this.timer);
    window.removeEventListener('click', this.clickListener, false);
  }

  clickListener = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.createTimer();
    }
  }

  createTimer = () => {
    this.timer = setTimeout(() => {  
      this.props.updateUser({
        authorized: false
      })
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiration");
      localStorage.removeItem("userId");

      this.props.history.replace("/login")  
    }, 1800000)
  }

  render() {

    return (
      this.props.children
    )
  }
}

export default AppLogout;