import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles/styling.js';

import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
} from "react-router-dom";
import AuthWrapper from './components/auth/authWrapper';
import ModalContainer from './components/common/modal/modalContainer.jsx';

import './utils/notificationManager';

const AuthApp = AuthWrapper(App);


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Route
      path="/logout"
      exact
      render={() => {
        return (
          <Redirect
            from="/logout"
            exact
            to="/login"
          />
        )
      }}
    />
    <Route
      render={({ history, location }) => {
        return (
          <AuthApp
            history={history}
            location={location}
          />
        );
      }}
    />
    <ModalContainer />
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
