import React from 'react';

const ToggleInput = ({
  label,
  description,
  callback = () => {},
  value,
  title,
  disabled,
  inputContainerStyle = {},
  id,
  notActive = false,
  helpIconAction,
  errMsg,
  statusText= () => {},
  flipColors,
}) => {
  
  return (
    <div
      className="input-container"
      title={title}
      style={inputContainerStyle}
    >
      {label &&
        <div
          className="input-label"
          style={{
            color: disabled ? "#9e9e9e" : "#333",
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <span>{label}</span>
            {helpIconAction && 
              <span
                className={disabled ? "grey" : "dark-grey"}
                onClick={() => helpIconAction()}
                style={{
                  marginLeft: "5px",
                  cursor: 'pointer'
                }}
              >
                <i className="material-icons">help</i>
              </span>
            }
          </div>
          {/* {errMsg && 
            <div
            >
              errMsg
            </div>
          } */}

          {(!disabled && (errMsg)) && 
            <span
              style={{
                color: 'maroon',
                fontWeight: '400'
              }}
            >
              {errMsg}
            </span>
          }
        </div>
      }
      {description &&
        <div
          style={{
            color: "grey",
            fontSize: "90%",
            paddingBottom: "10px"
          }}
        >
          {description}
        </div>
      }
      <div
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div>
          <input
            className="tgl tgl-light"
            id={`cb_${label || id}`} // NEEDS TO BE UNIQUE
            type="checkbox"
            checked={!!value}
            onChange={() => {
              callback(!value)
            }}
            disabled={disabled || notActive}
          />
          <label
            className={`tgl-btn ${flipColors ? "flipped" : ""}`}
            style={{
              border: errMsg ? "solid maroon 2px" : ""
            }}
            htmlFor={`cb_${label || id}`}
          />
          
        </div>
        {
          statusText(value) ? (
            <div
              style={{
                paddingLeft: "5px",
                fontSize: "90%",
              }}
            >
              {statusText(value)}
            </div>
          ) : (
            null
          )
        }
      </div>
    </div>
  );
}

export default ToggleInput;
