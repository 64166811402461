import React from 'react';

const TextStaticInput = ({
  label,
  error="",
  value,
  disabled,
  inputContainerStyle = {},
  options = [],
  type="",
  helpIconAction,
}) => {
  const valueString = options.length > 0 ? 
                        ((options.find(o => o.value == value) || {}).label || value)
                        :
                        value;
  return (
    <div
      className="input-container"
      style={inputContainerStyle}
    >
      {label &&
        <div
          className="input-label"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: disabled ? "#9e9e9e" : "#333",
            alignItems: 'center'
          }}
        >
          <span>{label}</span>
          {helpIconAction && 
            <span
              className={disabled ? "grey" : "dark-grey"}
              onClick={() => helpIconAction()}
              style={{
                marginLeft: "5px",
                cursor: 'pointer'
              }}
            >
              <i className="material-icons">help</i>
            </span>
          }
        </div>
      }
      {type === "password" ? (
        <input
          style={{
            // height: "1.22rem",
            padding: "0.5rem",
            borderRadius: "3px",
            border: disabled ? "solid #e6e6e6 1px" : error ? 'solid maroon 1px' : 'solid #e6e6e6 1px',
            color: disabled ? "#9e9e9e" : "#333",
            backgroundColor: "#F5F5F5"
          }}
          disabled
          type="password"
          value={valueString}
        />
      ) : (
        <div
          style={{
            height: "1.22rem",
            padding: "0.5rem",
            borderRadius: "3px",
            border: disabled ? "solid #e6e6e6 1px" : error ? 'solid maroon 1px' : 'solid #e6e6e6 1px',
            color: disabled ? "#9e9e9e" : "#333",
            backgroundColor: "#F5F5F5"
          }}
        >
          {valueString}
        </div>
      )}
    </div>
  );
}

export default TextStaticInput;
