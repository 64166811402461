import React from 'react';

const ToggleInput = ({
  label,
  callback = () => {},
  value,
  disabled,
  inputContainerStyle = {},
  toggleLabelOff,
  toggleLabelOn,
  id,
  description
}) => (
  <div
    className="input-container"
    style={{
      marginBottom: "20px",
      ...inputContainerStyle,
    }}
  >
    {label &&
      <div
        className="input-label"
        style={{
          color: disabled ? "#9e9e9e" : "#333",
          paddingBottom: '5px'
        }}
      >
        {label}
      </div>
    }
    {description &&
      <div
        className="input-label"
        style={{
          color: "#666",
          fontStyle: "italic",
          margin: "5px 0px",
          marginBottom: "10px",
          paddingTop: "0px",
          marginTop: "-10px"
        }}
      >
        {description}
      </div>
    }
    <span
      style={{
        marginRight: "0px",
        color: value ? "#999" : "#222"
      }}
    >
      {toggleLabelOff}
    </span>
    <input
      className="tgl tgl-light"
      id={`cb_${label || id}`} // NEEDS TO BE UNIQUE
      type="checkbox"
      checked={!!value}
      onChange={() => {
        callback(!value)
      }}
      disabled={disabled}
    />
    <label
      className="tgl-btn "
      htmlFor={`cb_${label || id}`}
    />
    <span
      style={{
        marginLeft: "-5px",
        color: value ? "#222" : "#999"
      }}
    >
      {toggleLabelOn}
    </span>
  </div>
);

export default ToggleInput;