import React from 'react';
import Logo from '../../synaccess_white.svg';
import TextInput from '../common/inputs/textInput';
import Loading from '../common/loading';
import parseQuery from '../../utils/parseQueryParams';

export default class ConfirmEmail extends React.Component {
  state = {
    loading: false,
    password: "",
  }
  componentDidMount() {
    if (this.props.user) {
      this.setState({ loading: true })
      this.props.confirmEmail({
        cookie: localStorage.getItem("token"),
        token: parseQuery(this.props.history.location.search).t
      },() => {
        this.setState({ loading: false });
      });
    }
  }
  submitPass = () => {
    // console.log(this.state.password)
    // this.props.confirmEmail
    this.props.confirmEmail({
      password: this.state.password,
      token: parseQuery(this.props.history.location.search).t
    })
  }
  render() {
    const {
      user ,
      loading,
    } = this.props;
    if (loading) {
      return <Loading fixed black />
    }

    return (
      <div className="welcome-background">
        <div className="grid-container">

        <div className="grid-x grid-padding-x grid-padding-y">
            {user && <div className="cell" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: "solid white 1px",
                  padding: '10px 30px',
                  borderRadius: '3px',
                  color: "white",
                  cursor: 'pointer',
                  // borderBottom: "solid white 1px"

                }}
                onClick={() => {
                  this.props.signout()
                }}
              >
                <i className="material-icons">logout</i>
                <span>
                  <b>
                    Sign Out
                  </b>
                </span>
              </div>
            </div>}
          </div>
          <div className="grid-x grid-padding-x grid-padding-y align-center"  style={{ marginTop: "0vh" }}>
            <div
                className="cell large-4 medium-6 small-10"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >             
               <div
                style={{
                  padding: "0px",
                  marginTop: "10vh",
                  borderRadius: "2px",
                  width: "100%"
                }}
              >
                <div
                  style={{
                    paddingBottom: "20px",
                  }}
                >
                  <img alt="logo" src={Logo} width="165px" />
                </div>
                <div
                  style={{
                    boxShadow: "3px 3px 20px rgba(0,0,0,0.05)",
                    width: "100%",
                    backgroundColor: "white",
                    padding: "50px 20px",
                    display: 'flex',
                    // alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: "column",
                    // textAlign: 'center',
                    borderRadius: "3px"
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <i className="material-icons" style={{ fontSize: "100px", width: "100px"}}>approval</i>
                  </div>
                  <div
                    style={{
                      paddingBottom: "20px",
                      paddingTop: "20px",
                      borderRadius: "2px",
                      fontSize: "125%",
                      fontWeight: "600",
                    }}
                  >
                    Verify your email
                  </div>
                  <div style={{
                    // textAlign: 'start'
                  }}>
                    Please enter the password for the Synaccess Cloud Account associated with your email. 
                  </div>
                  <TextInput
                    value={this.state.password}
                    type="password"
                    callback={text => this.setState({ password: text })}
                    label="Password"
                  />
                  <div
                    className="button"
                    onClick={() => {
                      this.submitPass();
                    }}
                  >
                    Continue
                  </div>
                </div>
                {/* <div
                  style={{
                    padding: "20px 10px 10px 10px",
                    color: "white",
                  }}
                >
                  Need Help? {" "}
                  <Link to="/contact">
                    <b style={{ color: "white" }}>Contact Us</b> 
                  </Link>
                </div> */}
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}