import React from "react";
import { Link } from "react-router-dom";

export default class Pricing extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="welcome-background"
          style={{
            height: "13vh",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "20px",
            }}
          >
            <Link to="/login">
              <span
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                <i className="material-icons">arrow_back</i>
                Back 
              </span>
            </Link>
          </div>
        </div>
        <div className="grid-container">
          <div
            className="grid-x grid-padding-x grid-padding-y align-center"
            style={{
              // marginTop: "-30vh",
            }}
          >
            <div
              className="cell large-6 medium-8 small-11"
              style={{
                paddingTop: "40px"
              }}
            >
              <h2 style={{ paddingBottom: "30px" }}>Beta Terms of Service</h2>
              {[{
                title: "1. Scope of Agreement",
                description: "Beta customer is being granted rights under this Agreement for the purpose of testing and providing input and other Feedback to Synaccess regarding one or more of Synaccess' proprietary, hosted software applications, application platform interfaces, services, features and/or functionalities. This Agreement covers all Beta Products made available to beta customer by Synaccess, including, without limitation, any Beta Products specifically identified by Synaccess as being in \"Beta\" or any similar stage of development. Synaccess retains sole and absolute discretion as to what, if any, Beta Products lwill be made available to Beta Customer during the Term. While Beta Customer is not required to utilize or enable any Beta Products, if Beta Customer elects to do so, then Beta Customer's use of and access to any such Beta Products shall be subject to the terms of this Agreement."
              }, {
                title: "2. Payment; Fees.",
                description: "Synaccess reserves the right to charge fees for use and access to Beta Products or may provide the Beta Products for no charge. Synaccess reserves the right to start charging or revise fee amounts at any time, at its sole discretion, under this Agreement or after the Beta Products are made widely available."
              }, {
                title: "3. Privacy",
                description: "For purposes of this Beta test program, you agree to allow Synaccess, it's employees or agents to contact you using the personal contact information you have provided, such as your name, or email address. Synaccess collects usage information, device information, subject, where necessary, to your consent. We only collect the minimum amount of personal data necessary from you, unless you choose to provide more."
              }, {
                title: "4. Third Party Tool Integrations.",
                description: "If applicable, one or more Beta Products may integrate with third party services. Beta Customer hereby consents to the sharing of the information in the Beta Products with these third party services and certifies that it has any and all required consents for doing so."
              }, {
                title: "5. Ownership",
                description: "You acknowledge and agree that Synaccess and/or its suppliers own all right, title and interest in and to the Beta Product and in any and all intellectual property rights therein, including but not limited to any and all copyrights, patents, patent applications, trade secrets, trademarks and other intangible rights therein. You agree not to use Synaccess' trademarks or other business names for any purpose. You will not earn or acquire any rights or licenses under any copyrights, patents, trade secrets, trademarks or other intellectual property rights on account of this Agreement or your performance under this Agreement. To the extent that Synaccess provides you Synaccess Materials or Beta Products, you agree that all Synaccess Materials are and remain the sole and exclusive property of Synaccess. You further agree, upon request of Synaccess, to return any Synaccess Materials at the end of the Beta test at Synaccess’ expense. You agree to promptly disclose and hereby assign to Synaccess any suggestions for change and/or improvement to the Beta Product and any related rights thereto and to assist Synaccess, as reasonably requested by Synaccess and at Synaccess' expense, in obtaining intellectual property protection relating to such suggestions as Synaccess may reasonably direct.",
              }].map(section => {
                return (
                  <div key={section.title}>
                    <div
                      style={{
                        paddingBottom: "15px"
                      }}
                    >
                      <h3><b>{section.title}</b></h3>
                    </div>
                    <div
                      style={{
                        paddingBottom: "50px"
                      }}
                    >
                      {section.description}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
