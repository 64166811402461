import React from 'react';
import {
  Link
} from 'react-router-dom';
// import icons from '../../assets/icon-all';


const BreadCrumbs = ({
  match,
  history,
  prevPath
}) => {
  const crumbs = match.url.split('/').filter(t => t);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        // flexWrap: 'wrap',
        paddingBottom: "10px"
      }}
    >
      <div
        onClick={() => {
          // if (prevPath) {
          //   history.push(prevPath)
          // } else {
          //   history.push("/")
          // }
          history.goBack()
        }}
        style={{
          cursor: 'pointer',
          display: "flex",
          alignItems: 'center',
          // textDecoration: "underline",
        }}
        className="black"
      >
        {/* {icons.chevronLeft} */}
        {/* "chevronleft" */}
        <i className="material-icons">chevron_left</i>
        <span
          style={{
            marginLeft: "-6px"
          }}
        >
          Go Back
        </span>
      </div>
      <div
        style={{
          // fontSize: '110%',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          // flexDirection: "column",
          // alignItems: 'flex-start'
        }}
        className="flex-small-column"
      >
        <Link
          to="/"
          style={{
            color: "#4d4d4d",
            textDecoration: "underline"
          }}
        >
          Home
        </Link>
        <div
          style={{
            display: 'flex',
            // flexDirection: "column"
          }}
          className="flex-small-column"
        >
          {crumbs.map((crumb, index) => {
            const link = crumbs.slice(0, index+1).join("/")
            
            return (
              <div
                key={crumb+"slash"}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  className="black"
                  style={{
                    padding: "0px 0px 0px 0px",
                    display: 'flex',
                    marginRight: "-10px"
                  }}
                >
                  {/* {icons.chevronRight} */}
                  {/* chevronright */}
                  <i className="material-icons">chevron_right</i>
                </div>
                <Link
                  to={`/${link}`}
                  style={{
                    padding: "0px 4px",
                    color: "#333",
                    textTransform: "capitalize",
                    textDecoration: "underline",
                  }}
                >
                  {crumb}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default BreadCrumbs;