import React from 'react';
import ActivityLog from './activityLog';

export default class LoggingUserLogs extends React.Component {
  state = {
    loading: false,
  }
  componentDidMount() {
    if ((this.props.resources.activity.length === 0) && !this.props.activityLoading) {
      this.setState({ loading: true })
      this.props.fetchResource("activity", (err, data) => {
        // console.log(err, data)
        this.setState({ loading: false })
        if (data) {
          // console.log(data.data)
        }
      })
    }
  }
  refresh() {
    this.props.fetchResource("activity", (err, data) => {
      console.log(err, data)
      if (data) {
        console.log(data.data)
      }
    })
  }
  render() {
    const {
      activity,
      outlets,
      devices,
      devicesLoading
    } = this.props.resources;
    return (
      <div className="grid-container">
        <div className="grid-x grid-padding-x grid-padding-y align-left">
          <div className="cell large-10 medium-10">
            <h4 style={{ paddingLeft: "15px" }}>Activity Log</h4>
            <ActivityLog
              activity={activity}
                outlets={outlets}
                devices={devices}
                devicesLoading={devicesLoading}
                loading={this.state.loading}
                refresh={() => {
                  this.props.fetchResource('activity')
                }}
              />
          </div>
        </div>
      </div>
    )
  }
}