import React from 'react';
import Logo from '../../synaccess.svg';
import TextInput from '../common/inputs/textInput';
import Loading from '../common/loading';
import { Link } from 'react-router-dom'

export default class Signup extends React.Component {
  state = {
    fullName: "",
    password: "",
    email: "",
    fullNameError: "",
    passwordError: "",
    emailError: "",
    loading: false,
    company: "",
  }
  submit = () => {
    this.setState({ loading: true })
    this.props.fetchSignup({
      fullName: this.state.fullName,
      company: this.state.company,
      password: this.state.password,
      email: this.state.email
    }, () => {
      this.setState({ loading: false })
    })
  }

  render() {
    const {
      password,
      fullName,
      email,
      passwordError,
      fullNameError,
      emailError,
      loading,
      company,
      companyError,
    } = this.state;

    const { signupErrMessage } = this.props;

    return (
      <div
        className="welcome-background"
      >
        <div
          className="grid-container"
        >
          <div className="grid-x grid-padding-x grid-padding-y">
            <div className="cell show-for-large  " style={{ paddingTop: "10vh" }} />
            <div className="cell hide-for-large  " style={{ paddingTop: "5vh" }} />
            <div className="cell" style={{ backgroundColor: "" }}>
              <div className="grid-x grid-padding-x grid-padding-y align-center" >
                <div className="cell large-5 medium-5 small-11" style={{ backgroundColor: "white", borderRadius: "3px 0px 0px 3px", boxShadow: "3px 3px 10px rgba(0,0,0,0.1)", paddingTop: "30px" }}>
                  <div
                    style={{
                      padding: "20px",
                      paddingBottom: "30px",
                    }}
                  >
                    <img alt="logo" src={Logo} width="165px" />
                  </div>
                  {[{
                    title: "Streamlined Access",
                    description: "Access multiple SynLink devices with minimal setup for ultimate convenience."
                  }, {
                    title: "Group Devices",
                    description: "Group outlets from multiple PDUs and issue batch commands."
                  }, {
                    title: "Free Tier Available",
                    description: <div>Access up to 5 devices for free. Learn about our pricing <Link to="/pricing">here</Link></div>
                  }, {
                    title: "Sign Up for Access",
                    description: "Create an account and we will review your information for access. Expect a response in 1-2 business days."
                  }].map((section => {
                    return (
                      <div
                        key={section.id}
                        style={{
                          // display: 'flex',
                          padding: "10px 20px",// 
                        }}
                      >
                        <div
                          style={{
                            // display: 'flex',
                            // alignItems: 'center'
                          }}
                        >
                          <div
                            // className="h5"
                            style={{
                              margin: "0px",
                              fontSize: "115%",
                              display: 'flex',
                              alignItems: 'center',
                              color: "#283f54"
                            }}
                          >
                            <i
                              className="material-icons"
                              style={{
                                // paddingRight: "5px",
                                width: "30px",
                              }}
                            >
                              check_circle
                            </i>
                            <b>{section.title}</b>
                          </div>
                          <div className="" style={{ marginLeft: "30px" }}>{section.description}</div>
                        </div>

                      </div>
                    )
                  }))}
                </div>

                <div
                  className="cell large-4 medium-5 small-11"
                  style={{
                    // display: 'flex',
                    // justifyContent: 'center',
                    backgroundColor: "white",
                    borderRadius: "0px 3px 3px 0px",
                    // boxShadow: "3px 3px 10px rgba(0,0,0,0.1)"
                    // padding: "30px",
                    padding: "0px"
                  }}
                >
                  <div
                    style={{
                      // backgroundColor: "white",
                      // boxShadow: "3px 3px 20px rgba(0,0,0,0.05)",
                      // padding: "30px 60px",
                      borderLeft: "1px solid rgba(0,0,0,0.2)",
                      // paddingLeft: '40px',
                      // paddingRight: '40px',
                      // paddingTop: "50px",
                      // maxWidth: '520px',
                      // minWidth: '350px',
                      padding: "40px 50px"
                    }}
                  >
                    <div
                      style={{
                        // backgroundColor: "#1d262f",
                        // color: "white",
                        borderRadius: '2px',
                        // display: 'flex',
                        // justifyContent: 'center',
                        fontSize: '125%',
                        fontWeight: '600',
                        paddingBottom: "20px"
                      }}
                    >
                      Create your Synaccess account
                    </div>
                    <div>
                      <TextInput
                        label="Full name"
                        placeholder="Name"
                        value={fullName}
                        error={fullNameError}
                        callback={data => this.setState({ fullName: data })}
                        inputContainerStyle={{
                          // paddingBottom: "5px"
                        }}
                      />
                      <TextInput
                        label="Company"
                        placeholder="Company"
                        value={company}
                        error={companyError}
                        callback={data => this.setState({ company: data })}
                        inputContainerStyle={{
                          // paddingBottom: "5px"
                        }}
                      />
                      <TextInput
                        label="Email*"
                        placeholder="Email"
                        value={email}
                        error={emailError}
                        callback={data => this.setState({ email: data })}
                        inputContainerStyle={{
                          // paddingBottom: "5px"
                        }}
                      />
                      <TextInput
                        label="Password*"
                        value={password}
                        error={passwordError}
                        type="password"
                        placeholder="Password"
                        callback={data => this.setState({ password: data })}
                        inputContainerStyle={{
                          // paddingBottom: "5px"
                        }}
                      />
                      <div
                      >
                        {[{
                          name: "2 lowercase characters required",
                          valid: /[a-z].*[a-z]/.test(this.state.password)
                        }, {
                          name: "2 uppercase characters required",
                          valid: /[A-Z].*[A-Z]/.test(this.state.password)
                        }, {
                          name: "2 number characters required",
                          valid: /[0-9].*[0-9]/.test(this.state.password)
                        }, {
                          name: "2 special characters required",
                          valid: /[!@#$%^&*;].*[!@#$%^&*;]/.test(this.state.password)
                        }].map(validation => {
                          return (
                            <div
                              key={validation.name}
                              style={{
                                color: validation.valid ? "forestgreen" : "#666",
                                display: 'flex',
                                paddingBottom: "10px",
                                alignItems: 'center'
                              }}
                            >
                              <i className="material-icons">{validation.valid ? "check" : "cancel"}</i>
                              <span>{validation.name}</span>
                            </div>
                          )
                        })}
                      </div>
                      {signupErrMessage &&
                        signupErrMessage.split(',').map(msg => {
                          return (
                            <div
                              key={msg}
                            >
                              <div
                                style={{
                                  color: "maroon",
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <i className="material-icons" style={{ marginRight: "5px" }}>error</i>
                                <span>{msg}</span>
                              </div>

                            </div>
                          )
                        })}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          paddingBottom: '20px',
                          paddingTop: "20px"
                        }}
                      >
                        <button
                          className="button"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontWeight: '600',
                            width: '100%',
                            boxShadow: '3px 3px 3px rgba(0,0,0,0.1)',
                            color: "white"
                          }}
                          onClick={() => this.submit()}
                        // type="submit"
                        >
                          {loading ? <Loading /> : "Create account"}
                        </button>
                        <div
                          style={{
                            padding: "20px 00px 00px 0px",
                            // color: "white"
                          }}
                        >
                          Already have an account? <Link to="/login"><b style={{ color: "" }}>Sign in</b></Link>
                        </div>
                      </div>

                    </div>

                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}