import React from "react";

import ActivityLog from "../../logging/activityLog";
import RowList from "../../../components/common/rowList";
import ModalManager from "../../../components/common/modal/ModalManager";
import TextInput from "../../../components/common/inputs/textInput";
// import { formatDuration, intervalToDuration } from "date-fns";
import Loading from "../../../components/common/loading";

// function humanDuration(time) {
//   if (!time) return null;
//   return formatDuration(intervalToDuration({ start: 0, end: time }));
// }
export default class DeviceDetails extends React.Component {
  state = {
    activityLoading: false,
    granularity: null,
    loading: false,

    deviceName: "",
    deviceNameLoading: false,
  };
  componentDidMount() {

    this.setState({
      deviceName: this.props.device.deviceName,
    })

    if (
      !this.props.resources.activityLoaded &&
      !this.props.resources.activityLoading
    ) {
      if (this.props.resources.activityLoaded.length > 0) {
      } else {
        this.setState({
          activityLoading: true,
        });
        this.props.fetchResource("activity", (err, data) => {
          // console.log(err, data)
          this.setState({
            activityLoading: false,
          });
          if (data) {
            // console.log(data.data)
          }
        });
      }
    }
    this.setState({
      granularity: this.props.device.cloudPostIntervalMs / 1000,
    });
  }

  onUnpairButtonPress = () => {
    ModalManager.confirm(
      "This action will unpair the PDU from your account. You can pair the PDU again by logging in directly to the PDU.",
      "Unpair PDU",
      "Confirm",
      (submitted, close) => {
        if (submitted) {
          this.props.unpair(() => {
            close();
          });
        } else {
          // close
        }
      }
    );
  };

  onSaveClick = () => {
    this.setState({ loading: true });
    this.props.modifyResource(
      "devices",
      this.props.device.enclosureSerialNumber,
      {
        // cloudPostIntervalMs: this.state.granularity * 1000,
        deviceName: this.state.deviceName,
      },
      () => {
        this.setState({ loading: false });
      },
      "enclosureSerialNumber"
    );
  };

  render() {
    const { device } = this.props;
    const isOnline = device.connectionState === "Connected";
    
    const outlets = this.props.resources.outlets.filter(
      (o) => o.enclosureSerialNumber === device.enclosureSerialNumber
    );
    const hasEdit = this.props.device.deviceName !== this.state.deviceName;

    return (
      <div className="grid-container">
        <div className="grid-x grid-padding-x grid-padding-y">
          <div className="cell large-6 medium-6">
            <div style={{}}>
              {/* <h4
                  style={{
                    // marginBottom: "20px",
                  }}
                >
                  Device Info
                </h4> */}
{/* 
              {!(
                this.props.user.role && !this.props.user.role.editDeviceInfo
              ) && ( */}
                <div>
                  {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                </div> */}
                  <div
                    style={
                      {
                        // backgroundColor: "white",
                        // padding: "20px",
                        // marginBottom: "100px",
                        // boxShadow: "3px 3px 10px rgb(0 0 0 / 10%)",
                        // borderTop: "solid 3px #ddd",
                        // marginTop: "30px",
                      }
                    }
                  >
                    <div
                      style={
                        {
                          // paddingTop: "20px",
                        }
                      }
                    >
                      <TextInput
                        label="Device Name"
                        value={this.state.deviceName}
                        callback={(txt) => {
                          this.setState({ deviceName: txt });
                        }}
                        placeholder="Enter Device Name"
                        readOnly={!isOnline}
                      />
                    </div>

                    {hasEdit && (
                      <div
                        className="button"
                        onClick={() => this.onSaveClick()}
                      >
                        {this.state.loading ? <Loading /> : "Save"}
                      </div>
                    )}
                  </div>
                </div>
              {/* )} */}
              <RowList
                rows={[
                  // {
                  //   label: "Device Information",
                  //   labelStyle: {
                  //     fontWeight: "bold",
                  //     fontSize: "110%",
                  //   },
                  // },
                  {
                    label: "Name",
                    value: device.deviceName,
                    valueStyle: {
                      // fontWeight: "600",
                    },
                  },
                  {
                    label: "Model Number",
                    value: device.modelNumber,
                    valueStyle: {
                      // fontWeight: "500",
                    },
                  },
                  {
                    label: "Enclosure Serial Number",
                    value: device.enclosureSerialNumber,
                  },
                  {
                    label: "Outlet Switching",
                    value: device.outletSwitchingSupported
                      ? "Supported"
                      : "Not Supported",
                  },
                  {
                    label: "Outlet Power Measurements",
                    value: device.outletPwrMeasurementsSupported
                      ? "Supported"
                      : "Not Supported",
                  },
                  {
                    label: "Number of Banks",
                    value: device.numBanks,
                  },
                  {
                    label: "Number of Outlets",
                    value: device.numOutlets,
                  },
                  {
                    label: "Number of Relay Outputs",
                    value: device.numDryContactRelays,
                    disabled: device.numDryContactRelays === 0,
                  },
                  {
                    label: "Controller Serial Number",
                    value: device.controllerSerialNumber,
                  },
                  {
                    label: "Inlet Configuration",
                    value:
                      device.inletConfig === "standard"
                        ? "Standard"
                        : device.inletConfig === "ats"
                        ? "Automatic Transfer Switch (ATS)"
                        : device.inletConfig === "dualcircuit"
                        ? "Dual Circuit"
                        : "-",
                  },
                  {
                    label: "Form Factor",
                    value: device.formFactor,
                  },
                  {
                    label: "Phase",
                    value: device.phase,
                  },
                ]}
              />
              <div
                style={{
                  paddingTop: "30px",
                }}
              >
                <div>Unnpair PDU from Synaccess Cloud Account</div>
                <div
                  className="button"
                  style={{
                    backgroundColor: "maroon",
                    marginTop: "10px",
                  }}
                  onClick={() => this.onUnpairButtonPress()}
                >
                  Unpair Now
                </div>
              </div>
            </div>
          </div>
          {!(this.props.user.role && !this.props.user.role.viewActivityLog) && (
            <div className="cell large-6 medium-6">
              <ActivityLog
                outlets={outlets}
                devices={[device]}
                selectedDevice={device}
                activity={this.props.resources.activity.filter(
                  (a) =>
                    a.resourceId == device.enclosureSerialNumber ||
                    a.enclosureSn == device.enclosureSerialNumber
                )}
                refresh={() => {
                  this.setState({
                    activityLoading: true,
                  });
                  this.props.fetchResource("activity", (err, data) => {
                    this.setState({
                      activityLoading: false,
                    });
                  });
                }}
                loading={this.state.activityLoading}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
