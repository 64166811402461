import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  registerables,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ...registerables
);

export default class LineChart extends React.Component {
  state = {
    foundationSize: "large-6"
  }
  componentDidMount() {
    if (this.props.item.wrapperClass) {
      this.setState({
        foundationSize: this.props.item.wrapperClass
      })
    }
  }
  render() {
    const { datasets, labels, item, chartTimeFrameText } = this.props;
    return (
      <div className={`cell ${this.state.foundationSize}`} >
        <div className="app-item">
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            {/* {(this.state.foundationSize === "large-6" || this.state.foundationSize === "large-12") &&  */}
              <i
                className="material-icons" 
                style={{ cursor: 'pointer', color: "#777", marginBottom: "-10px", fontSize: "28px" }} 
                onClick={() => {
                  this.setState({
                    foundationSize: (this.state.foundationSize === "large-6" || this.state.foundationSize === "large-auto") ? "large-12" : "large-6"
                  })
                }}
              >
                {this.state.foundationSize === "large-6" ? "fullscreen" : "fullscreen_exit"}
              </i>
            {/* } */}
          </div>
          <Line
            options={{
              animation: {
                duration: 0,
              },
              elements: {
                point: { radius: 0 },
              },
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: true,
                  // text: "Circuits Current RMS over time",
                  text: item.title
                },
              },
              scales: {
                x: {
                  type: "time",
                  time: {
                    unit:
                      chartTimeFrameText === "last-week"
                        ? "day"
                        : undefined,
                    displayFormats: {
                      day: "dd MMM",
                    },
                  },
                },
                y: {
                },
                ...(item.scales || {})
              },
            }}
            data={{
              labels,
              datasets
            }}
          />
        </div>
      </div>
    )
  }
}